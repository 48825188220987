import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { checkMemberDuplicatePhoneAPI, checkMemberVerifyCodeAPI, loginAPI, postMemberVerifyCodeAPI, signupAPI } from "../../apis/MemberAPICalls";
import PolicyModal from "../../components/modal/PolicyModal";
import { useSelector } from "react-redux";
import SendVerifyCodeInterval from "../../js/SendVerifyCodeInterval";
import Swal from "sweetalert2";

function Signup() {

  const {state} = useLocation();
  const [form, setForm] = useState({
    name: '',
    snsId: state  ? state : '',
    ssn1: '',
    ssn2: '',
    agency: 'SKT',
    phone: '',
    verifyCode: '',
    rcmCode: ''
  });
  const [step, setStep] = useState(1);
  const [policyModalStatus, setPolicyModalStatus] = useState(false);
  const [accept ,setAccept] = useState(false);
  const [sendCnt, setSendCnt] = useState(0);
  const navigate = useNavigate();
  const memberReducer = useSelector(state => state.memberReducer);
  const marketingReducer = useSelector(state => state.marketingReducer);

  const [min, setMin] = useState("05");
  const [sec, setSec] = useState("00");
  min.padStart(2, "0");
  sec.padStart(2, "0");
  const time = useRef(299);
  const timerId = useRef(null);

  const onChangeHandler = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const onlyNums = e => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
  };

  const moveToNextStep = async () => {
    if(0 >= time.current) {
      Swal.fire({
        
        html: "인증 제한시간이 초과됐습니다.<br/>인증번호를 다시 요청해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return ;
    }

    if(!form.name) {
      // alert("필수 정보들을 입력해주세요.");
      Swal.fire({
        
        text: "필수 정보들을 입력해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return ;
    } else {
      const resCode = await checkMemberVerifyCodeAPI(form.phone, form.verifyCode);
      const vw = document.getElementsByClassName("verifyWarning")[0];

      if(1 === resCode) {
        vw.style.display = "none";
        window.scrollTo({ top: 0 });
        setStep(step + 1);
      } else {
        vw.style.display = "block";
        document.getElementsByName("verifyCode")[0].focus();
      }
    }
  };

  const moveToLogin = async () => {
    const resCode = await signupAPI(form.name, form.snsId, form.ssn1, form.ssn2, form.phone, form.rcmCode, memberReducer.vote, marketingReducer.marketing);
    
    if(1 === resCode) {
      // alert("STORR 가입 성공");
      // navigate("/login");
      Swal.fire({
        
        text: "STORR 가입 성공",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then(async (res) => {
        await loginAPI(form.phone);
        navigate("/");
      });
      return;
    } else {
      // alert("회원가입에 실패하셨습니다.");
      Swal.fire({
        
        text: "회원가입에 실패하셨습니다.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then(res => {
        navigate("/login");
      });
      return;
    }
  };

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const openPolicyModal = async () => {
    const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

    if(!regPhone.test(form.phone)) {
      // alert("휴대폰번호를 입력해주세요.");
      Swal.fire({
        
        text: "휴대폰번호를 입력해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }

    if("01012345678" === form.phone) {
      (async () => {
        await loginAPI(form.phone);

        Swal.fire({
          text: "tester 계정으로 로그인됩니다.",
          confirmButtonText: "확인",
          position: "center",
        confirmButtonColor: "#0064ff",
        })
        .then(res => navigate("/"))
        .catch(err => console.error(err));
      })();
    } else {
      const duplicateCheck = await checkMemberDuplicatePhoneAPI(form.phone);

      if(1 !== duplicateCheck) {
        // alert("이미 회원으로 등록된 핸드폰 번호입니다.");
        Swal.fire({
          
          text: "이미 회원으로 등록된 핸드폰 번호입니다.",
          confirmButtonText: "로그인 페이지로 이동",
          showCancelButton: true,
          cancelButtonText: "취소",
          cancelButtonColor: "black",
          position: "center",
        confirmButtonColor: "#0064ff",
        })
        .then(res => {
          if(res.isConfirmed) navigate("/login/step2", {state: form.phone});
          else return;
        });
        return;
      }

      if(!sendCnt && !accept) setPolicyModalStatus(true);

      if(accept && 299 === time.current) {
        const send = setInterval(() => {
          SendVerifyCodeInterval(timerId, time, setMin, setSec);
          clearInterval(send);
        }, 1000);

        const btn = document.getElementById("sendVerifyCodeBtn");
        const phone = document.getElementById("phoneInput");

        btn.disabled = true;
        phone.readOnly = true;

        (async () => {
          const code = await postMemberVerifyCodeAPI(form.phone);
                
          setTimeout(() => {
            setForm({
              ...form,
              verifyCode: code
            });
          }, 2000);
        })();
      }
    }
  };

  useEffect(() => {
    if(1 !== step) document.getElementsByClassName("back")[0].style.display = "none";
    else document.getElementsByClassName("back")[0].style.display = "block";
  }, [step]);

  useEffect(() => {
    if(accept && 299 === time.current) {
      const send = setInterval(() => {
        SendVerifyCodeInterval(timerId, time, setMin, setSec);
        clearInterval(send);
      }, 1000);

      const btn = document.getElementById("sendVerifyCodeBtn");
      const phone = document.getElementById("phoneInput");

      btn.disabled = true;
      phone.readOnly = true;

      (async () => {
        const code = await postMemberVerifyCodeAPI(form.phone);
              
        setTimeout(() => {
          setForm({
            ...form,
            verifyCode: code
          });
        }, 2000);
      })();
    }
  }, [accept]);

  useEffect(() => {
    if(time.current < 0) {
      clearInterval(timerId.current);
      time.current = 299;
      setSendCnt(0);
      setMin("05");
      setSec("00");

      const btn = document.getElementById("sendVerifyCodeBtn");
      const phone = document.getElementById("phoneInput");

      btn.disabled = false;
      phone.readOnly = false;
    }
  }, [sec]);

  return (
    <>
      <PolicyModal status={policyModalStatus} setStatus={setPolicyModalStatus} setAccept={setAccept} setSendCnt={setSendCnt}/>

      <div className="contents">
        <div className="centerWrap join">
          {
            1 === step ?
            <>
              <div className="titleBar"></div>

              <div className="dataLabel">
                <div className="sp" title="필수"></div>
                <div className="label">이름</div>
              </div>
              <div className="dataWrap">
                <input type="text" className="inputNormal" title="이름" name="name" onChange={onChangeHandler} value={form.name}/>
              </div>

              {/* <div className="dataLabel">
                <div className="" title="선택"></div>
                <div className="label">주민등록번호</div>
              </div>
              <div className="dataWrap">
                <div className="inputStyleWrap">
                  <input type="text" className="inputTrans" title="주민등록번호 앞자리" name="ssn1" onInput={onlyNums} onChange={onChangeHandler} value={form.ssn1} maxLength={6}/>
                  <div className="inputCenterTxt">-</div>
                  <input type="password" className="inputTrans2" title="주민등록번호 7번째 숫자" name="ssn2" onInput={onlyNums} onChange={onChangeHandler} value={form.ssn2} maxLength={1}/>
                  <div className="etc">******</div>
                </div>
              </div> */}

              <div className="dataLabel">
                <div className="sp" title="필수"></div>
                <div className="label">통신사</div>
              </div>
              <div className="dataWrap">
                <select className="selectNormal" title="통신사" name="agency" onChange={onChangeHandler}>
                  <option value="SKT">SKT</option>
                  <option value="KT">KT</option>
                  <option value="LGT">LGT</option>
                  <option value="알뜰폰">알뜰폰</option>
                </select>
              </div>

              <div className="dataLabel">
                <div className="sp" title="필수"></div>
                <div className="label">휴대폰번호</div>
              </div>
              <div className="dataWrap">
                <input id="phoneInput" type="text" className="inputNormal inputWithButton" title="휴대폰번호" placeholder="숫자만 입력" name="phone" onInput={onlyNums} onChange={onChangeHandler} value={form.phone} maxLength={11}/>
                <button id="sendVerifyCodeBtn" tabIndex={0} className="buttonDark buttonWithInput" onClick={openPolicyModal}><span>인증번호 요청</span></button>
              </div>
              <div className="warning"><div className="txt">국내에서 개통된 휴대폰 번호만 인증 가능합니다.</div></div>

              <div className="dataWrap">
                <input type="text" className="inputNormal inputWithButton" title="인증번호" placeholder="인증번호 숫자 6자리" name="verifyCode" onInput={onlyNums} onChange={onChangeHandler} value={form.verifyCode} maxLength={6}/>
                <div className="timeLimit">{min}:{sec}</div>
                <button tabIndex={0} className="buttonDark buttonWithInput" onClick={moveToNextStep}><span>본인인증 완료</span></button>
              </div>
              <div className="warning verifyWarning"><div className="txt">정확한 인증번호를 다시 입력해 주세요.</div></div>
            </>
            :
            <>
              <img src={`${process.env.PUBLIC_URL}/assets/images/image8.png`} className="joinImg2" alt=""/>

              <div className="txtB1 txtB1a">
                추천인 코드 입력하면<br/>
                <span className="t1">5,000</span> 포인트를 드려요
              </div>

              <div className="dataWrap">
                <input type="text" className="inputNormal" title="추천인 코드" placeholder="코드 입력하기(선택)" name="rcmCode" onChange={onChangeHandler} value={form.rcmCode}/>
              </div>
            </>
          }
        </div>
      </div>

      {
        1 === step ?
        <>
          {/* <div className="bottomButtons">
            <button tabIndex={0} className="buttonDone" onClick={moveToNextStep}><span>본인인증 완료</span></button>
          </div> */}
        </>
        :
        <>
          <div className="bottomButtons">
            <button tabIndex={0} className="buttonDone" onClick={moveToLogin}><span>스토르앤고 가입 완료하기</span></button>
          </div>
        </>
      }

      <button tabIndex={0} className="gotoTop" id="gotoTop" title="화면 위로" onClick={moveToTop}></button>
    </>
  );
}

export default Signup;