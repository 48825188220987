import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ScrollToTop from "../../js/ScrollToTop";

function DeliveryHistoryComp() {

  const navigate = useNavigate();
  const { state } = useLocation();

  const dateFormat = val => {
    const year = val.substring(0, 4);
    const month = val.substring(5, 7);
    const date = val.substring(8, 10);

    return `${year}년 ${month}월 ${date}일`;
  };

  const dateFormat2 = val => {
    const y = val.substring(0, 4);
    const m = val.substring(5, 7);
    const d = val.substring(8, 10);

    return `${y}-${m}-${d}`;
  };

  const moveBack = () => {
    navigate("/history");
  };

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    ScrollToTop();
  }, []);

  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>수거/배송 내역</h1>
        </div>
      </header>

      <div className="contents contents2">
        <div className="centerWrap">
          {
            state.length ?
            state.map(item => {
              
              return (
                <div key={item.seq} className="deliveryOne">
                  <div className="type">
                    <div className="typeA1">
                      {
                        "C" === item.type && "수거"
                      }
                      {
                        "D" === item.type && "배송"
                      }
                    </div>
                    <div className="typeB1">
                      {
                        "N" === item.face_yn ?
                        "비대면"
                        :
                        "대면"
                      }
                    </div>
                  </div>

                  <div className="titleRow">
                    <div className="left">{dateFormat(item.apply_date)}</div>
                    <div className="right">
                      {
                        "F1" === item.free_yn || "F2" === item.free_yn || "F3" === item.free_yn ?
                        <span className="bold">FREE</span>
                        :
                        <><span className="bold">100,000</span>원</>
                      }
                    </div>
                  </div>

                  <div className="rows">
                    <div className="row">
                      <div className="left">
                        {
                          "C" === item.type && "수거 신청"
                        }
                        {
                          "D" === item.type && "배송 신청"
                        }
                      </div>
                      <div className="right">{dateFormat2(item.regdate)}</div>
                    </div>
                    {
                      item.comp_date ?
                      <div className="row">
                        <div className="left">
                          {
                            "C" === item.type && "D1" === item.status && "수거 완료"
                          }
                          {
                            "D" === item.type && "D1" === item.status && "배송 완료"
                          }
                          {
                            "C" === item.type && "E1" === item.status && "수거 실패"
                          }
                          {
                            "D" === item.type && "E1" === item.status && "배송 실패"
                          }
                          {
                            "C" === item.type && "C2" === item.status && "수거 취소"
                          }
                          {
                            "D" === item.type && "C2" === item.status && "배송 취소"
                          }
                        </div>
                        <div className="right">{item.comp_date}</div>
                      </div>
                      :
                      <></>
                    }

                    {
                      item.fail_seq ?
                      <div className="warning">
                        <div className="txt">
                          {`실패 사유 : ${item.reason}`}
                        </div>
                      </div>
                      :
                      <></>
                    }
                  </div>
                </div>
              );
            })
            :
            <></>
          }

          <div className="warningX">
            <div className="txt"><span className="bold">부재중, 공동현관 출입 불가</span> 등의 사유로 <br/><span className="bold">수거/배송에 실패할 경우</span><br/>무료 혜택 횟수가 차감되거나 추가 비용이 발생할 수 있습니다.</div>
          </div>
        </div>
      </div>

      <button tabIndex={0} className="gotoTop" id="gotoTop" title="화면 위로" onClick={moveToTop}></button>
    </>
  );
}

export default DeliveryHistoryComp;