import useCurrentLocation from "./useCurrentLocation";

const geolocationOptions = {
  enableHighAccuracy: true,
  timeout: 1000 * 60 * 1,
  maximumAge: 1000 * 3600 * 24,
};

export default function SearchCurrentLocation() {

  const {location, error} = useCurrentLocation(geolocationOptions);
  
  console.log("location : ", location);
};