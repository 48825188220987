function MypageHeader() {

  return (
    <header className="homeHeader">
      <div className="centerWrap">
        <a href="/" className="homeLogo" title="홈">
          <img src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} alt="STORR"/>
        </a>

        {/* <div className="floatRight">
          <button tabIndex={0} className="qmark" title="안내"></button>
            {
              sessionStorage.getItem("mem_seq") && "0" !== sessionStorage.getItem("mem_seq") ?
              <button tabIndex={0} className="bell" title="알림">
                <div className="alarm">알림 있음</div>
              </button>
              :
              <></>
            }
        </div> */}
      </div>
    </header>
  );
}

export default MypageHeader;