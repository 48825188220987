import { TEST_IMAGE } from "../../port/Port";

function DeliveryCompleteModal({applyList, status, setStatus}) {

  let type;
  let currentStatus;

  if(status.includes("C")) type = "수거";
  else if(status.includes("D")) type = "배송";

  if(status.includes("D1")) currentStatus = "완료";
  else if(status.includes("E1")) currentStatus = "실패";

  const closeModal = () => {
    setStatus("");
  };

  const dateFormat = val => {
    const year = val.substring(0, 4);
    const month = val.substring(5, 7);
    const date = val.substring(8, 10);

    return `${year}년 ${month}월 ${date}일`;
  };

  return (
    <>
      <div className={ status ? "modal midModal active" : "modal midModal" }>
        <div className="modalNoHeader">
          <div className="modalTxt1">{`${dateFormat(applyList[0].comp_date)}`} <span className="bold">{`${type}${currentStatus}`}</span></div>
          <img src={`${TEST_IMAGE}/storage/${applyList[0].filename_svr}`} className="imgDelivery" alt=""/>
          {
            applyList[0].reason && "실패" === currentStatus ?
            <div className="reason">{applyList[0].reason}</div>
            :
            <></>
          }
        </div>

        <div className="modalButtons">
          <div className="inside">
            <button tabIndex={0} className="buttonDone" title="" onClick={closeModal}>확인</button>
          </div>
        </div>
      </div>

      <button tabIndex={0} className={ status ? "dimm active" : "dimm" } title="닫기" onClick={closeModal}></button>
    </>
  );
}

export default DeliveryCompleteModal;