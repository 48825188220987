import { useEffect, useState } from "react";
import { deleteMemberAddressAPI, getMemberAddressListAPI } from "../../../apis/MemberAPICalls";
import { useNavigate } from "react-router-dom";
import AddrDefaultChangeModal from "../../modal/AddrDefaultChangeModal";
import { useDispatch } from "react-redux";
import { POST_MEMBER_COLL_ADDR_CNT, POST_MEMBER_DEL_ADDR_CNT } from "../../../modules/member/MemberModule";
import SeperatePhoneNum from "../../../js/SeperatePhoneNum";
import Swal from "sweetalert2";
import ScrollToTop from "../../../js/ScrollToTop";
import { decrypt } from "../../../js/encrypt";

function AddressListComp() {

  const urlParams = new URL(location.href).searchParams;
  const type = urlParams.get("type");
  const mem_seq = sessionStorage.getItem("mem_seq") ? decrypt(sessionStorage.getItem("mem_seq")) : "";
  const [list, setList] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);
  const [addSeq, setAddSeq] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const moveBack = () => {
    // window.history.back();  
    navigate("/mypage");
  };

  const moveToRegistAddr = () => {
    navigate(`/delivery/registAddr?type=${type}`);
  };

  const moveToModifyAddr = e => {
    const addr_seq = e.target.value;
    navigate(`/delivery/modifyAddr?type=${type}&seq=${addr_seq}`);
  };

  const deleteAddress = e => {
    const mem_seq = sessionStorage.getItem("mem_seq") ? decrypt(sessionStorage.getItem("mem_seq")) : "";
    const addr_seq = e.target.value;
    
    // if(confirm("정말로 선택한 주소를 삭제하시겠습니까?")) {
    //   (async () => {
    //     await deleteMemberAddressAPI(mem_seq, addr_seq, type);
    //   })();
    // } else {
    //   return;
    // }
    Swal.fire({
      text: "정말로 선택한 주소를 삭제하시겠습니까?",
      confirmButtonText: "확인",
      showCancelButton: true,
      cancelButtonText: "취소",
      cancelButtonColor: "black",
      position: "center",
        confirmButtonColor: "#0064ff",
    })
    .then(async (res) => {
      if(res.isConfirmed) {
        await deleteMemberAddressAPI(mem_seq, addr_seq, type);

        location.replace(location.href);
      } else {
        return;
      }
    })
    .catch(err => console.error(err));
  };

  const openModal = e => {
    setAddSeq(e.target.getAttribute("value"));
    setModalStatus(true);
  };

  useEffect(() => {
    ScrollToTop();

    if(!list.length) {
      (async () => {
        setList(await getMemberAddressListAPI(mem_seq, type));
      })();
    }
  }, []);

  useEffect(() => {
    if("C" === type) dispatch({ type: POST_MEMBER_COLL_ADDR_CNT, payload: list.length });
    else if("D" === type) dispatch({ type: POST_MEMBER_DEL_ADDR_CNT, payload: list.length });
  }, [list]);

  return (
    <>
      <AddrDefaultChangeModal seq={addSeq} setSeq={setAddSeq} status={modalStatus} setStatus={setModalStatus} type={type}/>
      
      {
        "C" === type ?
        <>
          <header>
            <div className="centerWrap">
              <button onClick={moveBack} tabIndex={0} className="back" title="뒤로"></button>
              <h1>수거지 선택</h1>
            </div>
          </header>

          <div className="contents deliveryContents type2">
            <div className="centerWrap">
              {
                list.map(item => {
                  
                  return (
                    <div className="addressOne" key={item.seq}>
                      <div className="row">
                        {
                          "Y" === item.default_yn ?
                          <>
                            <div className="tagA tagA1">기본수거지</div>
                          </>
                          :
                          <></>
                        }
                        <div className="tagA tagA2">EV</div>
                        {
                          item.addr1.includes("서울") ?
                          <div className="tagA">서비스 가능</div>
                          :
                          item.addr1.includes("경기") && item.addr1.includes("하남") ?
                          <div className="tagA">서비스 가능</div>
                          :
                          item.addr1.includes("경기") && item.addr1.includes("구리") ?
                          <div className="tagA">서비스 가능</div>
                          :
                          <div className="tagA">서비스 불가능</div> 
                        }
                      </div>

                      <div className="row row2">
                        <label className="addressCol">
                          {/* {
                            list.length > 1 ?
                            <>
                              <input type="radio" name="address1"/>
                              <div className="icon"></div>
                            </>
                            :
                            <></>
                          } */}

                          <div className="txtWrap">
                            <div className="addressTxt">{`${item.addr1} ${item.addr2}`}</div>
                            <div className="phoneTxt">{SeperatePhoneNum(item.phone)}</div>
                          </div>
                        </label>
                      </div>

                      <div className="row row3">
                        <button value={item.seq} tabIndex={0} className="buttonEdit" onClick={moveToModifyAddr}>수정</button>
                        {
                          list.length === 1 ?
                          <></>
                          :
                          <button value={item.seq} tabIndex={0} className="buttonDelete" onClick={deleteAddress}>삭제</button>
                        }
                        {
                          "N" === item.default_yn ?
                          <span value={item.seq} className="changeDefaultBtn" onClick={openModal}>기본 수거지로 등록하기</span>
                          :
                          <></>
                        }
                      </div>
                    </div>
                  );
                })
              }

              {
                !list.length ?
                <div className="warningX">
                  <div className="txt">현재 등록된 주소가 없습니다.</div>
                </div>
                :
                <></>
              }
            </div>
          </div>

          <div className="bottomButtons">
            <button onClick={moveToRegistAddr} tabIndex={0} className="buttonDone">수거지 정보 추가</button>
          </div>
        </>
        :
        <>
          <header>
            <div className="centerWrap">
              <button onClick={moveBack} tabIndex={0} className="back" title="뒤로"></button>
              <h1>배송지 선택</h1>
            </div>
          </header>

          <div className="contents deliveryContents type2">
            <div className="centerWrap">
              {
                list.map(item => {
                  return (
                    <div className="addressOne" key={item.seq}>
                      <div className="row">
                        {
                          "Y" === item.default_yn ?
                          <div className="tagA tagA1">기본배송지</div>
                          :
                          <></>
                        }
                        <div className="tagA tagA2">EV</div>
                        {
                          item.addr1.includes("서울") ?
                          <div className="tagA">서비스 가능</div>
                          :
                          item.addr1.includes("경기") && item.addr1.includes("하남") ?
                          <div className="tagA">서비스 가능</div>
                          :
                          item.addr1.includes("경기") && item.addr1.includes("구리") ?
                          <div className="tagA">서비스 가능</div>
                          :
                          <div className="tagA">서비스 불가능</div> 
                        }
                      </div>
                      
                      <div className="row row2">
                        <label className="addressCol">
                          {/* <input type="radio" name="address1"/>
                          <div className="icon"></div> */}

                          <div className="txtWrap">
                            <div className="addressTxt">{`${item.addr1} ${item.addr2}`}</div>
                            <div className="phoneTxt">{SeperatePhoneNum(item.phone)}</div>
                          </div>
                        </label>
                      </div>

                      <div className="row row3">
                        <button value={item.seq} tabIndex={0} className="buttonEdit" onClick={moveToModifyAddr}>수정</button>
                        {
                          list.length === 1 ?
                          <></>
                          :
                          <button value={item.seq} tabIndex={0} className="buttonDelete" onClick={deleteAddress}>삭제</button>
                        }
                        {
                          "N" === item.default_yn ?
                          <span value={item.seq} className="changeDefaultBtn" onClick={openModal}>기본 배송지로 등록하기</span>
                          :
                          <></>
                        }
                      </div>
                    </div>
                  );
                })
              }

              {
                !list.length ?
                <div className="warningX">
                  <div className="txt">현재 등록된 주소가 없습니다.</div>
                </div>
                :
                <></>
              }
            </div>
          </div>

          <div className="bottomButtons">
            <button onClick={moveToRegistAddr} tabIndex={0} className="buttonDone">배송지 정보 추가</button>
          </div>
        </>
      }
    </>
  );
}

export default AddressListComp;