import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteMemberPaymentItemAPI, getMemberPaymentListAPI } from "../../../apis/MemberAPICalls";
import PaymentDefaultChangeModal from "../../modal/PaymentDefaultChangeModal";
import CreateToken from "../../../js/CreateToken";
import { useDispatch, useSelector } from "react-redux";
import { POST_MEMBER_DEFAULT_PAYMENT_INFO } from "../../../modules/member/MemberModule";
import Swal from "sweetalert2";
import CardName from "../../../js/CardName";
import ScrollToTop from "../../../js/ScrollToTop";
import { decrypt } from "../../../js/encrypt";

function PaymentManageComp() {

  const memberReducer = useSelector(state => state.memberReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);
  const [paymentSeq, setPaymentSeq] = useState(0);

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const moveBack = () => {
    navigate("/mypage");
  };

  const moveToRegist = () => {
    navigate("registPayment");
  };

  // const moveToModify = e => {
  //   navigate(`modifyPayment?seq=${e.target.value}`);
  // };

  const deletePaymentItem = e => {
    Swal.fire({
      text: "해당 결제수단을 삭제하시겠습니까?",
      confirmButtonText: "확인",
      showCancelButton: true,
      cancelButtonText: "취소",
      cancelButtonColor: "black",
      position: "center",
        confirmButtonColor: "#0064ff",
    })
    .then(async (res) => {
      if(res.isConfirmed) {
        const resCode = await deleteMemberPaymentItemAPI(decrypt(sessionStorage.getItem("mem_seq")), e.target.getAttribute("value"), CreateToken());

        if(1 !== resCode) {
          // alert("결제 수단 삭제 실패");
          Swal.fire({
            text: "결제 수단 삭제 실패",
            confirmButtonText: "확인",
            position: "center",
        confirmButtonColor: "#0064ff",
          })
          .then();
          return;
        } else {
          // alert("성공적으로 삭제했습니다.");
          // location.replace(location.href);
          Swal.fire({
            text: "성공적으로 삭제했습니다.",
            confirmButtonText: "확인",
            position: "center",
        confirmButtonColor: "#0064ff",
          })
          .then(res => location.replace(location.href));
          return;
        }
      }
    })
    .catch(err => console.error(err));
  };

  const openModal = e => {
    setPaymentSeq(e.target.value);
    setModalStatus(true);
  };

  useEffect(() => {
    ScrollToTop();

    if(!sessionStorage.getItem("mem_seq") || "0" === decrypt(sessionStorage.getItem("mem_seq"))) navigate("/login");

    if(!list.length) {
      (async () => {
        setList(await getMemberPaymentListAPI(decrypt(sessionStorage.getItem("mem_seq"))));
      })();
    }
  }, []);

  useEffect(() => {
    const defaultCard = list.filter(item => "Y" === item.default_yn)[0];

    if(defaultCard) dispatch({ type: POST_MEMBER_DEFAULT_PAYMENT_INFO, payload: defaultCard });
  }, [list]);

  return (
    <>
      <header>
        <div className="centerWrap">
          <button onClick={moveBack} tabIndex={0} className="back" title="뒤로"></button>
          <h1>결제 수단 관리</h1>
        </div>
      </header>

      <PaymentDefaultChangeModal seq={paymentSeq} setSeq={setPaymentSeq} status={modalStatus} setStatus={setModalStatus} />

      <div className="contents contents2">
        <div className="centerWrap">
          {/* 회원이 등록한 결제수단 목록 불러온걸 map으로 뿌려줘야함 */}
          {
            list.length ?
            list.map(item => {
              return (
                <div key={item.seq} className="payBox">
                  <div className="row">
                    <div className={"Y" === item.default_yn ? "payment default" : "payment"}>{"Y" === item.default_yn ? "기본결제수단" : "추가결제수단"}</div>

                    <div className="floatRight">
                      {/* <button value={item.seq} tabIndex={0} className="buttonD4Edit" onClick={moveToModify}>수정</button> */}
                      {
                        "N" === item.default_yn ?
                        <span value={item.seq} className="changeDefaultBtn" onClick={openModal}>기본 결제수단으로 등록하기</span>
                        :
                        <></>
                      }
                      {
                        "N" === item.default_yn ?
                        <button value={item.seq} tabIndex={0} className="buttonD4Delete" onClick={deletePaymentItem}>삭제</button>
                        :
                        <></>
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="label">
                      {
                        CardName(item.card_code)
                      }
                    </div>
                    <div className="number">{`${item.card_num.substr(0, 4)}-****-****-${item.card_num.substr(-4)}`}</div>
                  </div>
                </div>
              );
            })
            :
            <div className="payBox">
              <div className="row">
                <div className="label">
                  등록된 카드가 없습니다.
                </div>
                <button tabIndex={0} className="buttonD4Edit" onClick={moveToRegist}>등록</button>
              </div>
            </div>
          }
          
          {
            1 === list.length ?
            <div className="warningX">
              <div className="txt">해당 결제 수단을 <span className="bold">삭제</span>하려면, <br/><span className="bold">다른 결제수단을 등록</span>해야 합니다.</div>
            </div>
            :
            <></>
          }
        </div>
      </div>

      <div className="bottomButtons">
        <button onClick={moveToRegist} tabIndex={0} className="buttonDone">결제 수단 등록하기</button>
      </div>

      <button onClick={moveToTop} tabIndex={0} className="gotoTop" id="gotoTop" title="화면 위로"></button>
    </>
  );
}

export default PaymentManageComp;