import { useNavigate } from "react-router-dom";
import { memberWithdrawAPI } from "../../apis/MemberAPICalls";
import CreateToken from "../../js/CreateToken";
import { decrypt } from "../../js/encrypt";
import Swal from "sweetalert2";

export default function MemberWithdrawModal({ status, setStatus }) {

  const navigate = useNavigate();

  const closeModal = () => {
    setStatus(false);
  };

  const accept = async () => {
    const code = await memberWithdrawAPI(decrypt(sessionStorage.getItem("mem_seq")), CreateToken());

    if (1 === code) {
      Swal.fire({
        text: "Storr를 이용해주셔서 감사합니다.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then(res => {
        sessionStorage.clear();
        localStorage.removeItem("mem_seq");
        navigate("/login", {replace: true});
      });
    } else {
      Swal.fire({
        text: "회원탈퇴 중 오류가 발생했습니다.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then(res => { return; });
    }
  };

  return (
    <>
      <div className={status ? "modal smallModal active" : "modal smallModal"}>
        <div className="modalNoHeader">
          <div className="modalTxt1">
            STORR의 특별한 회원할인혜택 및<br />
            그동안 적립한 포인트와 쿠폰을 모두 포기하고<br />
            회원을 탈퇴하시겠습니까?<br /><br />

            <span className="bold">회원자격<span />을 유지하면<br />포인트와 쿠폰은 사용이 가능합니다.<br />(3개월 이내 서비스 재신청시 적용)</span>
          </div>
        </div>

        <div className="modalButtons">
          <div className="inside">
            <button tabIndex={0} className="buttonCancel button1in2" title="" onClick={closeModal}>회원 유지하기</button>
            <button tabIndex={0} className="buttonDone button1in2" title="" onClick={accept}>회원 탈퇴하기</button>
          </div>
        </div>
      </div>

      <button tabIndex={0} className={status ? "dimm active" : "dimm"} title="닫기" onClick={closeModal}></button>
    </>
  );
}