import { changeDefaultAddrAPI } from "../../apis/MemberAPICalls";
import CreateToken from "../../js/CreateToken";
import Swal from "sweetalert2";
import { decrypt } from "../../js/encrypt";

function AddrDefaultChangeModal({ seq, setSeq, status, setStatus, type }) {
  
  const mem_seq = sessionStorage.getItem("mem_seq") ? decrypt(sessionStorage.getItem("mem_seq")) : "";

  const closeModal = () => {
    setStatus(false);
    setSeq(0);
  };

  const changeDefaultAddr = () => {
    (async () => {
      const resCode = await changeDefaultAddrAPI(mem_seq, seq, type, CreateToken());

      if(1 === resCode) {
        setStatus(false);
        location.replace(location.href);
      } else {
        if("C" === type) {
          // alert("기본 수거지 변경 실패");
          Swal.fire({
            
            text: "기본 수거지 변경 실패",
            confirmButtonText: "확인",
            position: "center",
        confirmButtonColor: "#0064ff",
          })
          .then();
          return;
        } else {
          Swal.fire({
            
            text: "기본 배송지 변경 실패",
            confirmButtonText: "확인",
            position: "center",
        confirmButtonColor: "#0064ff",
          })
          .then();
          return;
        }
      }
    })();
  };

  return (
    <>
      <div className={ status ? "modal smallModal active" : "modal smallModal" }>
        <div className="modalNoHeader">
          <div className="modalTxt1">
            선택하신 주소를 <span className="bold">{"C" === type ? "기본 수거지" : "기본 배송지"}</span>로<br/>
            등록하시겠습니까?
          </div>
        </div>

        <div className="modalButtons">
          <div className="inside">
            <button tabIndex={0} className="buttonCancel button1in2" title="" onClick={closeModal}>취소</button>
            <button tabIndex={0} className="buttonDone button1in2" title="" onClick={changeDefaultAddr}>등록</button>
          </div>
        </div>
      </div>

      <button tabIndex={0} className={ status ? "dimm active" : "dimm" } title="닫기" onClick={closeModal}></button>
    </>
  );
}

export default AddrDefaultChangeModal;