import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TEST_ADD } from "../../port/Port";
import { encrypt, decrypt } from "../../js/encrypt";

function Login() {

  const navigate = useNavigate();

  const moveToJoin = () => {
    navigate("/join");
  };

  const moveToMain = () => {
    sessionStorage.setItem("mem_seq", encrypt("0"));
    sessionStorage.setItem("temporary", "Y");
    navigate("/");
  };

  const moveToLogin = () => {
    navigate("/login/step2");
  };

  const moveToIntroduction = () => {
    navigate("/introduction");
  };

  const showSnsLogin = () => {
    const el = document.getElementsByClassName("snsLogins")[0];

    el.classList.add("active");
  };

  const moveToNaverLogin = () => {
    // navigate("https://nid.naver.com/oauth2.0/authorize?client_id=JrgdO34iQmKQT2wVJkOW&response_type=code&redirect_uri=http://192.168.1.241:8080/naverAuthCallback.do&state=1236548795464");
    window.location.href = `https://nid.naver.com/oauth2.0/authorize?client_id=JrgdO34iQmKQT2wVJkOW&response_type=code&redirect_uri=${TEST_ADD}/naverAuthCallback.do&state=1236548795464`;
  };

  const moveToKakaoLogin = () => {
    // navigate("https://kauth.kakao.com/oauth/authorize?client_id=2869d114c59053d1ffa59212c413fe73&redirect_uri=http://192.168.1.241:8080/kakaoAuthCallback.do&response_type=code");
    window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=2869d114c59053d1ffa59212c413fe73&redirect_uri=${TEST_ADD}/kakaoAuthCallback.do&response_type=code`;
  };

  const moveToGoogleLogin = () => {
    // navigate("https://kauth.kakao.com/oauth/authorize?client_id=2869d114c59053d1ffa59212c413fe73&redirect_uri=http://192.168.1.241:8080/kakaoAuthCallback.do&response_type=code");
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=632695656155-qcs03qmg28qo47q0sh65pt0vl66dlkjf.apps.googleusercontent.com&redirect_uri=${TEST_ADD}/googleAuthCallback.do&response_type=code&scope=email%20profile%20openid&access_type=offline`;
  };

  const moveToFacebookLogin = () => {
    window.location.href = `https://www.facebook.com/v18.0/dialog/oauth?client_id=682770990350665&redirect_uri=${TEST_ADD}/facebookAuthCallback.do&state=12345abcd`;
  };

  useEffect(() => {
    if(sessionStorage.getItem("mem_seq") && "0" !== decrypt(sessionStorage.getItem("mem_seq"))) navigate("/");
  }, []);

  return(
    <div className="loginBody">
      <div className="login new">
        <div className="topLogoWrap">
          <div className="centerWrap">
          <img src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} className="logo" alt="STORR"/>
          </div>
        </div>
        <div className="txt1" style={{ color: "black" }}>가장 혁신적인<br/>배송형 짐보관 구독서비스</div>

        <img src={`${process.env.PUBLIC_URL}/assets/images/logo1.png`} className="centerLogo" alt=""/>

        {/* <button tabIndex={0} className="button0" onClick={moveToIntroduction}><span>STORR&Go 서비스 안내</span></button> */}

        <button tabIndex={0} className="button0 new" onClick={moveToMain}><span style={{ color: "black" }}>스토르앤고 둘러보기</span></button>
        <button tabIndex={0} className="button1 new" onClick={moveToJoin} style={{ borderRadius: "0" }}><span>서비스 가입</span></button>
        <button tabIndex={0} className="button2 new" onClick={moveToLogin} style={{ borderRadius: "0" }}><span>회원 로그인</span></button>
        {/* <button tabIndex={0} className="button2" onClick={showSnsLogin}><span>Log-in for foreigners</span></button> */}

        <div className="snsLogins">
          <div className="inside">
            <button tabIndex={0} className="snsLogin google" title="SNS 로그인 : 구글" onClick={moveToGoogleLogin}></button>
            <button tabIndex={0} className="snsLogin kakao" title="SNS 로그인 : 카카오" onClick={moveToKakaoLogin}></button>
            <button tabIndex={0} className="snsLogin apple" title="SNS 로그인 : 애플"></button>
            <button tabIndex={0} className="snsLogin facebook" title="SNS 로그인 : 페이스북" onClick={moveToFacebookLogin}></button>
            <button tabIndex={0} className="snsLogin naver" title="SNS 로그인 : 네이버" onClick={moveToNaverLogin}></button>
          </div>
        </div>
      </div>

      <div className="bgBottom">
        <div className="copyRight">
          법인명: 스토리지엠주식회사 | 주소: 서울시 금천구 가산디지털 1로 225, 11층<br/>
          대표이사: 박건태 | 사업자등록번호: 101-88-02390<br/>
          고객센터: 1670-4670 | 통신판매번호: 제 2022-서울금천-1700호<br/>
          Copyright © Storage M, Inc. All Rights Reserved.
        </div>
      </div>
    </div>
  );
}

export default Login;