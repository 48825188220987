import { useNavigate } from "react-router-dom";

export default function StorrWithdraw() {

  const navigate = useNavigate();

  const moveToMain = () => {
    if(localStorage.getItem("mem_seq") || sessionStorage.getItem("mem_seq")) navigate("/");
    else navigate("/login");
  };

  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveToMain}></button>
          <h1>스토르앤고 회원탈퇴 문의</h1>
        </div>
      </header>

      <div className="contents">
        <div className="centerWrap">
          회원탈퇴 문의는 <a href="mailto:moonufo73@hanmail.net?subject=회원탈퇴 문의">moonufo73@hanmail.net</a>로 보내주세요.
        </div>
      </div>
    </>
  );
}