import { useNavigate } from "react-router-dom";
import CreateToken from "../../js/CreateToken";
import { directCancelServiceAPI } from "../../apis/MemberAPICalls";
import { decrypt } from "../../js/encrypt";

function ConfirmCancelServiceModal({ status, setStatus, purchasedInfo, type }) {

  const navigate = useNavigate();
  
  const closeModal = () => {
    setStatus(false);
  };

  const moveToTerminateService = () => {
    setStatus(false);
    navigate("/terminateService");
  };

  const directCancelService = async () => {
    const resCode = await directCancelServiceAPI(decrypt(sessionStorage.getItem("mem_seq")), purchasedInfo.seq, CreateToken());

    if(1 === resCode) navigate("/history", {replace: true});
    else return;
  };

  // const withdrawMember = async () => {
  //   const resCode = await memberWithdrawAPI(decrypt(sessionStorage.getItem("mem_seq")), CreateToken());
    
  //   if(1 === resCode) {
  //     Swal.fire({
  //       text: "회원탈퇴가 완료됐습니다.",
  //       confirmButtonText: "확인",
  //       position: "center",
  //       confirmButtonColor: "#0064ff",
  //     })
  //     .then(res => {
  //       sessionStorage.clear();
  //       localStorage.removeItem("mem_seq");
  //       navigate("/login");
  //     })
  //     .catch(err => console.error(err));
  //   } else {
  //     Swal.fire({
  //       text: "회원탈퇴 중 오류가 발생했습니다.",
  //       confirmButtonText: "확인",
  //       position: "center",
  //       confirmButtonColor: "#0064ff",
  //     })
  //     .then(res => {return;});
  //   }
  // };

  return (
    <>
      {/* <MemberWithdrawModal status={wStatus} setStatus={setWStatus} purchasedInfo={purchasedInfo}/> */}

      {
        type ?
        <div className={ status ? "modal smallModal active" : "modal smallModal" }>
          <div className="modalNoHeader">
            <div className="modalTxt1">
              STORR의 프리미엄 서비스를<br/>
              정말 해지 하시겠습니까?<br/><br/>

              <span className="bold">우수고객 혜택 및<br/>이번달 적립된 무료 크레딧이 사라집니다.</span>
            </div>
          </div>

          <div className="modalButtons">
            <div className="inside">
              <button tabIndex={0} className="buttonCancel button1in2" title="" onClick={closeModal}>서비스 유지하기</button>
              <button tabIndex={0} className="buttonDone button1in2" title="" onClick={moveToTerminateService}>서비스 해지하기</button>
            </div>
          </div>
        </div>
        :
        <div className={ status ? "modal smallModal active" : "modal smallModal" }>
          <div className="modalNoHeader">
            <div className="modalTxt1">
              STORR의 프리미엄 서비스를<br/>
              바로 해지 하시겠습니까?
            </div>
          </div>

          <div className="modalButtons">
            <div className="inside">
              <button tabIndex={0} className="buttonCancel button1in2" title="" onClick={closeModal}>결제 유지하기</button>
              <button tabIndex={0} className="buttonDone button1in2" title="" onClick={directCancelService}>결제 해지하기</button>
            </div>
          </div>
        </div>
      }

      <button tabIndex={0} className={ status ? "dimm active" : "dimm" } title="닫기" onClick={closeModal}></button>
    </>
  );
}

export default ConfirmCancelServiceModal;