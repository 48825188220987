import { useRef } from "react";
import { useNavigate } from "react-router-dom";

function MainMenu() {

  const menuRef = useRef();
  const navigate = useNavigate();
  const path = window.location.pathname;

  const moveToMain = () => {
    navigate("/");
  };

  const moveToDelivery = () => {
    navigate("/delivery");
  };

  const moveToHistory = () => {
    navigate("/history");
  };

  const moveToMypage = () => {
    navigate("/mypage");
  };

  return (
    <div className="mainMenus" ref={menuRef}>
        <div className="centerWrap">
          <a onClick={moveToMain} className={"/" === path ? "mainMenu active" : "mainMenu"}>
            <div className="iconM"></div>
            <div className="txtM">홈</div>
          </a>

          <a onClick={moveToDelivery} className={path.includes("delivery") ? "mainMenu active" : "mainMenu"}>
            <div className="iconM"></div>
            <div className="txtM">수거/배송신청</div>
          </a>

          <a className="mainMenu">
            <div className="iconL"></div>
          </a>

          <a onClick={moveToHistory} className={path.includes("history") ? "mainMenu active" : "mainMenu"}>
            <div className="iconM"></div>
            <div className="txtM">이용내역</div>
          </a>

          <a onClick={moveToMypage} className={path.includes("mypage") ? "mainMenu active" : "mainMenu"}>
            <div className="iconM"></div>
            <div className="txtM">마이페이지</div>
          </a>
        </div>
      </div>
  );
}

export default MainMenu;