import { useLocation, useNavigate } from "react-router-dom";
import PriceToString from "../../js/PriceToString";
import { useEffect, useState } from "react";
import { getMemberPayHistoryAPI } from "../../apis/MemberAPICalls";
import EncryptCardNum from "../../js/EncryptCardNum";
import { useSelector } from "react-redux";
import ScrollToTop from "../../js/ScrollToTop";
import { decrypt } from "../../js/encrypt";

function PaymentHistoryComp() {

  const navigate = useNavigate();
  const { state } = useLocation();  // [0] : serviceInfo, [1] : purchasedList[0]
  const [historyList, setHistoryList] = useState([]);
  const memberReducer = useSelector(state => state.memberReducer);
  
  const moveBack = () => {
    navigate("/history");
  };

  // const dateFormat = date => {
    
  // };

  const btw1y = val => {
    const comp = new Date();
    comp.setFullYear(comp.getFullYear() - 1);
    comp.setHours(0);
    comp.setMinutes(0);
    comp.setSeconds(0);
    const regdate = new Date(val);
    regdate.setHours(0);
    regdate.setMinutes(0);
    regdate.setSeconds(0);

    return regdate >= comp;
  };

  const dateFormat2 = str => {
    const year = str.substring(0, 4);
    const month = str.substring(5, 7);
    const date = str.substring(8, 10);

    return `${year}년 ${month}월 ${date}일`;
  };

  useEffect(() => {
    ScrollToTop();

    (async () => {
      if(!historyList.length) {
        const list = await getMemberPayHistoryAPI(decrypt(sessionStorage.getItem("mem_seq")));
        const arr = list.filter(item => btw1y(item.regdate));

        setHistoryList(arr);
      }
    })();
  }, []);

  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>결제 상세 내역</h1>
        </div>
      </header>

      <div className="contents deliveryContents type2">
        <div className="centerWrap">
          <div className="titleWrap2">
            <div className="title">현재 이용중인 서비스</div>
          </div>
          <div className="box1">
            <div className="service">
              <div className="row">
                <div className="status silver">
                  {
                    1 === memberReducer.memInfo.rate_seq && "BRONZE"
                  }
                  {
                    2 === memberReducer.memInfo.rate_seq && "SILVER"
                  }
                  {
                    3 === memberReducer.memInfo.rate_seq && "GOLD"
                  }
                  {
                    4 === memberReducer.memInfo.rate_seq && "DIAMOND"
                  }
                </div>
              </div>

              <div className="serviceNow">
                {
                  state[1] && ("N" === state[1].refund_yn || "R" === state[1].refund_yn) ?
                  <>
                    <div className="row">
                      <div className="serviceNowTitle type2">{state[0].title}</div>
                    </div>
                    <div className="row row4">
                      <div className="servicePrice">월 {PriceToString(state[0].regular_price - state[0].discount_price)}원</div>
                      <div className="payDate">다음 자동 결제일 : {state[1].service_date_ed ? dateFormat2(state[1].service_date_ed) : ""}</div>
                    </div>
                  </>
                  :
                  <div className="noList">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/nolist1.png`} alt=""/>
                    <div className="txt">현재 이용중인 서비스가 없습니다.</div>
                  </div>
                }
              </div>

              <div className="warning">
                {
                  state[0].title && state[0].title.includes("BASIC") ?
                  <div className="txt">BASIC: 편도 수거/배송 비용 10,000원이 별도 청구됩니다.</div>
                  :
                  <div className="txt">왕복패키지: 편도 수거/배송은 이월된 쿠폰 차감 &gt; 당월 편도 횟수 차감 &gt; 초과 운송요금 결제 순으로 적용됩니다.</div>
                }
              </div>
            </div>
          </div>

          <div className="titleWrap2">
            <div className="title">결제 내역</div>
          </div>
          <div className="txtK1">결제 내역은 지난 1년 동안까지만 표시됩니다.</div>

          <div className="paymentHistories">
            {
              historyList.length ?
              historyList.map(item => {

                return (
                  <div key={item.seq} className="paymentHistory">
                    <div className="row row1">
                      <div className="date">{item.regdate ? dateFormat2(item.regdate) : ""}</div>
                      <div className={"GOODS" === item.pay_type ? "mark mark1" : "COUPON" === item.pay_type ? "mark mark2" : "ADD" === item.pay_type ? "mark mark3" : "mark mark4"}>{"GOODS" === item.pay_type ? "월구독요금" : "COUPON" === item.pay_type ? "쿠폰" : "ADD" === item.pay_type ? "추가운송요금" : "결제취소"}</div>
                      {/* 서비스 원가를 표시해줘야함. 일단 현재는 api가 완전하지 않으므로 실제 카드가 결제한 값을 표시하게 했음. */}
                      <span className={item.point ? "price1 lineTr" : "price1"}>{PriceToString(item.amount + item.point)}원</span>
                      {/* 포인트 할인 있는지 없는지에 따라 추가 태그 */}
                      {/* <span className="price2">포인트 할인 <span className="t1">-10,000원</span></span> */}
                      {
                        item.point ? 
                        <span className="price2">포인트 할인 <span className="t1">-{PriceToString(item.point)}</span></span>
                        :
                        <></>
                      }
                    </div>

                    <div className="title">{item.goodsName}</div>
                    <div className="period">
                      {
                        !item.service_date_st || !item.service_date_ed ?
                        <></>
                        :
                        <>{dateFormat2(item.service_date_st)} ~ {dateFormat2(item.service_date_ed)}</>
                      }  
                    </div>
                    <div className="row payment">
                      <span className="t1">신용카드 {EncryptCardNum(item.card_num)}</span>
                      <span className="t2">총 <span className="bold">{PriceToString(item.amount)}</span>원</span>
                    </div>
                  </div>
                );
              })
              :
              <></>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentHistoryComp;