import AddressListComp from "../../../components/mypage/address/AddressListComp";

function AddressList() {

  return (
    <>
      <AddressListComp/>
    </>
  );
}

export default AddressList;