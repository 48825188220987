function GradeModal({ status, setStatus }) {

  const closeModal = () => {
    setStatus(false);
  };

  return (
    <>
      <div className={ status ? "modal bigModal active" : "modal bigModal" }>
        <div className="modalHeader">
          <div className="modalTitle">스토르앤고 멤버쉽 등급 안내</div>
          <button tabIndex={0} className="closeModal" title="모달 닫기" onClick={closeModal}></button>
        </div>

        <div className="modalContent2">
          <div>
            <div>스토르앤고 BRONZE</div>
            <div>
              - 대상: 서비스 연속 이용 기간 3개월<br/>
              - 혜택: 4개월부터 월 구독요금의 3% 할인 적용
            </div>
          </div>
          <br/>
          <div>
            <div>스토르앤고 SILVER</div>
            <div>
              - 대상: 서비스 연속 이용 기간 6개월<br/>
              - 혜택: 7개월부터 월 구독요금의 5% 할인 적용
            </div>
          </div>
          <br/>
          <div>
            <div>스토르앤고 GOLD</div>
            <div>
              - 대상: 서비스 연속 이용 기간 12개월<br/>
              - 혜택: 13개월부터 월 구독요금의 10% 할인 적용
            </div>
          </div>
        </div>
      </div>

      <button tabIndex={0} className={ status ? "dimm active" : "dimm" } title="닫기" onClick={closeModal}></button>
    </>
  );
}

export default GradeModal;