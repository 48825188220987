import CryptoJS from 'crypto-js';

// const secretKey = process.env.REACT_APP_AES_SECRETKEY;
// const iv = process.env.REACT_APP_AES_IV;

const secretKey = "jetbeqmasgapzdef01p95kl7hdbc1emf";
const iv = "zpsjdqg18bvn2d1l";

// 암호화
export const encrypt = val => {
  const cipher = CryptoJS.AES.encrypt(val, CryptoJS.enc.Utf8.parse(secretKey), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  return cipher.toString();
}

// 복호화
export const decrypt = (encryptedText) => {
  if(encryptedText.includes("dev_")) return encryptedText.replace("dev_", "");
  else {
    const decipher = CryptoJS.AES.decrypt(encryptedText, CryptoJS.enc.Utf8.parse(secretKey), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });
  
    return decipher.toString(CryptoJS.enc.Utf8);
  }
}