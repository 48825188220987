import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMemberFriendListAPI } from "../../../apis/MemberAPICalls";
import PriceToString from "../../../js/PriceToString";
import ScrollToTop from "../../../js/ScrollToTop";
import { decrypt } from "../../../js/encrypt";

function FriendPointHistory() {

  const navigate = useNavigate();
  const [obj, setObj] = useState({});

  const moveBack = () => {
    window.history.back();
  };

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const dateFormat = date => {
    const y = date.substr(0, 4);
    const m = date.substr(5, 2);
    const d = date.substr(8, 2);

    return `${y}.${m}.${d}`;
  };

  useEffect(() => {
    ScrollToTop();

    (async () => {
      setObj(await getMemberFriendListAPI(decrypt(sessionStorage.getItem("mem_seq"))));
    })();
  }, []);

  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>포인트 상세</h1>
        </div>
      </header>

      <div className="contents contents2">
        <div className="centerWrap">
          <div className="row">
            <div className="pointCol">
              <div className="txt1">초대한 친구</div>
              <div className="txt2"><span className="bold">{obj.resultCount}</span> 명</div>
            </div>

            <div className="pointCol">
              <div className="txt1">적립한 포인트</div>
              <div className="txt2"><span className="bold">{Object.keys(obj).length ? PriceToString(obj.resultPointSum) : ""}</span> 점</div>
            </div>
          </div>

          <table className="pointTable">
            <colgroup>
              <col style={{ width: "25%" }}/>
              <col style={{ width: "25%" }}/>
              <col style={{ width: "25%" }}/>
              <col style={{ width: "25%" }}/>
            </colgroup>
            <thead>
              <tr>
                <th>친구 이름</th>
                <th>가입 날짜</th>
                <th>포인트</th>
                <th>지급일</th>
              </tr>
            </thead>
            <tbody>
               {
                Object.keys(obj).length && Object.keys(obj.result).length ?
                obj.result.map(item => {

                  return (
                    <tr key={item.friend_seq}>
                      <td>{item.name}</td>
                      <td>{dateFormat(item.regdate)}</td>
                      <td>{PriceToString(item.point)}</td>
                      <td>{dateFormat(item.regdate)}</td>
                    </tr>
                  );
                })
                :
                <></>
               }
            </tbody>
          </table>

          <div className="warningTitle">유의사항</div>
          <div className="warnings">
            <div className="warning2"><div className="txt">신규 가입 회원만 인정됩니다.</div></div>
            <div className="warning2"><div className="txt">친구 초대는 제한이 없습니다.</div></div>
            <div className="warning2"><div className="txt">친구가 가입 후, 30일 이내 결제 완료 시, 포인트가 지급됩니다. </div></div>
            <div className="warning2"><div className="txt">적립 된 포인트는 익월 결제 시, 자동 차감되며, 최대 100% 사용이 가능합니다.</div></div>
            <div className="warning2"><div className="txt">스토어에서 상품 결제 시에는 사용 불가합니다.</div></div>
            <div className="warning2"><div className="txt">적립금의 유효기간은 6개월 (180일) 입니다.</div></div>
            <div className="warning2"><div className="txt">기타 비정상적인 방법을 통해 이벤트에 참여한 경우, 적립금이 지급되지 않거나, 회수 될 수 있습니다.</div></div>
            <div className="warning2"><div className="txt">친구 초대 이벤트는 정책에 따라 사전 고지 없이 변경 또는 중단 될 수 있습니다.</div></div>
          </div>
        </div>
      </div>

      <button tabIndex={0} className="gotoTop gotoTop2" id="gotoTop" title="화면 위로" onClick={moveToTop}></button>
    </>
  );
}

export default FriendPointHistory;