import DaumPostCode from 'react-daum-postcode';

export default function DaumAddr(props) {

  const onCompletePost = data => {
    props.setForm({
      ...props.form,
      addr: data.address,
      zipCode: data.zonecode
    });
    props.closeDaumPost();
  };

  return (
    <div>
      <DaumPostCode
        onComplete={onCompletePost}>
      </DaumPostCode>
    </div>
  );
}