import { useLocation, useNavigate } from "react-router-dom";
import PriceToString from "../../../js/PriceToString";
import { useEffect } from "react";
import Swal from "sweetalert2";
import ScrollToTop from "../../../js/ScrollToTop";
import { decrypt } from "../../../js/encrypt";

function AddFriend() {

  const navigate = useNavigate();
  const {state} = useLocation();
  
  const moveBack = () => {
    navigate("/mypage");
  };

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const moveToPointHistory = () => {
    navigate("/mypage/friendPointHistory");
  }

  const copyToClipboard = () => {
    const rcm_code = state.rcm_code;

    navigator.clipboard.writeText(rcm_code)
    .then(() => {
      // alert("나의 코드 클립복사 성공");
      Swal.fire({
        
        text: "나의 코드 클립복사 성공",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    })
    .catch(err => {
      // alert("클립복사 실패", err);
      Swal.fire({
        
        text: "클립복사 실패",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    });
  };

  useEffect(() => {
    ScrollToTop();

    if("0" === decrypt(sessionStorage.getItem("mem_seq"))) navigate("/login");

    if(!state) navigate("/login");
  }, []);
  
  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>친구추가</h1>
        </div>
      </header>

      <div className="contents contents2">
        <div className="centerWrap">
          <div className="couponImgWrap">
            <img src={`${process.env.PUBLIC_URL}/assets/images/coupon3.png`} alt="STORR 친구초대 5,000 P"/>
          </div>

          <div className="userPoint">
            <div className="t1"><span className="bold">{state && Object.keys(state).length ? state.name : ""}</span> 님의 포인트</div>
            <button tabIndex={0} className="t2" onClick={moveToPointHistory}>{state && Object.keys(state).length ? PriceToString(state.point) : ""} 점</button>
          </div>

          <button tabIndex={0} className="copyCode" onClick={copyToClipboard}>추천인 코드 복사하기</button>
          {/* <button tabIndex={0} className="shareLink">링크 공유하기</button> */}

          {/* 링크 공유하기 기능 추가되면 warningTitle의 style 삭제 */}
          <div className="warningTitle" style={{ marginTop: "20px" }}>유의사항</div>
          <div className="warnings">
            <div className="warning2"><div className="txt">초대받은 친구가 신규로 가입하는 경우에만 적용됩니다.</div></div>
            <div className="warning2"><div className="txt">초대받은 친구가 서비스 가입 후, 30일 이내 결제 완료 시에 포인트가 적립됩니다.</div></div>
            <div className="warning2"><div className="txt">적립된 포인트는 월 구독 결제 금액에서 자동 차감됩니다.</div></div>
            <div className="warning2"><div className="txt">적립된 포인트의 유효기간은 60일입니다.</div></div>
            <div className="warning2"><div className="txt">부정한 방법으로 이벤트에 참여하는 경우, 이용약관에 따라 적립된 포인트를 회수하거나 지급을 거절할 수 있습니다.</div></div>
            <div className="warning2"><div className="txt">친구 초대 포인트는 마케팅 정책에 따라 사전 고지 없이 변경되거나 또는 중단될 수 있습니다. </div></div>
            {/* <div className="warning2"><div className="txt">기타 비정상적인 방법을 통해 이벤트에 참여한 경우, 적립금이 지급되지 않거나, 회수 될 수 있습니다.</div></div>
            <div className="warning2"><div className="txt">친구 초대 이벤트는 정책에 따라 사전 고지 없이 변경 또는 중단 될 수 있습니다.</div></div> */}
          </div>
        </div>
      </div>

      <button tabIndex={0} className="gotoTop gotoTop2" id="gotoTop" title="화면 위로" onClick={moveToTop}></button>
    </>
  );
}

export default AddFriend;