import { createActions, handleActions } from "redux-actions";

const initialState = {
  serviceInfo: {},
};

export const POST_MEMBER_PURCHASED_SERVICE_INFO = "member/service/POST_MEMBER_PURCHASED_SERVICE_INFO";

const actions = createActions({
  [POST_MEMBER_PURCHASED_SERVICE_INFO]: () => {},
});

const serviceReducer = handleActions({
  [POST_MEMBER_PURCHASED_SERVICE_INFO]: (state, { payload }) => {
    state.serviceInfo = {...payload};

    let newState = {...state};

    return newState;
  },
}, initialState);

export default serviceReducer;