import $ from "jquery";
import PaymentPolicyContent1 from "../../js/PaymentPolicyContent1";
import PaymentPolicyContent2 from "../../js/PaymentPolicyContent2";
import PaymentPolicyContent3 from "../../js/PaymentPolicyContent3";

function PaymentPolicyModal({status, setStatus, type, setType, data, setData}) {
  
  const closeModal = () => {
    setStatus(false);
    setType(0);
    $("#modalContent").scrollTop(0);
  };

  const agreePolicy = () => {
    const key = "policy" + type;
    console.log(key);

    setData({
      ...data,
      [key]: "Y"
    });
    setStatus(false);
    setType(0);
    $("#modalContent").scrollTop(0);
  };

  return (
    <>
      <div className={status ? "modal bigModal active" : "modal bigModal"}>
        <div className="modalHeader">
          <div className="modalTitle">
            {
              "1" === type && "(나이스페이)전자금융거래 이용약관"
            }
            {
              "2" === type && "(나이스페이)개인정보 수집 및 이용 안내"
            }
            {
              "3" === type && "(나이스페이)개인정보 제공 및 위탁 안내"
            }
          </div>
          <button tabIndex={0} className="closeModal" title="모달 닫기" onClick={closeModal}></button>
        </div>

        <div id="modalContent" className="modalContent" style={{ overflowY: "scroll", maxHeight: "calc(100vh - 554px", padding: "0 17px" }}>
          <div className="policyContent active">
            {
              "1" === type && <PaymentPolicyContent1/>
            }
            {
              "2" === type && <PaymentPolicyContent2/>
            }
            {
              "3" === type && <PaymentPolicyContent3/>
            }
          </div>
        </div>
        <button tabIndex={0} className="buttonDone" title="동의" onClick={agreePolicy}>동의하기</button>
      </div>

      <button tabIndex={0} className={status ? "dimm active" : "dimm"} title="닫기" onClick={closeModal}></button>
    </>
  );
}

export default PaymentPolicyModal;