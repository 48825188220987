import axios from "axios";
import { TEST_ADD } from "../port/Port";
import CreateToken from "../js/CreateToken";
import Swal from "sweetalert2";
import { encrypt, decrypt } from "../js/encrypt";

export async function signupAPI(name, snsId, ssn1, ssn2, phone, rcmCode, vote, marketing) {
  
  let gender = "";

  if(0 === ssn2 % 2) gender = "F";
  else gender = "M";

  const data = {
    name: name,
    sns_id: snsId,
    birth_dt: ssn1,
    gender: gender,
    email: "",
    phone: phone,
    rate_seq: 1,
    rcm_code: rcmCode,
    vote1: vote.vote1,
    vote2: vote.vote2,
    vote3: vote.vote3,
    agree_push_marketing: marketing.promotion_app,
    agree_push_service: marketing.service_app,
    agree_sms_marketing: marketing.promotion_sms,
    agree_sms_service: marketing.service_sms,
  };
  const POST_MEMBER_URL = `${TEST_ADD}/memberInsert?agree_push_marketing=${marketing.promotion_app}&agree_push_service=${marketing.service_app}&agree_sms_marketing=${marketing.promotion_sms}&agree_sms_service=${marketing.service_sms}&birth_dt=${ssn1}&email=&gender=${gender}&name=${name}&phone=${phone}&rate_seq=${1}&sns_id=${snsId}&rcm_code=${rcmCode}&vote1=${vote.vote1}&vote2=${vote.vote2}&vote3=${vote.vote3}`;
  let resCode;

  await axios.post(POST_MEMBER_URL, data)
  .then(res => {
    resCode = res.data.resultCode;
  })
  .catch(err => console.error(err));

  return resCode;
}

export async function loginAPI(phone) {

  const GET_MEMBER_INFO_URL = `${TEST_ADD}/memberInfoPhone?phone=${phone}`;
  let resCode;

  await axios.get(GET_MEMBER_INFO_URL)
  .then(res => {
    // resCode = res.data.resultCode
    if(1 !== res.data.resultCode) {
      Swal.fire({  
        text: "계정이 존재하지 않습니다.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then(res => {
        window.location.reload(window.location.href);
      });
    }else {
      resCode = res.data.resultCode;
      sessionStorage.setItem("mem_seq", encrypt(String(res.data.result.seq)));
      localStorage.setItem("mem_seq", encrypt(String(res.data.result.seq)));
    }
  })
  .catch(err => console.error(err));

  return resCode;
}

export async function memberWithdrawAPI(seq, token) {

  const POST_MEMBER_WITHDRAW_URL = `${TEST_ADD}/memberWithdraw?seq=${seq}&token=${token}`;
  let code;

  await axios.post(POST_MEMBER_WITHDRAW_URL)
  .then(res => code = res.data.resultCode)
  .catch(err => console.error(err));

  return code;
}

export async function postMemberVerifyCodeAPI(phone) {

  const POST_MEMBER_VERIFY_CODE_URL = `${TEST_ADD}/memberSmsSend?phone=${phone}`;
  let code;

  await axios.post(POST_MEMBER_VERIFY_CODE_URL)
  .then(res => {
    if(1 === res.data.resultCode) {
      code = res.data.code;
    }
    Swal.fire({
      text: res.data.resultMsg,
      confirmButtonText: "확인",
      position: "center",
        confirmButtonColor: "#0064ff",
    })
    .then();
  })
  .catch(err => console.error(err));

  return code;
}

export async function checkMemberVerifyCodeAPI(phone, code) {

  const CHECK_MEMBER_VERIFY_CODE_URL = `${TEST_ADD}/memberSmsCheck?code=${code}&phone=${phone}`;
  let resCode;

  await axios.post(CHECK_MEMBER_VERIFY_CODE_URL)
  .then(res => resCode = res.data.resultCode)
  .catch(err => console.error(err));

  return resCode;
}

export async function checkMemberDuplicatePhoneAPI(phone) {

  const CHECK_MEMBER_DUPLICATE_PHONE_URL = `${TEST_ADD}/memberDuplicatePhoneCheck?phone=${phone}`;
  let resCode;

  await axios.get(CHECK_MEMBER_DUPLICATE_PHONE_URL)
  .then(res => resCode = res.data.resultCode)
  .catch(err => console.error(err));

  return resCode;
}

export async function snsDuplicateCheckAPI(snsId) {
  
  const GET_SNS_DUPLICATE_CHECK_URL = `${TEST_ADD}/memberDuplicateSnsCheck?sns_id=${snsId}`;
  let resCode;

  await axios.get(GET_SNS_DUPLICATE_CHECK_URL)
  .then(res => resCode = res.data.resultCode)
  .catch(err => console.error(err));

  return resCode;
}

export async function getSnsMemberInfoAPI(snsId) {

  const GET_SNS_MEMBER_INFO_URL = `${TEST_ADD}/memberInfoSnsId?sns_id=${snsId}`;
  let info = {};

  await axios.get(GET_SNS_MEMBER_INFO_URL)
  .then(res => {
    if(1 === res.data.resultCode) info = res.data.result;
    else {
      Swal.fire({  
        text: "회원 조회 실패",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
    }
  })
  .catch(err => console.error(err));

  return info;
}

export async function registAddrAPI(form, isDefault, type) {

  const data = {
    addr1: form.addr,
    addr2: form.detailAddr,
    default_yn: isDefault ? "Y" : "N",
    door_pw: encodeURIComponent(form.doorPw),
    door_pw_yn: form.doorPw ? "Y" : "N",
    elevator_yn: "Y",
    mem_seq: decrypt(sessionStorage.getItem("mem_seq")),
    type: type,
    zip_cd: form.zipCode
  };

  const POST_MEMBER_ADDRESS_URL = `${TEST_ADD}/memberAddressInsert?addr1=${data.addr1}&addr2=${data.addr2}&default_yn=${data.default_yn}&door_pw=${data.door_pw}&door_pw_yn=${data.door_pw_yn}&elevator_yn=${data.elevator_yn}&mem_seq=${data.mem_seq}&type=${data.type}&zip_cd=${data.zip_cd}&call_yn=${form.checkVehicle}&token=${CreateToken()}`;
  let resCode;

  await axios.post(POST_MEMBER_ADDRESS_URL, data)
  .then(res => resCode = res.data.resultCode)
  .catch(err => console.error(err));

  return resCode;
}

export async function updateAddrAPI(form, isDefault, type, addr_seq) {

  const data = {
    addr1: form.addr,
    addr2: form.detailAddr,
    default_yn: isDefault ? "Y" : "N",
    door_pw: encodeURIComponent(form.doorPw),
    door_pw_yn: form.doorPw ? "Y" : "N",
    elevator_yn: "Y",
    mem_seq: decrypt(sessionStorage.getItem("mem_seq")),
    type: type,
    zip_cd: form.zipCode
  };

  const POST_UPDATE_MEMBER_ADDRESS_URL = `${TEST_ADD}/memberAddressUpdate?addr1=${data.addr1}&addr2=${data.addr2}&default_yn=${data.default_yn}&door_pw=${data.door_pw}&door_pw_yn=${data.door_pw_yn}&elevator_yn=${data.elevator_yn}&mem_seq=${data.mem_seq}&seq=${addr_seq}&type=${data.type}&zip_cd=${data.zip_cd}&call_yn=${form.checkVehicle}&token=${CreateToken()}`;
  let resCode;

  await axios.post(POST_UPDATE_MEMBER_ADDRESS_URL, data)
  .then(res => resCode = res.data.resultCode)
  .catch(err => console.error(err));

  return resCode;
}

export async function changeDefaultAddrAPI(mem_seq, seq, type, token) {

  const CHANGE_DEFAULT_ADDR_URL = `${TEST_ADD}/memberAddressDefaultSet?mem_seq=${mem_seq}&seq=${seq}&type=${type}&token=${token}`;
  let resCode;

  await axios.post(CHANGE_DEFAULT_ADDR_URL)
  .then(res => resCode = res.data.resultCode)
  .catch(err => console.error(err));

  return resCode;
}

export async function getMemberInfoAPI(mem_seq) {

  const GET_MEMBER_INFO_URL = `${TEST_ADD}/memberInfo?seq=${mem_seq}`;
  let info = {};

  await axios.get(GET_MEMBER_INFO_URL)
  .then(res => {
    if(1 === res.data.resultCode) info = res.data.result;
  })
  .catch(err => console.error(err));

  return info;
}

export async function updateMemberInfoAPI(form) {

  const POST_UPDATE_MEMBER_INFO_URL = `${TEST_ADD}/memberUpdate?email=${form.email}&name=${form.name}&phone=${form.phone}&seq=${form.mem_seq}&token=${CreateToken()}`;
  let resCode;

  await axios.post(POST_UPDATE_MEMBER_INFO_URL)
  .then(res => resCode = res.data.resultCode)
  .catch(err => console.error(err));

  return resCode;
}

export async function getMemberAddressListAPI(mem_seq, type) {

  const GET_MEMBER_ADDRESS_LIST_URL = `${TEST_ADD}/memberAddressList?mem_seq=${mem_seq}&type=${type}`;
  let result = [];

  await axios.get(GET_MEMBER_ADDRESS_LIST_URL)
  .then(res => {
    if(1 === res.data.resultCode) result = res.data.result;
  })
  .catch(err => console.error(err));

  return result;
}

export async function getMemberDetailAddressAPI(addr_seq) {

  const GET_MEMBER_DETAIL_ADDRESS_INFO_URL = `${TEST_ADD}/memberAddressInfo?seq=${addr_seq}`;
  let info = {};

  await axios.get(GET_MEMBER_DETAIL_ADDRESS_INFO_URL)
  .then(res => {
    if(1 === res.data.resultCode) info = res.data.result;
  })
  .catch(err => console.error(err));

  return info;
}

export async function deleteMemberAddressAPI(mem_seq, addr_seq, type) {

  const POST_DELETE_MEMBER_ADDRESS_URL = `${TEST_ADD}/memberAddressDelete?mem_seq=${mem_seq}&seq=${addr_seq}&type=${type}&token=${CreateToken()}`;
  let resCode;

  await axios.post(POST_DELETE_MEMBER_ADDRESS_URL)
  .then(res => resCode = res.data.resultCode)
  .catch(err => console.error(err));
  
  return resCode;
}

/* ----------------------------- 회원 결제수단 관리 ----------------------------- */

export async function getMemberPaymentListAPI(mem_seq) {

  const GET_MEMBER_PAYMENT_LIST_URL = `${TEST_ADD}/paymentList?mem_seq=${mem_seq}&page_no=${1}`;
  let list = [];

  await axios.get(GET_MEMBER_PAYMENT_LIST_URL)
  .then(res => {
    if(1 === res.data.resultCode) list = res.data.result;
  })
  .catch(err => console.error(err));

  return list;
}

export async function getMemberPaymentInfoAPI(mem_seq, seq) {

  const GET_MEMBER_PAYMENT_INFO_URL = `${TEST_ADD}/paymentInfo?mem_seq=${mem_seq}&seq=${seq}`;
  let info = {};

  await axios.get(GET_MEMBER_PAYMENT_INFO_URL)
  .then(res => {
    if(1 === res.data.resultCode) info = res.data.result;
    else {
      Swal.fire({  
        text: "결제수단 정보 조회 실패",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      window.history.back();
    }
  })
  .catch(err => console.error(err));

  return info;
}

export async function postMemberPaymentItemAPI(data, token) {

  let gender = "";

  if(0 === data.ssn2 % 2) gender = "F";
  else gender = "M";
  
  const POST_MEMBER_PAYMENT_ITEM_URL = `${TEST_ADD}/paymentInsert?card_entry_name=${data.name}&card_expiration_mm=${data.expireM}&card_expiration_yy=${data.expireY}&card_num=${data.cardNum1 + data.cardNum2 + data.cardNum3 + data.cardNum4}&card_pw=${data.card_pw}&default_yn=${data.isDefault}&mem_seq=${data.mem_seq}&birth_dt=${data.ssn1}&gender=${gender}&token=${token}`;
  let resArr = [0, ""];

  await axios.post(POST_MEMBER_PAYMENT_ITEM_URL, data)
  .then(res => {
    resArr[0] = res.data.resultCode;
    resArr[1] = res.data.resultMsg;
  })
  .catch(err => console.error(err));

  return resArr;
}

export async function deleteMemberPaymentItemAPI(mem_seq, seq, token) {

  const DELETE_MEMBER_PAYMENT_ITEM_URL = `${TEST_ADD}/paymentDelete?mem_seq=${mem_seq}&seq=${seq}&token=${token}`;
  let resCode;

  await axios.post(DELETE_MEMBER_PAYMENT_ITEM_URL)
  .then(res => resCode = res.data.resultCode)
  .catch(err => console.error(err));

  return resCode;
}

export async function updateMemberPaymentInfoAPI(data, token) {

  const UPDATE_MEMBER_PAYMENT_INFO_URL = `${TEST_ADD}/paymentUpdate?card_entry_name=${data.name}&card_expiration_mm=${data.expireM}&card_expiration_yy=${data.expireY}&card_num=${data.cardNum}&default_yn=${data.isDefault}&mem_seq=${data.mem_seq}&seq=${data.seq}&token=${token}`;
  let resCode;

  await axios.post(UPDATE_MEMBER_PAYMENT_INFO_URL)
  .then(res => resCode = res.data.resultCode)
  .catch(err => console.error(err));

  return resCode;
}

export async function changeDefaultPaymentAPI(mem_seq, seq, token) {

  const CHANGE_DEFAULT_PAYMENT_URL = `${TEST_ADD}/paymentDefaultSet?mem_seq=${mem_seq}&seq=${seq}&token=${token}`;
  let resCode;

  await axios.post(CHANGE_DEFAULT_PAYMENT_URL)
  .then(res => resCode = res.data.resultCode)
  .catch(err => console.error(err));

  return resCode;
}

export async function getMemberPayHistoryAPI(mem_seq) {

  const GET_MEMBER_PAY_HISTORY_URL = `${TEST_ADD}/paymentHistoryMyList?mem_seq=${mem_seq}`;
  let list = [];

  await axios.get(GET_MEMBER_PAY_HISTORY_URL)
  .then(res => {
    if(1 === res.data.resultCode) list = res.data.result;
  })
  .catch(err => console.error(err));

  return list;
}

export async function postCancelPayHistoryAPI(data, token) {

  // const POST_CANCEL_PAY_HISTORY_URL = `${TEST_ADD}/paymentCancel?history_seq=${}&mem_seq=${}&payment_seq=${}&reason=고객요청&token=${token}`;
  
}

/* ----------------------------- 회원 구매 서비스 관리 ----------------------------- */

export async function getMemberPurchasedServiceListAPI(mem_seq) {

  const GET_MEMBER_PURCHASED_SERVICE_LIST_URL = `${TEST_ADD}/memberPurchasedServiceList?mem_seq=${mem_seq}&page_no=${1}`;
  let list = [];

  await axios.get(GET_MEMBER_PURCHASED_SERVICE_LIST_URL)
  .then(res => {
    if(1 === res.data.resultCode) list = res.data.result;
    else {
      Swal.fire({  
        text: "회원 서비스 구매 목록 조회 실패",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
    }
  })
  .catch(err => console.error(err));

  return list;
}

export async function postTerminateServiceAPI(form, token) {

  const POST_TERMINATE_SERVICE_URL = `${TEST_ADD}/memberPurchasedServiceRefundRequest?mem_seq=${form.mem_seq}&refund_cabinet_delivery_date=${form.terminateDelDate}&refund_reason=${form.inputReason}&service_seq=${form.service_seq}&refund_satisfaction=${form.satisfaction}&token=${token}`;
  let resCode = 0;

  await axios.post(POST_TERMINATE_SERVICE_URL)
  .then(res => {
    if(1 === res.data.resultCode) {
      Swal.fire({
        text: "서비스 해지 신청 성공",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      resCode = res.data.resultCode;
    } else {
      Swal.fire({
        text: res.data.resultMsg,
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
    }
  })
  .catch(err => console.error(err));

  return resCode;
}

export async function directCancelServiceAPI(mem_seq, service_seq, token) {

  const POST_DIRECT_CANCEL_SERVICE_API = `${TEST_ADD}/memberPurchasedServiceCancelRequest`;
  
  const form = new FormData();
  form.append("mem_seq", mem_seq);
  form.append("service_seq", service_seq);
  form.append("token", token);
  let resCode;

  await axios.post(POST_DIRECT_CANCEL_SERVICE_API, form)
  .then(res => {
    if(1 === res.data.resultCode) {
      Swal.fire({
        text: "서비스 결제 해지 성공",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then(res => {
        if(res.isConfirmed) window.location.replace(window.location.href);
      });
      resCode = res.data.resultCode;
    } else {
      Swal.fire({
        text: "서비스 결제 해지 실패",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      resCode = res.data.resultCode;
    }
  })
  .catch(err => console.error(err));

  return resCode;
}

export async function paymentDuplicateCheckAPI(card_num, mem_seq, token) {

  const PAYMENT_DUPLICATE_CHECK_URL = `${TEST_ADD}/paymentCardDupCheck?card_num=${card_num}&mem_seq=${mem_seq}&token=${token}`;
  let resCode;

  await axios.get(PAYMENT_DUPLICATE_CHECK_URL)
  .then(res => resCode = res.data.resultCode)
  .catch(err => console.error(err));

  return resCode;
}

// ----------------------- 서비스 변경 api -------------------------
export async function postChangeMemberPurchasedServiceAPI(data, token) {

  const POST_MEMBER_PURCHASED_SERVICE_URL = `${TEST_ADD}/memberPurchasedServiceInsert?cabinet_seq=${data.cabinet_seq}&mem_seq=${data.mem_seq}&payment_goods_seq=${data.payment_goods_seq}&service_date_ed=${data.service_date_ed}&service_date_st=${data.service_date_st}&d_amt=0&token=${token}`;
  let resCode;

  await axios.post(POST_MEMBER_PURCHASED_SERVICE_URL)
  .then(res => resCode = res.data.resultCode)
  .catch(err => console.error(err));

  return resCode;
}

export async function getMemberLastPurchasedServiceSeq(mem_seq) {

  const GET_MEMBER_LAST_PURCHASED_SERVICE_SEQ_URL = `${TEST_ADD}/memberPurchasedServiceLast?mem_seq=${mem_seq}`;
  let seq = 0;

  await axios.get(GET_MEMBER_LAST_PURCHASED_SERVICE_SEQ_URL)
  .then(res => {
    if(1 === res.data.resultCode) seq = res.data.result;
  })
  .catch(err => console.error(err));

  return seq;
}
// ----------------------------------------------------------

export async function getMemberStorageListAPI(mem_seq) {

  const GET_MEMBER_STORAGE_LIST_URL = `${TEST_ADD}/memberStorageList?mem_seq=${mem_seq}&page_no=${1}`;
  let list = [];

  await axios.get(GET_MEMBER_STORAGE_LIST_URL)
  .then(res => {
    if(1 === res.data.resultCode) list = res.data.result;
  })
  .catch(err => console.error(err));

  return list;
}

export async function postMemberPurchasedServiceInfoAPI(cardInfo, serviceInfo, token) {

  let gender = "";

  if(0 === cardInfo.ssn2 % 2) gender = "F";
  else gender = "M";

  const POST_MEMBER_PURCHASED_SERVICE_INFO_URL = `${TEST_ADD}/paymentPay?goods_seq=${serviceInfo.payment_goods_seq}&m_amt=${serviceInfo.m_amt}&d_amt=${serviceInfo.d_amt}&mem_seq=${serviceInfo.mem_seq}&payment_seq=${cardInfo.payment_seq}&quota=${cardInfo.installmentM}&service_month=${serviceInfo.service_month}&birth_dt=${cardInfo.ssn1}&gender=${gender}&token=${token}`;
  let resCode;

  await axios.post(POST_MEMBER_PURCHASED_SERVICE_INFO_URL)
  .then(res => resCode = res.data.resultCode)
  .catch(err => console.error(err));

  return resCode;
}

export async function postMemberPurchasedServiceInfoAndCardInfoAPI(cardInfo, serviceInfo, token) {

  let gender = "";

  if(0 === cardInfo.ssn2 % 2) gender = "F";
  else gender = "M";

  const POST_MEMBER_PURCHASED_SERVICE_INFO_AND_CARD_INFO_URL = `${TEST_ADD}/paymentInsertPay?card_entry_name=${cardInfo.name}&card_expiration_mm=${cardInfo.expireM}&card_expiration_yy=${cardInfo.expireY}&card_num=${cardInfo.cardNum1 + cardInfo.cardNum2 + cardInfo.cardNum3 + cardInfo.cardNum4}&card_pw=${cardInfo.card_pw}&goods_seq=${serviceInfo.payment_goods_seq}&m_amt=${serviceInfo.m_amt}&d_amt=${serviceInfo.d_amt}&mem_seq=${serviceInfo.mem_seq}&quota=${cardInfo.installmentM}&service_month=${serviceInfo.service_month}&birth_dt=${cardInfo.ssn1}&gender=${gender}&token=${token}`;
  let resCode;

  await axios.post(POST_MEMBER_PURCHASED_SERVICE_INFO_AND_CARD_INFO_URL)
  .then(res => resCode = res.data.resultCode)
  .catch(err => console.error(err));

  return resCode;
}

export async function getMemberStorageFreeCountAPI(mem_seq, service_seq) {

  const GET_MEMBER_FREE_COUNT_URL = `${TEST_ADD}/memberStorageFreeCount?mem_seq=${mem_seq}&service_seq=${service_seq}`;
  let cnt;

  await axios.get(GET_MEMBER_FREE_COUNT_URL)
  .then(res => {
    if(1 === res.data.resultCode) cnt = res.data.resultCount;
  })
  .catch(err => console.error(err));

  return cnt;
}

export async function updateMemberStorageApplyDateAPI(apply_date, mem_seq, seq, token) {

  const UPDATE_MEMBER_STORAGE_APPLY_DATE_URL = `${TEST_ADD}/memberStorageUpdate?apply_date=${apply_date}&mem_seq=${mem_seq}&seq=${seq}&token=${token}`;

  await axios.post(UPDATE_MEMBER_STORAGE_APPLY_DATE_URL)
  .then(res => {
    if(1 === res.data.resultCode) {
      Swal.fire({  
        text: "날짜 변경 완료",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
    } else {
      Swal.fire({  
        text: "날짜 변경 실패",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
    }
  })
  .catch(err => console.error(err));
}

export async function cancelMemberStorageApplyDateAPI(mem_seq, seq, token) {

  const POST_CANCEL_MEMBER_STORAGE_APPLY_DATE_URL = `${TEST_ADD}/memberStorageCancel?mem_seq=${mem_seq}&seq=${seq}&token=${token}`;

  await axios.post(POST_CANCEL_MEMBER_STORAGE_APPLY_DATE_URL)
  .then(res => {
    if(1 === res.data.resultCode) {
      Swal.fire({
        text: "취소 성공",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then(res => {
        if(res.isConfirmed) location.replace(location.href);
      });
    }
  })
  .catch(err => console.error(err));
}

/* ----------------------------- 회원 포인트 관리 ----------------------------- */
export async function getMemberFriendListAPI(mem_seq) {

  const GET_MEMBER_FRIEND_LIST_URL = `${TEST_ADD}/memberFriendList?seq=${mem_seq}`;
  let obj = {};

  await axios.get(GET_MEMBER_FRIEND_LIST_URL)
  .then(res => {
    if(1 === res.data.resultCode) obj = res.data;
  })
  .catch(err => console.error(err));

  return obj;
}

/* ----------------------------- 회원 캐비닛 관리 ----------------------------- */
export async function getMemberCabinetListAPI(mem_seq) {

  const GET_MEMBER_CABINET_LIST_URL = `${TEST_ADD}/memberCabinetList?mem_seq=${mem_seq}`;
  let list = [];

  await axios.get(GET_MEMBER_CABINET_LIST_URL)
  .then(res => {
    if(1 === res.data.resultCode) list = res.data.result;
  })
  .catch(err => console.error(err));

  return list;
}