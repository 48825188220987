import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ScrollToTop from "../../js/ScrollToTop";
import { TEST_IMAGE } from "../../port/Port";

function NoticeDetailComp() {

  const { state } = useLocation();
  const navigate = useNavigate();

  const moveBack = () => {
    navigate("/notice", {replace: true});
  };

  const dateFormat = date => {
    const year = date.substr(0, 4);
    const month = date.substr(5, 2);
    const day = date.substr(8, 2);
    
    return `${year}년 ${month}월 ${day}일`;
  };

  useEffect(() => {
    ScrollToTop();
  }, []);

  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>공지사항</h1>
        </div>
      </header>

      <div className="contents contents2">
        <div className="centerWrap">
          <div className="noticeWrap">
            <div className="noticeTitle">{state.title}</div>
            <div className="noticeDate">{dateFormat(state.regdate)}</div>
          </div>

          <img src={`${TEST_IMAGE}/notice/` + state.filename_svr} alt=""/>
          <div className="noticeDetailContent" dangerouslySetInnerHTML={{__html: state.content}}></div>
        </div>
      </div>
    </>
  );
}

export default NoticeDetailComp;