import { createActions, handleActions } from "redux-actions";

const initialState = {
  marketing: {},
};

export const POST_MEMBER_ACCEPTED_MARKETING = "signup/member/POST_MEMBER_ACCEPTED_MARKETING";

const actions = createActions({
  [POST_MEMBER_ACCEPTED_MARKETING]: () => {},
});

const marketingReducer = handleActions({
  [POST_MEMBER_ACCEPTED_MARKETING]: (state, { payload }) => {
    state.marketing = {...payload};

    let newState = {...state};

    return newState;
  },
}, initialState);

export default marketingReducer;