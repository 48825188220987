import axios from "axios";
import { TEST_ADD } from "../port/Port";
import Swal from "sweetalert2";

export async function getDefaultAddressAPI(mem_seq, type) {
  
  const POST_DEFAULT_ADD_URL = `${TEST_ADD}/memberAddressDefaultCount?mem_seq=${mem_seq}&type=${type}`;
  let cnt;

  await axios.post(POST_DEFAULT_ADD_URL)
  .then(res => cnt = res.data.resultCount)
  .catch(err => console.error(err));

  return cnt;
}

export async function getCollectionCateListAPI() {

  const GET_COLL_CATE_LIST_URL = `${TEST_ADD}/collectionCategoryAllList`;
  let list = [];

  await axios.get(GET_COLL_CATE_LIST_URL)
  .then(res => {
    if(1 === res.data.resultCode) list = res.data.result;
    else {
      Swal.fire({
        text: "수거 카테고리 목록 조회 실패",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
    }
  })
  .catch(err => console.error(err));

  return list;
}

export async function getHolidayListAPI() {

  const GET_HOLIDAY_LIST_URL = `${TEST_ADD}/storageRestdtRecentList`;
  let list = [];

  await axios.get(GET_HOLIDAY_LIST_URL)
  .then(res => {
    if(1 === res.data.resultCode) list = res.data.result;
    else {
      Swal.fire({
        
        text: "휴무일 조회 실패",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
    }
  })
  .catch(err => console.error(err));

  return list;
}

export async function getSelectableDateListAPI(type) {

  const GET_SELECTABLE_DATE_LIST_URL = `${TEST_ADD}/storageRequestDays?day=7&type=${type}`;
  let list = [];

  await axios.get(GET_SELECTABLE_DATE_LIST_URL)
  .then(res => {
    if(1 === res.data.resultCode) list = res.data.result;
  })
  .catch(err => console.error(err));

  return list;
}

export async function getSelectableTerminateDateListAPI(type) {

  const GET_SELECTABLE_TERMINATE_DATE_LIST_URL = `${TEST_ADD}/storageRequestDays?day=30&type=${type}`;
  let list = [];

  await axios.get(GET_SELECTABLE_TERMINATE_DATE_LIST_URL)
  .then(res => {
    if(1 === res.data.resultCode) list = res.data.result;
  })
  .catch(err => console.error(err));

  return list;
}

export async function postCollApplyAPI(data) {

  const POST_COLL_APPLY_URL = `${TEST_ADD}/memberStorageInsert?apply_date=${data.apply_date}&collection_type=${data.collection_type}&face_yn=${data.face_yn}&free_yn=${data.free_yn}&mem_seq=${data.mem_seq}&goods_seq=${data.goods_seq}&service_seq=${data.service_seq}&status=${data.status}&token=${data.token}&type=${data.type}`;
  let resCode;

  await axios.post(POST_COLL_APPLY_URL, data)
  .then(res => resCode = res.data.resultCode)
  .catch(err => console.error(err));

  return resCode;
}

export async function postDelApplyAPI(data) {

  const POST_DEL_APPLY_URL = `${TEST_ADD}/memberStorageInsert?apply_date=${data.apply_date}&face_yn=${data.face_yn}&free_yn=${data.free_yn}&mem_seq=${data.mem_seq}&goods_seq=${data.goods_seq}&service_seq=${data.service_seq}&status=${data.status}&token=${data.token}&type=${data.type}`;
  let resCode;

  await axios.post(POST_DEL_APPLY_URL, data)
  .then(res => resCode = res.data.resultCode)
  .catch(err => console.error(err));

  return resCode;
}