function DeliveryHeader({cabinetInfo}) {

  return (
    <header className="homeHeader">
      <div className="centerWrap">
        <a href="/" className="homeLogo" title="홈">
          <img src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} alt="STORR"/>
        </a>

        {/* {
          cabinetInfo ? 
          <div className="floatRight">
            <div className="passwordLabel">STORR 캐비닛 비밀번호</div>
            <div className="passwordData">
              <div className="data">{cabinetInfo.cabinet_pass.substring(0, 1)}</div>
              <div className="data">{cabinetInfo.cabinet_pass.substring(1, 2)}</div>
              <div className="data">{cabinetInfo.cabinet_pass.substring(2)}</div>
            </div>
          </div>
          :
          <></>
        } */}
      </div>
    </header>
  );
}

export default DeliveryHeader;