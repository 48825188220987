import $ from "jquery";
import { useState } from 'react';
import SignupPolicyContent1 from '../../js/SignupPolicyContent1';
import SignupPolicyContent2 from '../../js/SignupPolicyContent2';
import { useDispatch } from 'react-redux';
import { POST_MEMBER_ACCEPTED_MARKETING } from '../../modules/member/MarketingModule';
import Swal from 'sweetalert2';

function PolicyModal({status, setStatus, setAccept, setSendCnt}) {

  const dispatch = useDispatch();
  const [data, setData] = useState({
    policy1: false,
    policy2: false,
    policy3: false,
  });
  const [content, setContent] = useState({
    c_policy1: false,
    c_policy2: false,
    c_policy3: false,
  });
  const [choicePolicy, setChoicePolicy] = useState({
    service_sms: "N",
    service_app: "N",
    promotion_sms: "N",
    promotion_app: "N",
  });
  
  const checkAll = () => {
    const list = document.querySelectorAll("input[type='checkbox']");

    list.forEach(item => item.checked = true);

    setData({
      policy1: true,
      policy2: true,
      policy3: true,
    });

    setChoicePolicy({
      service_sms: "Y",
      service_app: "Y",
      promotion_sms: "Y",
      promotion_app: "Y",
    });
  };

  const onPolicyCheckHandler = e => {
    setData({
      ...data,
      [e.target.name]: e.target.checked
    });

    const marketBox = document.querySelector("input[name='policy3']");
    const choices = document.querySelectorAll(".choiceInput");

    if(marketBox.checked) {
      choices.forEach(item => item.checked = true);

      setChoicePolicy({
        service_sms: "Y",
        service_app: "Y",
        promotion_sms: "Y",
        promotion_app: "Y",
      });
    } else {
      choices.forEach(item => item.checked = false);

      setChoicePolicy({
        service_sms: "N",
        service_app: "N",
        promotion_sms: "N",
        promotion_app: "N",
      });
    }
  };

  const onClickHandler = e => {
    setContent({
      ...content,
      [e.target.name]: !content[`${e.target.name}`]
    });
  };

  const onServiceChangeHandler = e => {
    setChoicePolicy({
      ...choicePolicy,
      [e.target.name]: e.target.checked ? "Y" : "N"
    });

    const checked = document.querySelectorAll(".choiceInput:checked");
    const marketBox = document.querySelector("input[name='policy3']");

    if(!checked.length) {
      marketBox.checked = false;

      setData({
        ...data,
        policy3: false
      });
    } else {
      marketBox.checked = true;

      setData({
        ...data,
        policy3: true
      });
    }
  };

  const closeModal = () => {
    setStatus(false);

    const list = document.querySelectorAll("input[type='checkbox']");

    list.forEach(item => item.checked = false);

    setData({
      policy1: false,
      policy2: false,
      policy3: false,
    });

    setContent({
      c_policy1: false,
      c_policy2: false,
      c_policy3: false,
    });

    setChoicePolicy({
      service_sms: "N",
      service_app: "N",
      promotion_sms: "N",
      promotion_app: "N",
    });
  };

  const acceptPolicy = () => {
    if(data.policy1 && data.policy2) {
      dispatch({ type: POST_MEMBER_ACCEPTED_MARKETING, payload: choicePolicy });

      setAccept(true);
      setSendCnt(1);
      closeModal();
    } else {
      // alert("필수 약관들을 확인해주세요.");
      Swal.fire({
        
        text: "필수 약관들을 확인해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }
  };

  return (
    <>
      <div className={ status ? "modal bigModal active" : "modal bigModal" }>
        <div className="modalHeader">
          <div className="modalTitle">약관 동의</div>
          <button tabIndex={0} className="closeModal" title="모달 닫기" onClick={closeModal}></button>
        </div>

        <div className="modalContent policyAgreeModalContent" style={{ overflowY: "scroll", maxHeight: "calc(100vh - 554px" }}>
          <button tabIndex={0} className="buttonAgreeAll" title="" onClick={checkAll}>모두 동의합니다.</button>

          <div className={content.c_policy1 ? "row active" : "row"}>
            <div className="policyTitle">
              <label className="brisakInput pBipt">
                <input type="checkbox" name="policy1" onChange={onPolicyCheckHandler} checked={data.policy1}/>
                <div className="wrap">
                  <div className="icon"></div>
                </div>
              </label>
              <span className="t1">[필수]</span>
              <span className="t2">서비스 가입 및 이용 약관 동의</span>
              <button tabIndex={0} className="detailOpen" name="c_policy1" title="상세" onClick={onClickHandler}></button>
            </div>
          </div>

          <div className="policyChildList">
            <div className="policyContent active">
              <SignupPolicyContent1/>
            </div>
          </div>

          <div className="lineP1"></div>

          <div className={content.c_policy2 ? "row active" : "row"}>
            <div className="policyTitle">
              <label className="brisakInput pBipt">
                <input type="checkbox" name="policy2" onChange={onPolicyCheckHandler} checked={data.policy2}/>
                <div className="wrap">
                  <div className="icon"></div>
                </div>
              </label>
              <span className="t1">[필수]</span>
              <span className="t2">개인정보 수집 및 처리 방침 동의</span>
              <button tabIndex="0" type="button" className="detailOpen" name="c_policy2" title="상세" onClick={onClickHandler}></button>
            </div>
          </div>

          <div className="policyChildList">
            <div className="policyContent active">
              <SignupPolicyContent2/>
            </div>
          </div>

          <div className="lineP1"></div>

          <div className={content.c_policy3 ? "row active" : "row"}>
            <div className="policyTitle">
              <label className="brisakInput pBipt">
                <input type="checkbox" name="policy3" onChange={onPolicyCheckHandler} checked={data.policy3}/>
                <div className="wrap">
                  <div className="icon"></div>
                </div>
              </label>
              <span className="t1">[선택]</span>
              <span className="t2">마케팅 수신 및 제3자 제공 동의</span>
              <button tabIndex="0" type="button" className="detailOpen" name="c_policy3" title="상세" onClick={onClickHandler}></button>
            </div>
          </div>

          <div className="policyChildList">
            <div className="row">
              <div className="policyTitle">
                <span className="t1">[선택]</span>
                <span className="t2">서비스 신청∙수거∙배송 상황 알림</span>
              </div>
            </div>

            <div className="dataWrap">
              <label className="brisakInput">
                <input className="choiceInput" type="checkbox" name="service_sms" onChange={onServiceChangeHandler}/>
                <div className="wrap">
                  <div className="icon"></div>
                  <div className="txt">SMS</div>
                </div>
              </label>

              <label className="brisakInput">
                <input className="choiceInput" type="checkbox" name="service_app" onChange={onServiceChangeHandler}/>
                <div className="wrap">
                  <div className="icon"></div>
                  <div className="txt">앱푸쉬</div>
                </div>
              </label>
            </div>

            <div className="row policyM2">
              <div className="policyTitle">
                <span className="t1">[선택]</span>
                <span className="t2">할인 쿠폰∙프로모션 홍보 알림</span>
              </div>
            </div>

            <div className="dataWrap">
              <label className="brisakInput">
                <input className="choiceInput" type="checkbox" name="promotion_sms" onChange={onServiceChangeHandler}/>
                <div className="wrap">
                  <div className="icon"></div>
                  <div className="txt">SMS</div>
                </div>
              </label>

              <label className="brisakInput">
                <input className="choiceInput" type="checkbox" name="promotion_app" onChange={onServiceChangeHandler}/>
                <div className="wrap">
                  <div className="icon"></div>
                  <div className="txt">앱푸쉬</div>
                </div>
              </label>
            </div>
          </div>
				</div>

        <div className="modalButtons">
          <button tabIndex={0} className="buttonDone" title="" onClick={acceptPolicy}>인증하기</button>
        </div>
      </div>

      <button tabIndex={0} className={ status ? "dimm active" : "dimm" } title="닫기" onClick={closeModal}></button>
    </>
  );
}

export default PolicyModal;