function PaymentPolicyContent3() {

  return (
    <>
      개인정보 처리방침<br />
      'NICE페이먼츠 주식회사’(이하 '회사')는 이용자의 개인정보를 중요시하며, “정보통신망 이용촉진 및 정보보호 등에 관한 법률”, “개인정보보호법” 및 “신용정보의 이용 및 보호에 관한 법률”을 준수하고 있습니다. 회사는 개인정보처리방침을 통하여 이용자께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.<br />
      회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.<br />
      회사의 개인정보처리방침은 다음과 같은 내용을 담고 있습니다.<br />
      <br />
      1. 개인정보의 수집 및 이용목적<br />
      2. 수집하는 개인정보 항목 및 수집방법<br />
      3. 개인정보 제공<br />
      4. 개인정보의 보유 및 이용기간<br />
      5. 개인정보의 파기절차 및 파기방법<br />
      6. 개인정보의 처리위탁<br />
      7. 이용자 및 법정대리인의 권리와 그 행사방법<br />
      8. 인터넷 접속정보파일 등 개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항<br />
      9. 개인정보의 안전성 확보조치<br />
      10. 개인정보 보호책임자의 성명 또는 개인정보보호 업무 및 관련 고충사항을 처리하는 부서의 명칭과 그 전화번호 등 연락처<br />
      11. 기타 개인정보처리방침의 변경에 관한 사항<br />
      <br />
      1. 개인정보의 수집 및 이용목적<br />
      회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.<br />
      <br />
      - 서비스 제공 계약의 성립, 유지, 종료를 위한 본인 식별 및 실명확인, 각종 회원관리, 계약서 발송 등<br />
      - 서비스 제공 과정 중 본인 식별, 인증, 실명확인 및 각종 안내/고지<br />
      - 부정 이용방지 및 비인가 사용방지<br />
      - 서비스 제공 및 관련 업무처리에 필요한 동의 또는 철회 등 의사확인<br />
      - 이용 빈도 파악 및 인구통계학적 특성에 따른 서비스 제공 및 CRM<br />
      - 서비스 제공을 위한 각 결제수단 별 상점 사업자 정보 등록<br />
      - 회사가 제공하는 소비자보호법 메일 발송<br />
      - 기타 회사가 제공하는 이벤트 등 광고성 정보 전달(결제알림메일 내), 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 실제 마케팅 활동<br />
      - 서비스 제공(거래승인 등) 및 관련 업무처리(민원, 개인정보 관리상태 점검 등)를 위해 이용자와 해당<br />
        결제수단에 관한 계약을 체결한 이용자의 해당 결제수단 발행자 (ex : 신용카드의 경우 해당 신용카드사)에게 이용자의 결제정보, 개인정보 보관내역 등 전송<br />
      - 특정 금융거래정보의 보고 및 이용 등에 관한 법률 및 동 법률 시행령에 따른 법령 상 의무이행(고객확인, 특정금융거래보고 등), 신원확인에 관한 사항<br />
        (결제수단 발행자에 대한 상세 내용 아래 참조)<br />
      <br />
      ※ 결제수단별 발행자<br />
      가. 신용카드: 국민/비씨/롯데/삼성/NH농협/현대/신한/하나/우리 등 신용카드사<br />
      나. 계좌이체<br />
            은행 : 경남/광주/국민/기업/농협/대구/부산/산업/새마을금고/수협/신한/신협/우리/우체국/전북/제주/하나/씨티/SC제일/산림조합중앙회/케이뱅크/카카오뱅크 등<br />
            증권 : 유안타/미래에셋대우/삼성/신한/한투/한화 등, 금융결제원, 토스페이먼츠㈜<br />
      다. 가상계좌: 은행(국민/농협/우리/신한/하나/기업/우체국/부산/대구 등), (주)헥토파이낸셜, 쿠콘㈜<br />
      라. 휴대폰 : ㈜SKT/㈜KT/㈜LGU+/㈜KG모빌리언스/㈜다날/갤럭시아머니트리㈜/MVNO/페이레터㈜<br />
      마. 현금영수증 발행: 국세청<br />
      바. 본인확인 인증 : NICE평가정보㈜/쿠콘㈜/(주)헥토파이낸셜/㈜KG모빌리언스/㈜인비즈넷<br />
      사. 간편결제 : 11번가㈜/㈜엘지씨엔에스/㈜카카오페이/㈜에스에스지닷컴/엔에이치엔㈜/엔에이치엔한국사이버결제㈜/엔에이치엔페이코㈜/삼성전자㈜/롯데멤버스㈜/<br />
            네이버파이낸셜㈜/㈜비바리퍼블리카/㈜티머니<br />
      아. 상품권 : ㈜한국문화진흥<br />
      <br />
      2. 수집하는 개인정보 항목 및 수집방법<br />
      1) 개인정보 수집항목<br />
      가. 필수항목<br />
      - 이용자의 성명(가맹점의 경우, 대표자명 및 담당자명, 생년월일, 실명번호(주민등록번호,운전면허번호), 대표자가 외국인일 경우 영문명/국적/외국인등록번호, 휴대폰번호(가맹점의 경우 대표자 휴대폰번호 및 담당자 휴대폰번호), 전화번호, 이메일주소(가맹점의 경우 대표자 이메일주소 및 담당자 이메일주소), 상호명, 사업자번호(법인번호), 업종 및 업태, 쇼핑몰URL, 사업장 주소(개인/개인사업자의 경우 주민등록증 상 주소지, 외국인일 경우 실제 거소), 대표번호, 팩스번호, 아이디, 비밀번호, 거래대금지급정보(결제은행, 계좌번호, 계좌명) 및 상품 또는 용역 거래정보<br />
      - 결제정보(”이용자”가 상점의 상품 및 서비스를 구매하기 위하여 “회사”가 제공하는 ‘서비스’를 통해 제시한 각 결제수단 별 제반 정보를 의미하며 신용카드 번호, 신용카드 유효기간, 성명, 계좌번호, 휴대폰번호, 유선전화번호 등)<br />
      - 상기 명시된 개인정보 수집항목 이외의 서비스 이용과정이나 서비스 처리과정에서 추가 정보(접속 IP/MAC Address, 쿠키, e-mail, 서비스 접속 일시, 서비스 이용기록, 불량 혹은 비정상 이용기록, 결제기록)들이 자동 혹은 수동으로 생성되어 수집 될 수 있습니다.<br />
      나. 선택항목<br />
      - 필수항목 이외에 계약서류에 기재된 정보 또는 고객이 제공한 정보 또는 정보주체가 서비스 이용을 위하여 신청한 정보<br />
      - 주소, 팩스번호 등<br />
      - PAYU 간편결제 안면인증 서비스를 신청한 경우 안면정보, 본인인증내역<br />
      2) 개인정보 수집방법<br />
      - 홈페이지(NICEPAY 신규 서비스 신청 게시판), NICEPAY For Startups 전자계약 체결, 서면양식, 팩스, E-Mail, 회사가 제공하는 전자금융서비스 결제창 및 업무제휴 계약을 체결한 제휴사로부터 이용자가 직접 제시한 정보를 수집<br />
      <br />
      3. 개인정보 제공<br />
      회사는 이용자의 개인정보를 [개인정보의 수집 및 이용목적]에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.<br />
      <br />
      - 이용자들이 사전에 동의한 경우(이용자가 사전에 동의한 경우란, 서비스 이용 등을 위하여 이용자가 자발적으로 자신의 개인정보를 제3자에게 제공하는 것에 동의하는 것을 의미합니다), 이러한 경우에도, 회사는 이용자에게 (1) 개인정보를 제공받는 자, (2) 그의 이용목적, (3) 제공되는 개인정보의 항목, (4)개인정보의 보유 및 이용기간을 사전에 고지하고 이에 대해 명시적/개별적으로 동의를 얻습니다. 이와 같은 모든 과정에 있어서 회사는 이용자의 의사에 반하여 추가적인 정보를 수집하거나, 동의의 범위를 벗어난 목적으로 제3자와 공유하지 않습니다.<br />
      - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br />
      - 개인 맞춤형 광고 서비스를 제공하기 위해 특정 개인을 식별할 수 없는 형태로 제휴사에 제공하는 경우<br />
      회사의 서비스 이행을 위하여 개인정보를 제3자에게 제공하고 있는 경우는 다음과 같습니다.<br />
      <br />
      <table>
      <thead>
        <tr>
          <td>제공받는자</td>
          <td>이용목적</td>
          <td>개인정보항목</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>신용카드사 : 국민/비씨/롯데/삼성/NH농협/현대/신한/하나/우리<br />은행 : 경남/광주/국민/기업/농협/대구/부산/산업/새마을금고/수협/신한/신협/우리/우체국/전북/제주/하나/씨티/SC제일/산림조합중앙회/케이뱅크/카카오뱅크<br />VAN사 : NICE정보통신㈜/KIS정보통신㈜/㈜제이티넷/한국신용카드결제㈜</td>
          <td>신용카드 결제</td>
          <td>결제정보</td>
        </tr>
        <tr>
          <td>금융결제원/토스페이먼츠㈜<br />은행 : 경남/광주/국민/기업/농협/대구/부산/산업/새마을금고/수협/신한/신협/우리/우체국/전북/제주/하나/씨티/SC제일<br />증권 : 유안타/미래에셋대우/삼성/한투/NH/하이투자/현대/SK/대신/한화/하나금융/신한금융/동부/유진투자/메리츠/신영</td>
          <td>계좌이체 결제</td>
          <td>결제정보</td>
        </tr>
        <tr>
          <td>은행 : 국민/농협/우리/신한/하나/기업/우체국/부산/대구<br />쿠콘(주)<br />(주)헥토파이낸셜</td>
          <td>가상계좌 결제</td>
          <td>결제정보</td>
        </tr>
        <tr>
          <td>㈜SKT/㈜KT/㈜LGU+/㈜KG모빌리언스/㈜다날/갤럭시아머니트리㈜/페이레터㈜/MVNO</td>
          <td>휴대폰 결제</td>
          <td>결제정보</td>
        </tr>
        <tr>
          <td>국세청</td>
          <td>현금영수증 발행</td>
          <td>결제정보</td>
        </tr>
        <tr>
          <td>NICE평가정보㈜/쿠콘㈜/(주)헥토파이낸셜/㈜KG모빌리언스/㈜인비즈넷</td>
          <td>본인확인 인증</td>
          <td>결제정보</td>
        </tr>
        <tr>
          <td>11번가㈜/㈜엘지씨엔에스/㈜카카오페이/㈜에스에스지닷컴/엔에이치엔㈜/엔에이치엔한국사이버결제㈜/엔에이치엔페이코㈜/삼성전자㈜/롯데멤버스㈜/네이버파이낸셜㈜/㈜비바리퍼블리카/㈜티머니</td>
          <td>간편 결제</td>
          <td>결제정보</td>
        </tr>
        <tr>
          <td>㈜한국문화진흥</td>
          <td>상품권 결제</td>
          <td>결제정보</td>
        </tr>
        <tr>
          <td>NICE신용정보㈜/㈜메타넷엠씨씨</td>
          <td>고객상담</td>
          <td>고객지원 및 고충처리, 민원접수 결제건의 정보</td>
        </tr>
        <tr>
          <td>㈜인비즈넷</td>
          <td>출금동의</td>
          <td>결제계좌정보</td>
        </tr>
        <tr>
          <td>금융정보분석원</td>
          <td>고객확인</td>
          <td>실명번호, 거래목적, 자금원천 등</td>
        </tr>
        <tr>
          <td>신용카드사 : 신한/삼성/현대/비씨/국민/하나/농협/롯데</td>
          <td>PAYU 카드등록(*)</td>
          <td>카드번호,유효기간,비밀번호 앞2자리,CVC,CI,생년월일,성별,휴대폰번호</td>
        </tr>
        <tr>
          <td>NICE정보통신㈜</td>
          <td>생체정보의 등록/인증 및 분산저장(**)</td>
          <td>안면정보(원본정보 및 특징정보)</td>
        </tr>
        <tr>
          <td>전자계약 : 한국정보인증㈜<br />OCR/진위확인 : 네이버클라우드㈜/미니소프트㈜/㈜티소프트</td>
          <td>NICEPAY For Startups 전자계약 체결</td>
          <td>성명, 실명번호, 생년월일, 주소, 신분증 발급일자, 면허번호, 발급확인번호</td>
        </tr>
        <tr>
          <td colSpan="3">※ 보유 및 이용기간<br />
            <br />
            개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다<br />
            (단, 다른 법령에 특별한 규정이 있을 경우 관련 법령에 따라 보관)<br />
            (*) PAYU신용카드 등록 시: 신용카드사에 전달할 목적으로 위(개인)신용정보를 수집하나, 회사는 별도로 저장하지 않습니다.<br />
            (**)바이오정보의 보유•이용기간: 원본정보(특징정보 생성시까지), 특징정보(회원탈퇴시까지)
          </td>
        </tr>
      </tbody>
      </table>
      <br />
      4. 개인정보의 보유 및 이용기간<br />
      회사는 법령에 따른 개인정보 보유기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유 이용기간 내에서 개인정보를 처리 보유 합니다.<br />
      회원 탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집 및 이용목적이 달성되거나 보유 및 이용기간이 종료한 경우에는 해당 개인 정보를 지체 없이 파기 합니다.<br />
      단, 전자금융거래법, 정보통신망법 등 관련 법령의 규정에 의하여 보존할 필요가 있는경우 회사는 관련법령에서 정한 기간동안 정보를 보관합니다. 이 경우 회사는 그 보존의 목적으로만 이용하며, 법령에서 정한 각각의 개인정보 처리 및 보유기간은 다음과 같습니다.<br />
      <br/>
      <table>
        <thead>
          <tr>
            <th>보관하는 정보</th>
            <th>보존 근거</th>
            <th>보존 기간</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>계약 또는 청약 철회 등에 관한 기록</td>
            <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
            <td>5년</td>
          </tr>
          <tr>
            <td>대금결제 및 재화 등의 공급에 관한 기록</td>
            <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
            <td>5년</td>
          </tr>
          <tr>
            <td>소비자의 불만 또는 분쟁 처리에 관한 기록</td>
            <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
            <td>3년</td>
          </tr>
          <tr>
            <td>건당 1만원 초과 전자금융거래에 관한 기록</td>
            <td>전자금융거래법</td>
            <td>5년</td>
          </tr>
          <tr>
            <td>건당 1만원 이하 전자금융거래에 관한 기록</td>
            <td>전자금융거래법</td>
            <td>1년</td>
          </tr>
          <tr>
            <td>신용정보의 수집/처리 및 이용 등에 관한 기록</td>
            <td>신용정보의 이용 및 보호에 관한 법률</td>
            <td>3년</td>
          </tr>
          <tr>
            <td>웹사이트 방문에 관한 기록</td>
            <td>통신비밀보호법</td>
            <td>3년</td>
          </tr>
          <tr>
            <td>본인확인에 관한 기록</td>
            <td>정보통신 이용촉진 및 정보보호 등에 관한 법률</td>
            <td>6개월</td>
          </tr>
          <tr>
            <td>고객확인에 관한 기록</td>
            <td>특정금융거래정보의 보고 및 이용에 관한 법률</td>
            <td>5년</td>
          </tr>
          <tr>
            <td>서비스 이용기록, 접속 로그, 접속 IP 정보</td>
            <td>개인정보보호법</td>
            <td>1년</td>
          </tr>
          <tr>
            <td>온라인 서비스 로그인 ID</td>
            <td>서비스 이전의 혼선 방지, 불법적인 사용자에 대한 관련 기관 수사협조</td>
            <td>1년</td>
          </tr>
        </tbody>
      </table>
      <br/>
      5. 개인정보의 파기절차 및 파기방법<br/>
      이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다.<br/>
      회사의 개인정보 파기절차 및 파기방법은 다음과 같습니다.<br/>
      1) 파기절차<br/>
      - 이용자가 서비스 신청, 서비스 상담 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(개인정보의 보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.<br/>
      2) 파기방법<br/>
      - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.<br/>
      - 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 파기합니다.<br/>
      6. 개인정보의 처리위탁<br/>
      회사는 서비스 이행을 위하여 이용자들의 개인정보를 외부전문업체에 위탁하여 운영하거나 위탁업무의 내용이나 수탁자가 변경될 경우에는 미리 그 사실을 이용자들에게 공지할 것 입니다. 또한 업무 제휴 계약서 등을 통하여 서비스제공자의 개인정보보호 관련 지시엄수, 개인정보에 관한 비밀유지,제3자 제공의 금지 및 사고시의 책임부담, 위탁기간 종료 즉시 개인정보의 반납/파기 의무 등을 명확히 규정하고 당해 계약 내용을 서면 또는 전자적으로 보관하여 이용자의 권익을 보호할 것입니다.<br/>
      회사는 원할한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.<br/>
      <br/>
      <table>
        <thead>
          <tr>
            <th>수탁업체</th>
            <th>위탁업무 내용</th>
            <th>위탁 개인정보 항목</th>
            <th>개인정보의 보유 및 이용기간</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>(주)KG모빌리언스</td>
            <td>휴대폰 결제 및 본인인증 시스템 연동</td>
            <td>
              휴대폰 결제 시 제공형태<br/>
              •통신사정보<br/>
              •휴대폰번호<br/>
              •생년월일<br/>
              •성별<br/>
              •이메일<br/>
              •IP주소<br/>
              본인인증 시 제공형태<br/>
              •성명<br/>
              •성별<br/>
              •생년월일<br/>
              •내/외국인 구분<br/>
              •휴대폰번호<br/>
              •IP주소<br/>
              •CI/DI
            </td>
            <td>위탁업체 계약종료 시점까지 (단, 관계 법령에 따라 파기하지 않고 보존하여야 하는 경우에는 해당 기간까지)</td>
          </tr>
          <tr>
            <td>갤럭시아머니트리㈜</td>
            <td>빌게이트 전자결제 대행 서비스</td>
            <td>
              휴대폰 결제 시 제공형태<br/>
              •통신사<br/>
              •전화번호(마스킹)<br/>
              •고객ID(마스킹)<br/>
              •거래금액<br/>
              •고객명(마스킹)<br/>
              •상품명(마스킹)
            </td>
            <td>위탁업체 계약종료 시점까지 (단, 관계 법령에 따라 파기하지 않고 보존하여야 하는 경우에는 해당 기간까지)</td>
          </tr>
          <tr>
            <td>(주)다날</td>
            <td>휴대폰 결제</td>
            <td>
              •이름<br/>
              •전화번호<br/>
              •생년월일<br/>
              •성별
            </td>
            <td>위탁업체 계약종료 시점까지 (단, 관계 법령에 따라 파기하지 않고 보존하여야 하는 경우에는 해당 기간까지)</td>
          </tr>
          <tr>
            <td>(주)페이레터</td>
            <td>휴대폰 결제</td>
            <td>
              •대표자명<br/>
              •대표자 휴대폰번호<br/>
              •대표자 생년월일(실명번호)<br/>
              •회사명<br/>
              •사업자등록번호(법인번호)<br/>
              •사업장주소<br/>
              •대표번호<br/>
              •담당자명<br/>
              •담당자 휴대폰 번호<br/>
              •담당자 E-Mail<br/>
              •홈페이지주소<br/>
              •팩스번호<br/>
              •정산계좌정보(은행명,계좌번호,예금주)
            </td>
            <td>위탁업체 계약종료 시점까지 (단, 관계 법령에 따라 파기하지 않고 보존하여야 하는 경우에는 해당 기간까지)</td>
          </tr>
        </tbody>
      </table>
      <br/>
      회사는 위탁계약 체결 시 '개인정보보호법' 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적•관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
      <br/>
      <br/>
      위탁업무의 내용이나 수탁자가 변경될 때에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.<br/>
      <br/>
      7. 이용자 및 법정대리인의 권리와 그 행사방법<br/>
      1) 이용자는 언제나 본인의 개인정보의 열람 및 오류정정을 요구할 수 있고, 이에 대해 회사는 체없이 필요한 조치를 취합니다. 또한 회사는 오류정정 요구가 있을 시 그 정정 때까지 당해 해당정보를 이용하지 않으며, 기 요구 시에는 즉각 폐기하여 어떠한 용도로도 사용할 수 없도록 합니다.<br/>
      2) 인터넷 홈페이지를 이용한 열람, 오류정정의 방법 및 신청접수 경로는 아래의 순서로 신청 접수 후 처리가 가능합니다.<br/>
          ① 회사 홈페이지(https://www.nicepay.co.kr) URL접속<br/>
          ② 화면 중앙의 [가맹점관리자] 클릭 후 가맹점ID/PW 입력을 통한 로그인<br/>
          ③ 고객지원 > FAQ/문의상담 > 정보변경 > 개인정보 변경 신청 접수<br/>
          ④ 서비스 해지(동의철회) : 위와 같은 경로로 정보변경 > 해지 신청 접수<br/>
          ⑤ NICEPAY For Startups(https://start.nicepay.co.kr) URL접속<br/>
          ⑥ 화면 우측 상단의 [로그인]클릭 후 가입 시 등록한 이메일/비밀번호를 통한 로그인<br/>
          ⑦ 좌측 메뉴 > 상점설정 > 기본정보관리 > 사업자 정보 변경신청 접수<br/>
          ⑧ 서비스 해지(동의철회) : 위와 같은 경로로 상점해지 > 해지 신청 접수<br/>
      3) 또한, 회사 고객센터(1661-0808) 전화로 연락하시거나 방문을 통한 경우에는 이용자 본인임을 확인한 후 열람/변경/회원탈퇴 신청을 하실 수 있습니다.<br/>
      4) 회사는 만 14세 미만 아동(이하 "아동"이라 함)의 개인정보를 수집하지 않고 있습니다. 다만, 개인정보 수집이 필요한 경우 반드시 법정대리인의 동의를 구하고 있습니다. 회사는 법정대리인의 동의를 받기 위하여 아동으로부터 법정대리인의 성명과 연락처 등 최소한의 정보를 요구할 수 있으며,아동의 법정대리인은 서면, 전화, E-mail, FAX 등을 통하여 아동의 개인정보에 대한 열람, 정정 및 삭제를 요청할 수 있으며, 이러한 요청이 있는 경우 회사는 지체 없이 필요한 조치를 취합니다.<br/>
      <br/>
      8. 인터넷 접속정보파일 등 개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항<br/>
      회사는 이용자의 정보를 수시로 저장하고 찾아내는 '쿠키(cookie)'을 설치·운용 합니다. 쿠키란 회사의 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다. 회사는 다음과 같은 목적을 위해 쿠키 등을 사용합니다.<br/>
      1) 쿠키 등 사용 목적<br/>
      - 홈페이지 이용자의 접속 도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공을 목적으로 이루어지며, 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.<br/>
      2) 쿠키 설정 거부 방법<br/>
      [인터넷 익스플로러(Windows 10용 Internet Explore11)]<br/>
        - Internet Explore11에서 도구 버튼을 선택한 다음 인터넷 옵션을 선택<br/>
        - 개인 정보 탭을 선택하고 설정에서 고급을 선택한 다음 쿠키의 차단 또는 허용을 선택<br/>

      [Microsoft Edge]<br/>
        - Edge에서 오른쪽 상단 '…' 표시를 클릭한 후, 설정을 클릭합니다.<br/>
        - 설정 페이지 좌측의 '개인정보, 검색 및 서비스'를 클릭한 후 『추적방지』섹션에서 '추적방지'여부 및 수준을 선택합니다.<br/>
        - InPrivate를 검색할 때 항상 "엄격", 추적 방지 사용' 여부를 선택합니다.<br/>
        - 아래 『개인정보』 섹션에서 '추적 안함 요청보내기' 여부를 선택합니다.<br/>

      [크롬 브라우저]<br/>
        - Chrome에서 오른쪽 상단 ':' 표시(chrome 맞춤설정 및 제어)를 클릭한 후, 설정 표시를 클릭합니다.<br/>
        - 설정 페이지 하단에 '고급 설정 표시'를 클릭하고 『개인정보』 섹션에서 콘텐츠 설정을 클릭합니다.<br/>
        - 쿠키 섹션에서 '타사 쿠키 및 사이트 데이터 차단'의 체크박스를 선택합니다.<br/>

      3) 단, 이용자께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.<br/>
      <br/>
      9. 개인정보의 안전성 확보조치<br/>
      회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.<br/>

      1) 관리적 조치: 내부관리계획 수립 및 시행, 정기적 직원 교육 등<br/>
      2) 기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 식별정보 등의 암호화, 보안프로그램 설치<br/>
      3) 물리적 조치: 전산실, 자료 보관실 등의 접근 통제<br/>
      <br/>
      10. 개인정보 보호책임자의 성명 또는 개인정보보호 업무 및 관련 고충사항을 처리하는 부서의 명칭과 그 전화번호 등 연락처<br/>
      1) 개인정보 보호책임자 및 개인정보 보호담당자<br/>
      회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 담당 부서 및 개인정보보호책임자, 개인정보보호담당자를 지정하고 있습니다.<br/>
      <br/>
      <table>
        <thead>
          <tr>
            <th>구분</th>
            <th>개인정보 보호책임자</th>
            <th>개인정보 보호담당자</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>성명/직위</td>
            <td>조인혜/본부장</td>
            <td>김은혁/실장</td>
          </tr>
          <tr>
            <td>전화번호</td>
            <td>1661-0808</td>
            <td>1661-0808</td>
          </tr>
          <tr>
            <td>이메일</td>
            <td>service@nicepay.co.kr</td>
            <td>ehkim@nicepay.co.kr</td>
          </tr>
        </tbody>
      </table>
      <br/>
      2) 고충사항 처리 부서<br/>
      이용자는 회사의 서비스를 이용하며 발생하는 모든 개인정보보호 관련 민원을 개인정보보호책임자 혹은 담당부서로 신고할 수 있으며 개인정보보호 업무 및 관련 고충사항을 처리하는 부서는 아래와 같으며 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.<br/>
      <br/>
      <table>
        <thead>
          <tr>
            <th>부서명</th>
            <th>연락처</th>
            <th>이메일</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>경영기획실</td>
            <td>02-2186-4816</td>
            <td>rm@nicepay.co.kr</td>
          </tr>
        </tbody>
      </table>
      <br/>
      3) 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다<br/>
      <br/>
      <table>
        <thead>
          <tr>
            <th>기관명</th>
            <th>연락처</th>
            <th>홈페이지</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>개인정보침해신고센터</td>
            <td>(국번없이) 118</td>
            <td>http://privacy.kisa.or.kr</td>
          </tr>
          <tr>
            <td>개인정보 분쟁조정위원회</td>
            <td>1833-6972</td>
            <td>http://kopico.go.kr</td>
          </tr>
          <tr>
            <td>대검찰청 사이버수사과</td>
            <td>(국번없이) 1301</td>
            <td>http://spo.go.kr</td>
          </tr>
          <tr>
            <td>경찰청 사이버안전국</td>
            <td>(국번없이) 182</td>
            <td>http://cyberbureau.police.go.kr</td>
          </tr>
        </tbody>
      </table>
      <br/>
      11. 기타 개인정보처리방침의 변경에 관한 사항<br/>
      개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 '공지사항'을 통해 고지합니다.<br/>
      <br/>
      부칙<br/>
      제 1 호, 2016. 8. 1.<br/>
      본 약관은 2016년 8월 8일부터 시행한다.<br/>

      제 2 호, 2016. 11. 3.<br/>
      본 약관은 2016년 11월 11일부터 시행한다.<br/>

      제 3 호, 2017. 1. 25.<br/>
      본 약관은 2017년 2월 1일부터 시행한다.<br/>

      제 4 호, 2017. 3. 16.<br/>
      본 약관은 2017년 3월 23일부터 시행한다.<br/>

      제 5 호, 2018. 3. 8.<br/>
      본 약관은 2018년 3월 15일부터 시행한다.<br/>

      제 6 호, 2018. 5. 31.<br/>
      본 약관은 2018년 6월 7일부터 시행한다.<br/>

      제 7 호, 2018. 7. 9.<br/>
      본 약관은 2018년 7월 17일부터 시행한다.<br/>

      제 8 호, 2018. 10. 22.<br/>
      본 약관은 2018년 10월 29일부터 시행한다.<br/>

      제 9 호, 2019. 2. 11.<br/>
      본 약관은 2019년 2월 18일부터 시행한다.<br/>

      제 10 호, 2020. 5. 28.<br/>
      본 약관은 2020년 6월 1일부터 시행한다.<br/>

      제 11 호, 2020. 11. 09.<br/>
      본 약관은 2020년 11월 17일부터 시행한다.<br/>

      제 12 호, 2020. 12. 08.<br/>
      본 약관은 2020년 12월 15일부터 시행한다.<br/>

      제 13 호, 2021. 05. 26.<br/>
      본 약관은 2021년 6월 2일부터 시행한다.<br/>

      제 14 호, 2021. 06. 22.<br/>
      본 약관은 2021년 6월 29일부터 시행한다.<br/>

      제 15 호, 2022. 05. 26.<br/>
      본 약관은 2022년 6월 15일부터 시행한다.<br/>

      제 16 호, 2022. 11. 10.<br/>
      본 약관은 2022년 11월 17일부터 시행한다.<br/>
    </>
  );
}

export default PaymentPolicyContent3;