import { useEffect, useState } from "react";
import { faqDetailAPI } from "../../apis/FaqAPICalls";
import { useLocation, useNavigate } from "react-router-dom";
import ScrollToTop from "../../js/ScrollToTop";

export default function FaqDetail() {

  const navigate = useNavigate();
  const { state } = useLocation();

  const moveBack = () => {
    navigate("/faq", {replace: true});
  };

  useEffect(() => {
    ScrollToTop();
  }, []);

  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>Faq <span className="arrow_left"></span> {state.cate_name}</h1>
        </div>
      </header>

      <div className="contents contents2">
        <div className="centerWrap">
          <div className="noticeWrap">
            <div className="noticeTitle">Q. {state.title}</div>
          </div>

          <div className="noticeDetailContent" dangerouslySetInnerHTML={{__html: state.content}}>
            {/* img data 불러올 수 있는 서버 주소 필요 */}
            {/* <img src={`${IMG_ADDRESS}/upload/notice/` + state.filename_svr}/> */}
          </div>
        </div>
      </div>
    </>
  );
}