import { useNavigate } from "react-router-dom";
import { getSnsMemberInfoAPI, snsDuplicateCheckAPI } from "../../apis/MemberAPICalls";
import { useDispatch } from "react-redux";
import { GET_MEMBER_INFO } from "../../modules/member/MemberModule";
import { encrypt } from "../../js/encrypt";

function SnsLoginCheck() {

  const path = window.location.pathname;
  const snsId = path.substring(7);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  (async () => {
    const resCode = await snsDuplicateCheckAPI(snsId);

    if(-1 === resCode) {
      const info = await getSnsMemberInfoAPI(snsId);

      sessionStorage.setItem("mem_seq", encrypt(info.seq));
      dispatch({ type: GET_MEMBER_INFO, payload: info });
      navigate("/");
    }
    else navigate("/join", { state: snsId });
  })();
}

export default SnsLoginCheck;