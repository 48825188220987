import axios from "axios";
import { TEST_ADD } from "../port/Port";

// faq 목록 조회
export async function faqListAPI(page, cate_seq) {
  
  const GET_FAQ_LIST = `${TEST_ADD}/faqList?page_no=${page}&sDisplay=Y&sCateSeq=${cate_seq}`;
  let list = [];

  await axios.get(GET_FAQ_LIST)
  .then(res => {
    if(1 === res.data.resultCode) list = res.data.result;
  })
  .catch(err => console.error(err));

  return list;
}

// faq 카테고리 조회
export async function faqCateListAPI() {

  const GET_FAQ_CATE_LIST = `${TEST_ADD}/faqCateList`;
  let list = [];

  await axios.get(GET_FAQ_CATE_LIST)
  .then(res => {
    if(1 === res.data.resultCode) list = res.data.result;
  })
  .catch(err => console.error(err));

  return list;
}

// faq 상세정보 조회
// export async function faqDetailAPI(seq) {

// }