function SendVerifyCodeInterval(timerId, time, setMin, setSec) {

    timerId.current = setInterval(() => {
        setMin(String(parseInt(time.current / 60)).padStart(2, "0"));
        setSec(String(time.current % 60).padStart(2, "0"));
        time.current -= 1;
    }, 1000);

    return () => clearInterval(timerId.current);
}

export default SendVerifyCodeInterval;