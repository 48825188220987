import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { POST_COLL_SELECTED_DATE } from "../../modules/delivery/DeliveryModule";

function CancelDeliveryServiceModal({status, setStatus}) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const today = new Date()
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const date = String(today.getDate()).padStart(2, "0");

  const closeModal = () => {
    setStatus(false);
  };

  const moveToDelivery = () => {
    dispatch({type: POST_COLL_SELECTED_DATE, payload: ["", false]});
    navigate("/delivery");
  };

  return (
    <>
      <div className={ status ? "modal smallModal active" : "modal smallModal" }>
        <div className="modalNoHeader">
          <div className="modalTxt1">
            <span className="bold">{`${month}월 ${date}일`}</span> 신청하신 <span className="bold">수거</span> 서비스를<br/>
            취소 하시겠습니까?
          </div>
        </div>

        <div className="modalButtons">
          <div className="inside">
            <button tabIndex={0} className="buttonCancel button1in2" title="" onClick={closeModal}>아니오</button>
            <button tabIndex={0} className="buttonDone button1in2" title="" onClick={moveToDelivery}>예</button>
          </div>
        </div>
      </div>

      <button tabIndex={0} className={ status ? "dimm active" : "dimm" } title="닫기" onClick={closeModal}></button>
    </>
  );
}

export default CancelDeliveryServiceModal;