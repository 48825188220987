function ConfirmFaceServiceModal({ tab, status, setStatus, info, setInfo }) {

  const checkbox = document.getElementsByName("face_yn")[0];

  const closeModal = () => {
    setStatus(false);
    setInfo({
      ...info,
      face_yn: false
    });
    checkbox.checked = false;
  };

  const acceptFaceService = () => {
    setStatus(false);
    setInfo({
      ...info,
      face_yn: true
    });
    checkbox.checked = true;
  };

  return (
    <>
      <div className={ status ? "modal smallModal active" : "modal smallModal" }>
        <div className="modalNoHeader">
          <img src={`${process.env.PUBLIC_URL}/assets/images/img3.png`} className="img3" alt=""/>
          <div className="modalTxt1">
            {
              !tab ?
              <>
                고객과 배송기사가 직접 만나<br/>
                캐비닛을 전달하는 서비스입니다.<br/><br/>

                공동현관 비밀번호는 필수 입니다.<br/>
                약속한 시간에 대면 수거에 실패할 경우<br/>
                횟수가 차감될 수 있으니 꼭 확인해주세요.<br/><br/>

                <span className="bold">신청 하시겠습니까?</span>
              </>
              :
              <>
                고객과 배송기사가 직접 만나<br/>
                캐비닛을 전달하는 서비스입니다.<br/><br/>

                공동현관 비밀번호는 필수 입니다.<br/>
                약속한 시간에 대면 배송에 실패할 경우<br/>
                횟수가 차감될 수 있으니 꼭 확인해주세요.<br/><br/>

                <span className="bold">신청 하시겠습니까?</span>
              </>
            }
          </div>
        </div>

        <div className="modalButtons">
          <div className="inside">
            <button tabIndex={0} className="buttonCancel button1in2" title="" onClick={closeModal}>취소</button>
            <button tabIndex={0} className="buttonDone button1in2" title="" onClick={acceptFaceService}>신청합니다</button>
          </div>
        </div>
      </div>

      <button tabIndex={0} className={ status ? "dimm active" : "dimm" } title="닫기" onClick={closeModal}></button>
    </>
  );
}

export default ConfirmFaceServiceModal;