import { useEffect, useState } from 'react';
import DaumPostCode from 'react-daum-postcode';
import { useNavigate } from 'react-router-dom';
import { getMemberDetailAddressAPI, getMemberInfoAPI, updateAddrAPI } from '../../../apis/MemberAPICalls';
import { useSelector } from 'react-redux';
import SearchCurrentLocation from '../../../js/SearchCurrentLocation';
import Swal from 'sweetalert2';
import ScrollToTop from '../../../js/ScrollToTop';
import { decrypt } from '../../../js/encrypt';
import DaumAddr from './DaumAddr';

function ModifyAddr() {

  const memberReducer = useSelector(state => state.memberReducer);
  const urlParams = new URL(location.href).searchParams;
  const type = urlParams.get("type");
  const addr_seq = urlParams.get("seq");
  const navigate = useNavigate();
  const mem_seq = sessionStorage.getItem("mem_seq") ? decrypt(sessionStorage.getItem("mem_seq")) : "";
  const [form, setForm] = useState({
    addr: '',
    detailAddr: '',
    zipCode: '',
    doorPw: '',
    checkEV: "",
    checkVehicle: "",
  });
  const [modalState, setModalState] = useState(false);
  const [doorPwCheck, setDoorPwCheck] = useState(false);
  const [memInfo, setMemInfo] = useState({});
  const [startGeolocation, setStartGeolocation] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [isAvailable1, setIsAvailable1] = useState(false);
  const [isAvailable2, setIsAvailable2] = useState(false);

  const cDefaultAddrInput = document.getElementById("cDefaultAddrInput");
  const dDefaultAddrInput = document.getElementById("dDefaultAddrInput");

  const moveBack = () => {
    window.history.back();  
  };

  const onChangeHandler = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const onCheckHandler = e => {
    setIsDefault(!isDefault);
  };

  const onDoorPwCheck = e => {
    setDoorPwCheck(e.target.value);
  };

  const openDaumPost = () => {
    setModalState(true);
  };

  const closeDaumPost = () => {
    setModalState(false);
  }

  const submitAddr = async () => {
    if(doorPwCheck){
      const resCode = await updateAddrAPI(form, isDefault, type, addr_seq);
      
      if(1 === resCode) {
        Swal.fire({
          text: "주소 수정에 성공했습니다.",
          confirmButtonText: "확인",
          position: "center",
          confirmButtonColor: "#0064ff",
        })
        .then(res => {
          if(res.isConfirmed) {
            if(!addr_seq) navigate("/delivery");
            else if(addr_seq) navigate("/mypage");
          }
        });
      } else {
        Swal.fire({
          html: "주소 수정에 실패했습니다.<br/>다시 확인해주세요.",
          confirmButtonText: "확인",
          position: "center",
          confirmButtonColor: "#0064ff",
        })
        .then();
        return;
      }
    } else {
      Swal.fire({
        text: "공동현관 비밀번호 확인 여부를 체크해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }
  };

  const searchLocation = () => {
    setStartGeolocation(true);
  };

  useEffect(() => {
    ScrollToTop();

    if(!Object.keys(memInfo).length && !addr_seq) {
      (async () => {
        setMemInfo(await getMemberInfoAPI(mem_seq));
      })();
    } else if(!Object.keys(memInfo).length && addr_seq) {
      (async () => {
        setMemInfo(await getMemberDetailAddressAPI(addr_seq));
      })();
    }
  }, []);

  useEffect(() => {
    if(Object.keys(memInfo).length && !addr_seq) {
      if("C" === type) {
        setForm({
          ...form,
          addr: memInfo.collection_addr1,
          detailAddr: memInfo.collection_addr2,
          zipCode: memInfo.collection_zip_cd,
          doorPw: memInfo.collection_door_pw,
          checkEV: memInfo.elevator_yn,
          checkVehicle: memInfo.call_yn,
        });
        setIsDefault("Y" === memInfo.default_yn ? true : false);
        setDoorPwCheck("Y" === memInfo.collection_door_pw_yn ? "Y" : "N");
      } else if("D" === type) {
        setForm({
          ...form,
          addr: memInfo.delevery_addr1,
          detailAddr: memInfo.delevery_addr2,
          zipCode: memInfo.delevery_zip_cd,
          doorPw: memInfo.delevery_door_pw,
          checkEV: memInfo.elevator_yn,
          checkVehicle: memInfo.call_yn,
        });
        setIsDefault("Y" === memInfo.default_yn ? true : false);
        setDoorPwCheck("Y" === memInfo.delevery_door_pw_yn ? "Y" : "N");
      }
    } else if(Object.keys(memInfo).length && addr_seq) {
      if("C" === type) {
        setForm({
          ...form,
          addr: memInfo.addr1,
          detailAddr: memInfo.addr2,
          zipCode: memInfo.zip_cd,
          doorPw: memInfo.door_pw,
          checkEV: memInfo.elevator_yn,
          checkVehicle: memInfo.call_yn,
        });
        setIsDefault("Y" === memInfo.default_yn ? true : false);
        setDoorPwCheck("Y" === memInfo.door_pw_yn ? "Y" : "N");
      } else if("D" === type) {
        setForm({
          ...form,
          addr: memInfo.addr1,
          detailAddr: memInfo.addr2,
          zipCode: memInfo.zip_cd,
          doorPw: memInfo.door_pw,
          checkEV: memInfo.elevator_yn,
          checkVehicle: memInfo.call_yn,
        });
        setIsDefault("Y" === memInfo.default_yn ? true : false);
        setDoorPwCheck("Y" === memInfo.door_pw_yn ? "Y" : "N");
      }
    }
  }, [memInfo]);

  useEffect(() => {
    if("C" === type && cDefaultAddrInput && 1 === memberReducer.cAddrCnt) {
      cDefaultAddrInput.checked = true;
      cDefaultAddrInput.disabled = true;
    }
    else if("D" === type && dDefaultAddrInput && 1 === memberReducer.dAddrCnt) {
      dDefaultAddrInput.checked = true;
      dDefaultAddrInput.disabled = true;
    }

    if(form.addr) {
      if(form.addr.includes("서울") || (form.addr.includes("경기") && (form.addr.includes("하남") || form.addr.includes("구리")))) setIsAvailable1(true);
      else setIsAvailable1(false);
    }

    if(form.checkEV) {
      if("N" === form.checkEV) setIsAvailable2(false);
      else setIsAvailable2(true);
    }
  }, [form]);

  return (
    <>
      {
        "C" === type ?
        <>
          <header>
            <div className="centerWrap">
              <button onClick={moveBack} tabIndex={0} className="back" title="뒤로"></button>
              <h1>수거지 수정</h1>
            </div>
          </header>

          <div className="contents">
            <div className="centerWrap">
              <div className="dataLabel">
                <div className="sp" title="필수"></div>
                <div className="label">주소</div>
              </div>
              <div className="dataWrap">
                <input name="addr" type="text" className="inputNormal inputAddress" title="기본주소" placeholder="주소검색" onClick={openDaumPost} value={form.addr} readOnly/>
              </div>
              {
                modalState &&
                <DaumAddr closeDaumPost={closeDaumPost} form={form} setForm={setForm}/>
              }
              <div className="dataWrap" style={{ margin: "12px 0 0" }}>
                <input id="detailAddr" name="detailAddr" type="text" className="inputNormal" title="상세주소" placeholder="상세주소" onChange={onChangeHandler} value={form.detailAddr}/>
              </div>

              {/* <div className="buttonDone yourLocation" onClick={searchLocation}><span>현재 위치로 주소찾기</span></div> */}
              {
                startGeolocation ? 
                <SearchCurrentLocation/>
                :
                <></>
              }

              <div className="dataWrap">
                <label className="brisakInput">
                  <input id="cDefaultAddrInput" name="isDefault" type="checkbox" onChange={onCheckHandler} checked={isDefault}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">기본 수거지로 설정</div>
                  </div>
                </label>
              </div>

              {
                !form.addr || !isAvailable1 ?
                <div className="warning">
                  <div className="txt">서비스가 불가능한 주소입니다.</div>
                </div>
                :
                <div className="available">
                  <div className="txt" style={{ color: "green" }}>서비스가 가능한 주소입니다.</div>
                </div>
              }

              <div className="dataLabel">
                <div className="sp" title="필수"></div>
                <div className="label">공동현관 비밀번호</div>
              </div>
              <div className="dataWrap">
                <input name="doorPw" type="text" className="inputNormal" title="공동현관 비밀번호" onChange={onChangeHandler} value={("Y" === doorPwCheck || !doorPwCheck) ? form.doorPw : form.doorPw = ""} readOnly={"N" === doorPwCheck ? true : false}/>
              </div>

              <div className="dataWrap">
                <label className="brisakInput">
                  <input type="checkbox" value="N" checked={"N" === doorPwCheck ? true : false} onChange={onDoorPwCheck}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">공동현관 비밀번호 없이 출입이 가능합니다.</div>
                  </div>
                </label>
              </div>
              <div className="dataWrap">
                <label className="brisakInput">
                  <input type="checkbox" value="Y" checked={"Y" === doorPwCheck ? true : false} onChange={onDoorPwCheck}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">공동현관 비밀번호를 정확하게 입력했습니다.</div>
                  </div>
                </label>
              </div>

              <div className="dataLabel">
                <div className="sp" title="필수"></div>
                <div className="label">엘리베이터 운행 확인</div>
              </div>
              <div className="dataWrap">
                <label className="brisakInput">
                  <input type="checkbox" name="checkEV" value="Y" checked={"Y" === form.checkEV ? true : false} onChange={onChangeHandler}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">엘리베이터가 운행중이거나, 1층 주소입니다.</div>
                  </div>
                </label>
              </div>
              <div className="dataWrap">
                <label className="brisakInput">
                  <input type="checkbox" name="checkEV" value="N" checked={"N" === form.checkEV ? true : false} onChange={onChangeHandler}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">엘리베이터가 없는 고층건물입니다.</div>
                  </div>
                </label>
              </div>

              {
                !form.checkEV ||  "N" === form.checkEV?
                <div className="warning">
                  <div className="txt">서비스가 불가능한 주소입니다.</div>
                </div>
                :
                <div className="available">
                  <div className="txt" style={{ color: "green" }}>서비스가 가능한 주소입니다.</div>
                </div>
              }

              <div className="dataLabel">
                <div className="sp" title="필수"></div>
                <div className="label">운송 차량 사전 등록 확인(단지 내 출입)</div>
              </div>
              <div className="dataWrap">
                <label className="brisakInput">
                  <input type="checkbox" name="checkVehicle" value="Y" checked={"Y" === form.checkVehicle ? true : false} onChange={onChangeHandler}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">단지 또는 건물 내로 운송차량이 출입하려면, 사전에 차량 번호 등록이 필요합니다.</div>
                  </div>
                </label>
              </div>
              <div className="dataWrap">
                <label className="brisakInput">
                  <input type="checkbox" name="checkVehicle" value="N" checked={"N" === form.checkVehicle ? true : false} onChange={onChangeHandler}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">사전 등록이 필요 없습니다.</div>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div className="bottomButtons">
            <button onClick={submitAddr} tabIndex={0} className={isAvailable1 && isAvailable2 ? "buttonDone" : "buttonDone disabledBtn"} disabled={isAvailable1 && isAvailable2 ? false : true}>저장</button>
          </div>

          {/* <div id="layer" style={{ display: "none", position: "fixed", overflow: "hidden", zIndex: "1", WebkitOverflowScrolling: "touch" }}>
            <img src="//t1.daumcdn.net/postcode/resource/images/close.png" id="btnCloseLayer" style={{ cursor: "pointer", position: "absolute", right: "-3px", top: "-3px", zIndex: "1" }} onClick={closeDaumPostCode} alt="닫기 버튼"/>
          </div> */}
        </>
        :
        <>
          <header>
            <div className="centerWrap">
              <button onClick={moveBack} tabIndex={0} className="back" title="뒤로"></button>
              <h1>배송지 수정</h1>
            </div>
          </header>

          <div className="contents">
            <div className="centerWrap">
              <div className="dataLabel">
                <div className="sp" title="필수"></div>
                <div className="label">주소</div>
              </div>
              <div className="dataWrap">
                <input name="addr" type="text" className="inputNormal inputAddress" title="기본주소" placeholder="주소검색" onClick={openDaumPost} value={form.addr} readOnly/>
              </div>
              {
                modalState &&
                <DaumAddr closeDaumPost={closeDaumPost} form={form} setForm={setForm}/>
              }
              <div className="dataWrap" style={{ margin: "12px 0 0" }}>
                <input id="detailAddr" name="detailAddr" type="text" className="inputNormal" title="상세주소" placeholder="상세주소" onChange={onChangeHandler} value={form.detailAddr}/>
              </div>

              {/* <div className="buttonDone yourLocation" onClick={searchLocation}><span>현재 위치로 주소찾기</span></div> */}
              {
                startGeolocation ? 
                <SearchCurrentLocation/>
                :
                <></>
              }

              <div className="dataWrap">
                <label className="brisakInput">
                  <input id="dDefaultAddrInput" name="isDefault" type="checkbox" onChange={onCheckHandler} checked={form.isDefaultAddr}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">기본 배송지로 설정</div>
                  </div>
                </label>
              </div>
              
              {
                !form.addr || !isAvailable1 ?
                <div className="warning">
                  <div className="txt">서비스가 불가능한 주소입니다.</div>
                </div>
                :
                <div className="available">
                  <div className="txt" style={{ color: "green" }}>서비스가 가능한 주소입니다.</div>
                </div>
              }

              <div className="dataLabel">
                <div className="sp" title="필수"></div>
                <div className="label">공동현관 비밀번호</div>
              </div>
              <div className="dataWrap">
                <input name="doorPw" type="text" className="inputNormal" title="공동현관 비밀번호" onChange={onChangeHandler} value={("Y" === doorPwCheck || !doorPwCheck) ? form.doorPw : form.doorPw = ""} readOnly={"N" === doorPwCheck ? true : false}/>
              </div>

              <div className="dataWrap">
                <label className="brisakInput">
                  <input type="checkbox" value="N" checked={"N" === doorPwCheck ? true : false} onChange={onDoorPwCheck}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">공동현관 비밀번호 없이 출입이 가능합니다.</div>
                  </div>
                </label>
              </div>
              <div className="dataWrap">
                <label className="brisakInput">
                  <input type="checkbox" value="Y" checked={"Y" === doorPwCheck ? true : false} onChange={onDoorPwCheck}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">공동현관 비밀번호를 정확하게 입력했습니다.</div>
                  </div>
                </label>
              </div>

              <div className="dataLabel">
                <div className="sp" title="필수"></div>
                <div className="label">엘리베이터 운행 확인</div>
              </div>
              <div className="dataWrap">
                <label className="brisakInput">
                  <input type="checkbox" name="checkEV" value="Y" checked={"Y" === form.checkEV ? true : false} onChange={onChangeHandler}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">엘리베이터가 운행중이거나, 1층 주소입니다.</div>
                  </div>
                </label>
              </div>
              <div className="dataWrap">
                <label className="brisakInput">
                  <input type="checkbox" name="checkEV" value="N" checked={"N" === form.checkEV ? true : false} onChange={onChangeHandler}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">엘리베이터가 없는 고층건물입니다.</div>
                  </div>
                </label>
              </div>

              {
                !form.checkEV ||  "N" === form.checkEV?
                <div className="warning">
                  <div className="txt">서비스가 불가능한 주소입니다.</div>
                </div>
                :
                <div className="available">
                  <div className="txt" style={{ color: "green" }}>서비스가 가능한 주소입니다.</div>
                </div>
              }

              <div className="dataLabel">
                <div className="sp" title="필수"></div>
                <div className="label">운송 차량 사전 등록 확인(단지 내 출입)</div>
              </div>
              <div className="dataWrap">
                <label className="brisakInput">
                  <input type="checkbox" name="checkVehicle" value="Y" checked={"Y" === form.checkVehicle ? true : false} onChange={onChangeHandler}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">단지 또는 건물 내로 운송차량이 출입하려면, 사전에 차량 번호 등록이 필요합니다.</div>
                  </div>
                </label>
              </div>
              <div className="dataWrap">
                <label className="brisakInput">
                  <input type="checkbox" name="checkVehicle" value="N" checked={"N" === form.checkVehicle ? true : false} onChange={onChangeHandler}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">사전 등록이 필요 없습니다.</div>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div className="bottomButtons">
            <button onClick={submitAddr} tabIndex={0} className={isAvailable1 && isAvailable2 ? "buttonDone" : "buttonDone disabledBtn"} disabled={isAvailable1 && isAvailable2 ? false : true}>저장</button>
          </div>

          {/* <div id="layer" style={{ display: "none", position: "fixed", overflow: "hidden", zIndex: "1", WebkitOverflowScrolling: "touch" }}>
            <img src="//t1.daumcdn.net/postcode/resource/images/close.png" id="btnCloseLayer" style={{ cursor: "pointer", position: "absolute", right: "-3px", top: "-3px", zIndex: "1" }} onClick={closeDaumPostCode} alt="닫기 버튼"/>
          </div> */}
        </>
      }
    </>
  );
}

export default ModifyAddr;