import axios from "axios";
import { TEST_ADD } from "../port/Port";
import Swal from "sweetalert2";

export async function getBannerListAPI() {

  const GET_BANNER_LIST_URL = `${TEST_ADD}/bannerList`;
  let list = [];
  const now = new Date();

  await axios.get(GET_BANNER_LIST_URL)
  .then(res => {
    if(1 === res.data.resultCode) {
      list = res.data.result.filter(item => now <= new Date(item.date_ed) && "Y" === item.display_yn);
    } else {
      Swal.fire({
        text: "배너 목록 조회 실패",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
    }
  })
  .catch(err => console.error(err));

  return list;
}

export async function getServiceListAPI() {

  const GET_SERVICE_LIST_URL = `${TEST_ADD}/goodsList?page_no=${1}`;
  let list = [];

  await axios.get(GET_SERVICE_LIST_URL)
  .then(res => {
    if(1 === res.data.resultCode) list = res.data.result;
    else {
      // alert("서비스 목록 조회 실패");
      Swal.fire({
        
        text: "서비스 목록 조회 실패",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
    }
  })
  .catch(err => console.error(err));

  return list;
}

export async function getDetailServiceAPI(service_seq) {

  const GET_DETAIL_SERVICE_INFO_URL = `${TEST_ADD}/goodsInfo?seq=${service_seq}`;
  let info = {};

  await axios.get(GET_DETAIL_SERVICE_INFO_URL)
  .then(res => {
    if(1 === res.data.resultCode) info = res.data.result;
    else {
      // alert("상품 상세 정보 조회 실패");
      Swal.fire({
        
        text: "상품 상세 정보 조회 실패",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
    }
  })
  .catch(err => console.error(err));

  return info;
}

export async function getPopupListAPI() {

  const GET_POPUP_LIST_URL = `${TEST_ADD}/popupList?page_no=0`;
  let list = [];
  const now = new Date();

  await axios.get(GET_POPUP_LIST_URL)
  .then(res => {
    if(1 === res.data.resultCode) list = res.data.result.filter(item => now <= new Date(item.date_ed) && "Y" === item.display_yn);
    else {
      Swal.fire({
        text: "팝업창 리스트 조회 실패",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
    }
  })
  .catch(err => console.error(err));

  return list;
}

export async function getCabinetSizeListAPI() {

  const GET_CABINET_LIST_URL = `${TEST_ADD}/manage/cabinetSizeList`;
  let list = [];

  await axios.get(GET_CABINET_LIST_URL)
  .then(res => {
    if(1 === res.data.resultCode) list = res.data.result;
  })
  .catch(err => console.error(err));

  return list;
}