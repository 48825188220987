import { createActions, handleActions } from "redux-actions";

const initialState = {
  payData: {},
}

export const POST_PAY_DATA = "pay/POST_PAY_DATA";

const actions = createActions({
  [POST_PAY_DATA]: () => {},
});

const payReducer = handleActions({
  [POST_PAY_DATA]: (state, { payload }) => {
    state.payData = {...payload};

    let newState = {...state};

    return newState;
  },
}, initialState);

export default payReducer;