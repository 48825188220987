import ModifyAddr from "../../../components/delivery/address/ModifyAddr";

function Modify() {

  return (
    <>
      <ModifyAddr/>
    </>
  );
}

export default Modify;