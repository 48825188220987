import { useNavigate } from "react-router-dom";

function AddrConfirm({status, setStatus, type}) {

  const navigate = useNavigate();

  const closeModal = () => {
    setStatus(false);
  };

  const moveToMypage = () => {
    navigate("/mypage");
  };

  return (
    <>
      <div className={status ? "modal smallModal active" : "modal smallModal"}>
        <div className="modalNoHeader">
          <div className="modalTxt1">
            해당 지역은<br/>
            스토르앤고 서비스 준비중입니다.<br/>
            <span className="bold">
              {
                "C" === type && "다른 수거지로 변경해 주세요."
              }
              {
                "D" === type && "다른 배송지로 변경해 주세요."
              }
              {
                "All" === type && "다른 수거/배송지로 변경해 주세요."
              }
            </span>
          </div>
        </div>

        <div className="modalButtons">
          <div className="inside">
            <button tabIndex={0} className="buttonCancel button1in2" title="" onClick={closeModal}>다음에 변경하기</button>
            <button tabIndex={0} className="buttonDone button1in2" title="" onClick={moveToMypage}>지금 변경하기</button>
          </div>
        </div>
      </div>

      <button tabIndex={0} className={status ? "dimm active" : "dimm"} title="닫기" onClick={closeModal}></button>
    </>
  );
}

export default AddrConfirm;