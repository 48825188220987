import MypageComp from "../../components/mypage/MypageComp";

function Mypage() {

  return (
  <>
    <MypageComp/>
  </>
  );
}

export default Mypage;