import { useEffect, useState } from "react";
import { getMemberInfoAPI, getMemberPaymentListAPI } from "../../apis/MemberAPICalls";
import { useNavigate } from "react-router-dom";
import { getCollectionCateListAPI, getHolidayListAPI, getSelectableDateListAPI } from "../../apis/DeliveryAPICalls";
import { useDispatch, useSelector } from "react-redux";
import CancelDeliveryServiceModal from "../modal/CancelDeliveryServiceModal";
import ConfirmDeliveryModal from "../modal/ConfirmDeliveryModal";
import ConfirmFaceServiceModal from "../modal/ConfirmFaceServiceModal";
import { POST_COLL_DEFAULT_DATE, POST_DEL_DEFAULT_DATE, POST_ORDER_TAB } from "../../modules/delivery/DeliveryModule";
import EncryptCardNum from "../../js/EncryptCardNum";
import PayModal from "../modal/PayModal";
import Swal from "sweetalert2";
import SeperatePhoneNum from "../../js/SeperatePhoneNum";
import CardName from "../../js/CardName";
import ScrollToTop from "../../js/ScrollToTop";
import { decrypt } from "../../js/encrypt";

function OrderComp() {

  const deliveryReducer = useSelector(state => state.deliveryReducer);
  const memberReducer = useSelector(state => state.memberReducer);
  const mem_seq = sessionStorage.getItem("mem_seq") ? decrypt(sessionStorage.getItem("mem_seq")) : "";
  const [memInfo, setMemInfo] = useState({});
  const [cateList, setCateList] = useState([]);
  const [cardInfo, setCardInfo] = useState([]);
  const [cSelectList, setCSelectList] = useState([]);
  const [dSelectList, setDSelectList] = useState([]);
  const [cancelModalStatus, setCancelModalStatus] = useState(false);
  const [confirmModalStatus, setConfirmModalStatus] = useState(false);
  const [payModalStatus, setPayModalStatus] = useState(false);
  const [checkInfo, setCheckInfo] = useState({
    face_yn: false,
    door_pw_checked: false,
    phone_checked: false,
    policy_checked: false,
    delCol_checked: true,
  });
  const [faceCheck, setFaceCheck] = useState(false);
  const [isAvailable1, setIsAvailable1] = useState(false);
  const [isAvailable2, setIsAvailable2] = useState(false);
  const [click, setClick] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const collTab = document.getElementById("collTab");
  const delTab = document.getElementById("delTab");
  
  const onTabClickHandler = e => {
    const prevActiveEl = document.querySelector("button.tab.active");

    if(prevActiveEl !== e.target) {
      prevActiveEl.classList.remove("active");
      e.target.classList.add("active");
      dispatch({ type: POST_ORDER_TAB, payload: !deliveryReducer.orderTab })
    }
  };

  const onCateClickHandler = e => {
    if(e.target.className.includes("active")) e.target.classList.remove("active");
    else e.target.classList.add("active");
    setClick(!click);
  };

  const onCheckHandler = e => {
    setCheckInfo({
      ...checkInfo,
      [e.target.name]: e.target.checked
    });
  };

  // const onFaceCheckHandler = () => {
  //   const checkbox = document.getElementsByName("face_yn")[0];

  //   if(checkbox.checked) {
  //     setFaceCheck(true);
  //   } else {
  //     setFaceCheck(false);
  //     setCheckInfo({
  //       ...checkInfo,
  //       face_yn: false
  //     })
  //   }
  // };

  const moveToAddrList = e => {
    let type = "";
    
    if(e.target.classList.contains("cType")) type = "C";
    else if(e.target.classList.contains("dType")) type = "D";

    navigate(`/mypage/addressList?type=${type}`);
  };

  const moveToPaymentList = () => {
    navigate("/mypage/paymentManage");
  };

  const selectDate = e => {
    if(e.target.classList.contains("cDate")) navigate("selectDate?type=C", { state: cSelectList });
    else if(e.target.classList.contains("dDate")) {
      if("N" === deliveryReducer.cabinetYn) navigate("selectDate?type=D&cabinet=N", { state: dSelectList });
      else navigate("selectDate?type=D", { state: dSelectList });
    }
  };

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const moveBack = () => {
    dispatch({ type: POST_ORDER_TAB, payload: false });
    navigate("/delivery");
  };

  const dateFormat = val => {
    const y = val.substring(0, 4);
    const m = val.substring(5, 7);
    const d = val.substring(8, 10);
    let day = "";
    const dayNum = new Date(val).getDay();

    if(1 === dayNum) {
      day = "(월)";
    } else if(2 === dayNum) {
      day = "(화)";
    } else if(3 === dayNum) {
      day = "(수)";
    } else if(4 === dayNum) {
      day = "(목)";
    } else if(5 === dayNum) {
      day = "(금)";
    } else if(6 === dayNum) {
      day = "(토)";
    } else if(0 === dayNum) {
      day = "(일)";
    }

    return `${y}. ${m}. ${d} ${day}`;
  };

  const checkDeadLine = val => {
    const now = new Date();
    const applyDate = new Date(val);
    applyDate.setDate(applyDate.getDate() - 1);
    applyDate.setHours(17);
    applyDate.setMinutes(0);
    applyDate.setSeconds(0);

    return now < applyDate;
  };

  const openCancelModal = () => {
    setCancelModalStatus(true);
  };

  const openConfirmModal = () => {
    if(!deliveryReducer.orderTab) {
      const cateActiveEl = document.querySelectorAll("button.buttonCategory.active");
      
      if(checkInfo.door_pw_checked && checkInfo.phone_checked && checkInfo.policy_checked && cateActiveEl.length) setConfirmModalStatus(true);
      else if(!checkInfo.door_pw_checked) {
        Swal.fire({
          text: "공동현관 비밀번호를 확인하셨는지 체크해주세요.",
          confirmButtonText: "확인",
          position: "center",
          confirmButtonColor: "#0064ff",
        })
        .then();
        return;
      } else if(!checkInfo.phone_checked) {
        Swal.fire({
          text: "휴대폰 연락에 동의해주세요.",
          confirmButtonText: "확인",
          position: "center",
          confirmButtonColor: "#0064ff",
        })
        .then();
        return;
      } else if(!checkInfo.policy_checked) {
        Swal.fire({
          text: "비상시 방침에 대해서 확인해주세요.",
          confirmButtonText: "확인",
          position: "center",
          confirmButtonColor: "#0064ff",
        })
        .then();
        return;
      } else if(!cateActiveEl.length) {
        Swal.fire({
          text: "수거하실 물품의 카테고리를 선택해주세요.",
          confirmButtonText: "확인",
          position: "center",
          confirmButtonColor: "#0064ff",
        })
        .then();
        return;
      } 
    } else {
      if(checkInfo.door_pw_checked && checkInfo.phone_checked && checkInfo.policy_checked && checkInfo.delCol_checked) setConfirmModalStatus(true);
      else if(!checkInfo.door_pw_checked) {
        Swal.fire({
          text: "공동현관 비밀번호를 확인하셨는지 체크해주세요.",
          confirmButtonText: "확인",
          position: "center",
          confirmButtonColor: "#0064ff",
        })
        .then();
        return;
      } else if(!checkInfo.phone_checked) {
        Swal.fire({
          text: "휴대폰 연락에 동의해주세요.",
          confirmButtonText: "확인",
          position: "center",
          confirmButtonColor: "#0064ff",
        })
        .then();
        return;
      } else if(!checkInfo.policy_checked) {
        Swal.fire({
          text: "비상시 방침에 대해서 확인해주세요.",
          confirmButtonText: "확인",
          position: "center",
          confirmButtonColor: "#0064ff",
        })
        .then();
        return;
      } else if(!checkInfo.delCol_checked) {
        Swal.fire({
          text: "배송 완료후 수거일 자동 신청사항을 체크해주세요.",
          confirmButtonText: "확인",
          position: "center",
          confirmButtonColor: "#0064ff",
        })
        .then();
        return;
      }
    }
  };

  if(collTab && "N" === deliveryReducer.cabinetYn) {
    collTab.disabled = true;
  } else if(collTab && delTab && "Y" === deliveryReducer.cabinetYn) {
    if("D" === deliveryReducer.recentHistory.type) {
      if("C2" === deliveryReducer.recentHistory.status || "E1" === deliveryReducer.recentHistory.status) {
        collTab.disabled = true;
        delTab.disabled = false;
      } else if("D1" === deliveryReducer.recentHistory.status) {
        collTab.disabled = false;
        delTab.disabled = true;
      }
    } else if("C" === deliveryReducer.recentHistory.type) {
      if("C2" === deliveryReducer.recentHistory.status || "E1" === deliveryReducer.recentHistory.status) {
        collTab.disabled = false;
        delTab.disabled = true;
      } else if("D1" === deliveryReducer.recentHistory.status) {
        collTab.disabled = true;
        delTab.disabled = false;
      }
    }
  } 

  useEffect(() => {
    ScrollToTop();

    if(Object.keys(deliveryReducer.recentHistory).length) {
      if("C" === deliveryReducer.recentHistory.type && ("C2" === deliveryReducer.recentHistory.status || "E1" === deliveryReducer.recentHistory.status)) dispatch({ type: POST_ORDER_TAB, payload: false });
      else if("C" === deliveryReducer.recentHistory.type && "D1" === deliveryReducer.recentHistory.status) dispatch({ type: POST_ORDER_TAB, payload: true });

      if("D" === deliveryReducer.recentHistory.type && ("C2" === deliveryReducer.recentHistory.status || "E1" === deliveryReducer.recentHistory.status)) dispatch({ type: POST_ORDER_TAB, payload: true });
      else if("Y" === deliveryReducer.cabinetYn && "D" === deliveryReducer.recentHistory.type && "D1" === deliveryReducer.recentHistory.status) dispatch({ type: POST_ORDER_TAB, payload: false });
    } else dispatch({ type: POST_ORDER_TAB, payload: true });

    if(!Object.keys(memInfo).length && mem_seq) {
      (async () => {
        setMemInfo(await getMemberInfoAPI(mem_seq));
      })();
    }

    if(!cateList.length) {
      (async () => {
        setCateList(await getCollectionCateListAPI());
      })();
    }

    if(!cardInfo.length && mem_seq) {
      (async () => {
        setCardInfo((await getMemberPaymentListAPI(mem_seq)).filter(item => "Y" === item.default_yn)[0]);
      })();
    }

    if(!cSelectList.length) {
      (async () => {
        setCSelectList(await getSelectableDateListAPI("C"));
      })();
    }

    if(!dSelectList.length) {
      (async () => {
        setDSelectList(await getSelectableDateListAPI("D"));
      })();
    }
  }, []);

  useEffect(() => {
    if(cSelectList.length) {
      const defaultDate = cSelectList.filter(item => checkDeadLine(item.date) && "OVER" !== item.result && "2024-08-23" !== item.date);
      
      if(defaultDate.length) dispatch({ type: POST_COLL_DEFAULT_DATE, payload: defaultDate[0].date });
    }
    if(dSelectList.length) {
      const defaultDate = dSelectList.filter(item => checkDeadLine(item.date) && "OVER" !== item.result && "2024-08-23" !== item.date);
      
      if(defaultDate.length) dispatch({ type: POST_DEL_DEFAULT_DATE, payload: defaultDate[0].date });
    }
  }, [cSelectList, dSelectList]);

  useEffect(() => {
    if(!deliveryReducer.orderTab) {
      if(document.querySelectorAll("button.buttonCategory.active").length && checkInfo.door_pw_checked && checkInfo.phone_checked && checkInfo.policy_checked) setIsAvailable1(true);
      else setIsAvailable1(false);
    } else {
      if(document.querySelectorAll("button.buttonCategory.active").length && checkInfo.door_pw_checked && checkInfo.phone_checked && checkInfo.policy_checked && checkInfo.delCol_checked) setIsAvailable1(true);
      else setIsAvailable1(false);
    }

    if(checkInfo.door_pw_checked && checkInfo.phone_checked && checkInfo.policy_checked && checkInfo.delCol_checked) setIsAvailable2(true);
    else setIsAvailable2(false);
  }, [checkInfo, click]);

  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>수거/배송 신청</h1>
        </div>
      </header>

      <PayModal status={payModalStatus} setStatus={setPayModalStatus} form={{}}/>
      <ConfirmFaceServiceModal tab={deliveryReducer.orderTab} status={faceCheck} setStatus={setFaceCheck} info={checkInfo} setInfo={setCheckInfo}/>
      <ConfirmDeliveryModal tab={deliveryReducer.orderTab} status={confirmModalStatus} setStatus={setConfirmModalStatus} face={checkInfo.face_yn} setPayModalStatus={setPayModalStatus}/>
      <CancelDeliveryServiceModal status={cancelModalStatus} setStatus={setCancelModalStatus}/>

      <div className="contents contents2">
        <div className="centerWrap">
          <div className="titleWrap2">
            <div className="title">수거/배송 신청</div>
            {
              memberReducer.time ?
              <div className="free">FREE {memberReducer.freeUsedCnt}/{memberReducer.time}회</div>
              :
              <></>
            }
            {
              memberReducer.time && memberReducer.time && memberReducer.time <= memberReducer.freeUsedCnt ?
              <div className="payMore">추가결제</div>
              :
              <></>
            }
          </div>

          <div className="tabs">
            <button id="collTab" tabIndex={0} className={deliveryReducer.orderTab ? "tab left" : "tab left active"} title="현재 선택중" onClick={onTabClickHandler}>수거 신청하기</button>
            <button id="delTab" tabIndex={0} className={deliveryReducer.orderTab ? "tab right active" : "tab right"} title="클릭시 선택" onClick={onTabClickHandler}>배송 신청하기</button>
          </div>

          {
            !deliveryReducer.orderTab ?
            <div className="tabArea active">
              <div className="boxD1">
                {
                  deliveryReducer.cSelectedDate ?
                  <>
                    <div className="txt1">다음 신청하신 수거날짜는</div>
                    <div className="txt2"><span>{dateFormat(deliveryReducer.cSelectedDate)}{/*09:30*/}</span> 입니다.</div>
                    <div className="txt3"><span>12시 전까지 문 앞에 캐비닛을 준비해 주세요.</span></div>
                  </>
                  :
                  <>
                    {
                      deliveryReducer.cDefaultDate ?
                      <>
                        <div className="txt1">현재 가장 빠른 수거일은</div>
                        <div className="txt2"><span>{dateFormat(deliveryReducer.cDefaultDate)}{/*09:30*/}</span> 이후입니다.</div>
                        <div className="txt3"><span>12시 전까지 문 앞에 캐비닛을 준비해 주세요.</span></div>
                        <button tabIndex={0} className="button1 cDate" onClick={selectDate}>날짜 변경</button>
                      </>
                      :
                      <div className="txt1">현재 신청 가능한 날이 없습니다.</div>
                    }
                  </>
                }
              </div>

              {/* <div className="dataWrap">
                <label className="brisakInput">
                  <input type="checkbox" name="face_yn" onChange={onFaceCheckHandler}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">대면 수거 서비스를 신청합니다.</div>
                  </div>
                </label>
              </div> */}

              <div className="boxD2">
                <div className="title">수거신청정보</div>
                <div className="row">
                  <div className="inside">
                    <div className="tag">기본수거지</div>
                    <div className="tag">EV</div>
                    <div className="tag">서비스 가능</div>
                  </div>
                </div>
                <div className="address">{memInfo.collection_addr1}<br/>{memInfo.collection_addr2}</div>
                <div className="phone"><span>긴급연락번호 : {memInfo.collection_phone ? SeperatePhoneNum(memInfo.collection_phone) : ""}</span></div>
                <div className="password">{`공동현관 비밀번호 (${memInfo.collection_door_pw ? memInfo.collection_door_pw : "없음"})`}</div>
                <button tabIndex={0} className="buttonD21 cType" onClick={moveToAddrList}>수정</button>
              </div>

              <div className="dataWrap">
                <label className="brisakInput">
                  <input type="checkbox" name="door_pw_checked" onChange={onCheckHandler}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">[필수] 공동현관 비밀번호를 입력했습니다.</div>
                  </div>
                </label>
              </div>

              <div className="dataWrap">
                <label className="brisakInput">
                  <input type="checkbox" name="phone_checked" onChange={onCheckHandler}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">[필수] 건물 또는 단지 내로 운송차량이 출입하기 위해, 필요할 경우 고객의 휴대폰으로 연락하는 것에 동의합니다.</div>
                  </div>
                </label>
              </div>

              <div className="dataWrap">
                <label className="brisakInput">
                  <input type="checkbox" name="policy_checked" onChange={onCheckHandler}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">[필수] 이용약관에 따라, 비상 시 관리자가 캐비닛을 열고 보관중인 물품을 확인하는 것에 동의합니다.</div>
                  </div>
                </label>
              </div>

              <div className="boxD3">
                <div className="title">수거 신청 물품 카테고리</div>
                <div className="txt1">STORR는 고객님이 맡기시는 상품에 따라 <br/>최적화 된, 보관 환경을 제공합니다.</div>
                <div className="buttons">
                  {
                    cateList.map(item => {
                      return (
                        <button key={item.seq} tabIndex={0} className="buttonCategory" onClick={onCateClickHandler} value={item.name}>{item.name}</button>
                      );
                    })
                  }
                </div>
              </div>

              <div className="bottomButtons">
                <button tabIndex={0} className="buttonCancel button1in2" onClick={openCancelModal}>신청취소</button>
                <button tabIndex={0} className={isAvailable1 && (deliveryReducer.cDefaultDate || deliveryReducer.cSelectedDate) ? "buttonDone button1in2 cApply" : "buttonDone button1in2 cApply disabledBtn"} onClick={openConfirmModal} disabled={isAvailable1 && (deliveryReducer.cDefaultDate || deliveryReducer.cSelectedDate) ? false : true}>신청하기</button>
              </div>
            </div>
            :
            <div className="tabArea active">
              <div className="boxD1">
                {
                  deliveryReducer.dSelectedDate ?
                  <>
                    <div className="txt1">다음 신청하신 배송날짜는</div>
                    <div className="txt2"><span>{dateFormat(deliveryReducer.dSelectedDate)}{/*09:30*/}</span> 입니다.</div>
                  </>
                  :
                  <>
                    {
                      deliveryReducer.dDefaultDate ?
                      <>
                        <div className="txt1">현재 가장 빠른 배송일은</div>
                        <div className="txt2"><span>{dateFormat(deliveryReducer.dDefaultDate)}{/*09:30*/}</span> 이후입니다.</div>
                        <button tabIndex={0} className="button1 dDate" onClick={selectDate}>날짜 변경</button>
                      </>
                      :
                      <div className="txt1">현재 신청 가능한 날이 없습니다.</div>
                    }
                  </>
                }
              </div>

              {/* <div className="dataWrap">
                <label className="brisakInput">
                  <input type="checkbox" name="face_yn" onChange={onFaceCheckHandler}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">대면 배송 서비스를 신청합니다.</div>
                  </div>
                </label>
              </div> */}

              <div className="boxD2">
                <div className="title">배송신청정보</div>
                <div className="row">
                  <div className="inside">
                    <div className="tag">기본배송지</div>
                    <div className="tag">EV</div>
                    <div className="tag">서비스 가능</div>
                  </div>
                </div>
                <div className="address">{memInfo.delivery_addr1}<br/>{memInfo.delivery_addr2}</div>
                <div className="phone"><span>긴급연락번호 : {memInfo.delivery_phone ? SeperatePhoneNum(memInfo.delivery_phone) : ""}</span></div>
                <div className="password">{`공동현관 비밀번호 (${memInfo.delivery_door_pw ? memInfo.delivery_door_pw : "없음"})`}</div>
                <button tabIndex={0} className="buttonD21 dType" onClick={moveToAddrList}>수정</button>
              </div>

              <div className="dataWrap">
                <label className="brisakInput">
                  <input type="checkbox" name="door_pw_checked" onChange={onCheckHandler}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">[필수] 공동현관 비밀번호를 입력했습니다.</div>
                  </div>
                </label>
              </div>

              <div className="dataWrap">
                <label className="brisakInput">
                  <input type="checkbox" name="phone_checked" onChange={onCheckHandler}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">[필수] 건물 또는 단지 내로 운송차량이 출입하기 위해, 필요할 경우 고객의 휴대폰으로 연락하는 것에 동의합니다.</div>
                  </div>
                </label>
              </div>

              <div className="dataWrap">
                <label className="brisakInput">
                  <input type="checkbox" name="policy_checked" onChange={onCheckHandler}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">[필수] 이용약관에 따라, 비상 시 관리자가 캐비닛을 열고 보관중인 물품을 확인하는 것에 동의합니다.</div>
                  </div>
                </label>
              </div>

              {/* <div className="dataWrap">
                <label className="brisakInput">
                  <input type="checkbox" name="delCol_checked" onChange={onCheckHandler}/>
                  <div className="wrap">
                    <div className="icon"></div>
                    <div className="txt">[필수] 배송 완료일로부터 3일 이후로 수거일이 자동 신청됩니다. 자동 지정된 수거일에 운송 횟수 또는 쿠폰이 차감되거나 추가 운송비가 결제되는 것에 동의합니다. 휴대폰 앱을 통해 수거일 날짜 변경이 가능합니다.</div>
                  </div>
                </label>
              </div> */}

              <div className="boxD4">
                <div className="title">결제 정보</div>
                <div className="payBox">
                  <div className="row">
                    <div className="date">기본결제수단</div>
                    <button tabIndex={0} className="buttonD4Edit" onClick={moveToPaymentList}>수정</button>
                  </div>
                  <div className="row">
                    <div className="label">
                      {CardName(cardInfo.card_code)}
                    </div>
                    {
                      <div className="number">{EncryptCardNum(cardInfo.card_num)}</div>
                    }
                  </div>
                </div>
              </div>

              {/* <div className="warnings">
                <div className="warning2"><div className="txt">안내문구안내문구안내문구안내문구안내문구안내문구안내문구안내문구안내문구안내문구</div></div>
                <div className="warning2"><div className="txt">안내문구안내문구안내문구안내문구안내문구안내문구안내문구안내문구안내문구안내문구</div></div>
                <div className="warning2"><div className="txt">안내문구안내문구안내문구안내문구안내문구안내문구안내문구안내문구안내문구안내문구</div></div>
              </div> */}

              <div className="bottomButtons">
                <button tabIndex={0} className={isAvailable2 && (deliveryReducer.dDefaultDate || deliveryReducer.dSelectedDate) ? "buttonDone dApply" : "buttonDone dApply disabledBtn"} onClick={openConfirmModal} disabled={isAvailable2 && (deliveryReducer.dDefaultDate || deliveryReducer.dSelectedDate) ? false : true}>배송 신청하기</button>
              </div>
            </div>
          }
        </div>
      </div>

      <button tabIndex={0} className="gotoTop" id="gotoTop" title="화면 위로" onClick={moveToTop}></button>
    </>
  );
}

export default OrderComp;