import { combineReducers } from "redux";
import deliveryReducer from "./delivery/DeliveryModule";
import memberReducer from "./member/MemberModule";
import payReducer from "./pay/PayModules";
import serviceReducer from "./member/ServiceModule";
import marketingReducer from "./member/MarketingModule";

const rootReducer = combineReducers({
  deliveryReducer,
  memberReducer,
  payReducer,
  serviceReducer,
  marketingReducer,
});

export default rootReducer;