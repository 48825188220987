import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function AppSetting() {

  const navigate = useNavigate();

  const moveBack = () => {
    navigate("/mypage");
  };

  useEffect(() => {
    ScrollToTop();
  }, []);

  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>앱 설정</h1>
        </div>
      </header>

      <div className="contents contents2">
        <div className="centerWrap">
          <img src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} className="appSettingLogo" alt="STORR"/>

          <div className="appSettingBox">
            <div className="row titleRow">
              <div className="appTitle">앱 정보</div>
            </div>

            <div className="row appSettingRow">
              <div className="appSettingLeft">앱 버전</div>
              <div className="appSettingRight">1.13.0</div>
            </div>

            <div className="appTxt1">최신버전 사용중</div>
          </div>

          <div className="appSettingBox">
            <div className="row titleRow">
              <div className="appTitle">혜택, 마케팅 알림</div>
            </div>

            <div className="row appSettingRow">
              <div className="appSettingLeft">앱 알림</div>
              <div className="appSettingRight">
                <label className="toggleButton">
                  <input type="checkbox"/>
                  <div className="toggleWrap">
                    <div className="buttonBody"></div>
                  </div>
                </label>
              </div>
            </div>

            <div className="appTxt1">
              스토르의 다양한 혜택, 소식을 알려드립니다. 알림을 꺼도 중요한 정보는 받을 수 있어요.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppSetting;