import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMemberPaymentInfoAPI, updateMemberPaymentInfoAPI } from "../../../apis/MemberAPICalls";
import CreateToken from "../../../js/CreateToken";
import { useDispatch, useSelector } from "react-redux";
import { POST_MEMBER_DEFAULT_PAYMENT_INFO } from "../../../modules/member/MemberModule";
import Swal from "sweetalert2";

function ModifyPaymentComp() {

  const urlParams = new URL(location.href).searchParams;
  const seq = urlParams.get("seq");

  const [data, setData] = useState({
    seq: seq ? seq : "",
    cardNum: "",
    expireM: "",
    expireY: "",
    card_pw: "",
    name: "",
    isDefault: "N",
    mem_seq: sessionStorage.getItem("mem_seq"),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const memberReducer = useSelector(state => state.memberReducer);

  const moveBack = () => {
    navigate("/mypage/paymentManage");
  };

  const onChangeHandler = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const onlyNums = e => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
  };

  const onNameHandler = e => {
    setData({
      ...data,
      name: e.target.value
    });
  };

  const onCheckHandler = e => {
    setData({
      ...data,
      [e.target.name]: e.target.checked ? "Y" : "N"
    });
  };

  const checkExpireLength = e => {
    if(2 < e.target.value.length) {
      e.target.value = e.target.value.substr(0, 2);
    }
  };

  const updatePayment = async () => {
    if(!data.cardNum || !data.card_pw || !data.expireM || !data.expireY || !data.name) {
      // alert("필수 정보들을 입력하세요.");
      Swal.fire({
        
        text: "필수 정보들을 입력하세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }

    const resCode = await updateMemberPaymentInfoAPI(data, CreateToken());

    if(1 !== resCode) alert("결제 수단 수정 실패");
    else {
      // alert("성공적으로 수정했습니다.");

      if(Object.keys(memberReducer.cardInfo).length && "Y" === data.isDefault) dispatch({ type: POST_MEMBER_DEFAULT_PAYMENT_INFO, payload: data });

      // navigate("/mypage/paymentManage");
      Swal.fire({
        
        text: "성공적으로 수정했습니다.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then(res => navigate("/mypage/paymentManage"));
      return;
    }
  };

  useEffect(() => {
    if(!seq) {
      // alert("존재하지 않는 정보입니다.");
      // navigate("/mypage/paymentManage");
      Swal.fire({
        
        text: "존재하지 않는 정보입니다.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then(res => navigate("/mypage/paymentManage"));
      return;
    } else {
      (async () => {
        const info = await getMemberPaymentInfoAPI(sessionStorage.getItem("mem_seq"), seq);

        if(Object.keys(info).length) {
          setData({
            ...data,
            cardNum: info.card_num,
            expireM: info.card_expiration_mm,
            expireY: info.card_expiration_yy,
            name: info.card_entry_name,
            isDefault: info.default_yn,
          });
        }
      })();
    }
  }, []);

  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>간편 결제수단 수정</h1>
        </div>
      </header>

      <div className="contents">
        <div className="centerWrap">
          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">카드번호</div>
          </div>
          <div className="dataWrap">
            <input name="cardNum" type="text" className="inputNormal" title="카드번호" onInput={onlyNums} onChange={onChangeHandler} value={data.cardNum} maxLength={16}/>
          </div>
          <div className="warning">
            <div className="txt">신용카드 번호를 정확히 입력하세요.</div>
          </div>

          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">유효기간</div>
          </div>
          <div className="dataWrap">
            <div className="inputStyleWrap">
            <input name="expireM" type="text" className="inputTrans" title="유효기간 월" placeholder="MM" onInput={checkExpireLength} onChange={onChangeHandler} value={data.expireM}/>
              <div className="inputCenterTxt">/</div>
              <input name="expireY" type="password" className="inputTrans" title="유효기간 년도" placeholder="YY" onInput={checkExpireLength} onChange={onChangeHandler} value={data.expireY}/>
            </div>
          </div>

          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">카드 비밀번호</div>
          </div>
          <div className="dataWrap">
            <div className="inputStyleWrap">
              <input name="card_pw" type="password" className="inputTrans" title="카드 비밀번호" placeholder="비밀번호 앞2자리" onInput={onlyNums} onChange={onChangeHandler} value={data.card_pw} maxLength={2}/>
              <div className="inputCenterTxt"></div>
              <input type="password" className="inputTrans"value="**" readOnly/>
            </div>
          </div>

          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">이름</div>
          </div>
          <div className="dataWrap">
            <input type="text" className="inputNormal" title="이름" onChange={onNameHandler} value={data.name}/>
          </div>

          <div className="dataWrap" style={{ marginTop: "32px" }}>
            <label className="brisakInput">
              <input name="isDefault" type="checkbox" onChange={onCheckHandler} checked={"Y" === data.isDefault ? true : false} disabled={"Y" === data.isDefault ? true : false}/>
              <div className="wrap">
                <div className="icon"></div>
                <div className="txt">기본 결제 수단으로 설정</div>
              </div>
            </label>
          </div>
        </div>
      </div>

      <div className="bottomButtons">
        <button tabIndex={0} className="buttonDone" onClick={updatePayment}>저장하기</button>
      </div>
    </>
  );
}

export default ModifyPaymentComp;