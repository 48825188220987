import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getServiceListAPI } from "../../apis/ServiceAPICalls";
import { TEST_IMAGE } from "../../port/Port";

function MainServiceList() {

  const navigate = useNavigate();
  const [serviceList, setServiceList] = useState([]);

  const moveToDetail = e => {
    const service_seq = e.currentTarget.getAttribute("value");
    const serviceInfo = serviceList.filter(item => item.seq === Number(service_seq));

    navigate(`/serviceDetail/${service_seq}`, { state: serviceInfo[0] });
  };

  useEffect(() => {
    // api 요청해서 service list 가져오기
    if(!serviceList.length) {
      (async () => {
        setServiceList(await getServiceListAPI());
      })();
    }
  }, []);

  return (
    <div className="services">
      {
        serviceList.map(item => {

          return (
            <a key={item.seq} onClick={moveToDetail} className="service" title="상세보기" value={item.seq}>
              {
                "Y" === item.is_best ?
                <div className="best">BEST</div>
                :
                <></>
              }
              <div className="col1">
                {/* 나중에 이미지 서버로 부터 받아온 이미지로 수정해야함 */}
                <img src={`${TEST_IMAGE}/goods/${item.filename_svr}`} className="imgItem" />
              </div>
              <div className="col2">
                <div className="txt1">{item.title}</div>
                <div className="txt2" dangerouslySetInnerHTML={{__html: item.summary}}></div>
                <br/>
                {0 !== item.discount_price && item.discount_price !== item.regular_price ? <div className="originalPrice">정상가격: 월 {Number(item.regular_price).toLocaleString()}원</div> : <br/>}
                <div className="row">
                  {0 !== item.discount_price && item.discount_price !== item.regular_price ? <div className="discount">{Math.round((item.discount_price/item.regular_price) * 100)}%<span className="hide">할인</span></div> : <></>}
                  <div className="price">월 {item.discount_price !== item.regular_price ? Number(item.regular_price - item.discount_price).toLocaleString() : item.regular_price.toLocaleString()}원 (4주)</div>
                </div>
                <div className="icon1"></div>
              </div>
            </a>
          );
        })
      }
    </div>
  );
}

export default MainServiceList;