import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from "./pages/login/Login";
import MainLayout from './layouts/MainLayout';
import Main from './pages/Main';
import Signup from './pages/login/Signup';
import SignupLayout from './layouts/login/SignupLayout';
import Payment from './pages/payment/Payment';
import Delivery from './pages/delivery/Delivery';
import Regist from './pages/delivery/address/Regist';
import MainIntroduction from './components/main/MainIntroduction';
import MainCabinetIntro from './components/main/MainCabinetIntro';

import "./css/storr.css";
import MainServiceDetail from './components/main/MainServiceDetail';
import Modify from './pages/delivery/address/Modify';
import Mypage from './pages/mypage/Mypage';
import AddressList from './pages/mypage/address/AddressList';
import MyInfo from './pages/mypage/myInfo/MyInfo';
import PaymentManage from './pages/mypage/payment/PaymentManage';
import RegistPayment from './pages/mypage/payment/RegistPayment';
import Notice from './pages/notice/Notice';
import NoticeDetailComp from './components/notice/NoticeDetailComp';
import Order from './pages/delivery/Order';
import SelectDate from './pages/delivery/SelectDate';
import History from './pages/history/History';
import PaymentHistory from './pages/history/PaymentHistory';
import DeliveryHistoryComp from './components/history/DeliveryHistoryComp';
import TerminateService from './pages/service/TerminateService';
import ChangeService from './pages/service/ChangeService';
import AddFriend from './pages/mypage/friend/AddFriend';
import FriendPointHistory from './pages/mypage/friend/FriendPointHistory';
import AppSetting from './pages/mypage/setting/AppSetting';
import Intro from './pages/intro/Intro';
import SnsLoginCheck from './pages/login/SnsLoginCheck';
import ModifyPaymentComp from './components/mypage/payment/ModifyPaymentComp';
import LoginStep2 from './pages/login/LoginStep2';
import StorrPolicy from './pages/service/StorrPolicy';
import StorrWithdraw from './pages/service/StorrWithdraw';
import MainServiceAvailable from './components/main/MainServiceAvailable';
import Faq from './pages/faq/Faq';
import FaqDetail from './pages/faq/FaqDetail';

// import "./css/swiper.css";

const protocol = window.location.protocol;
const host = window.location.host;

if("http:" === protocol) {
  if(!host.includes("localhost") && !host.includes("192.168.1.6")) location.href = "https://www.storrngo.kr";
} else if("https:" === protocol) {
  if(!host.includes("storrngo.kr")) location.href = "https://www.storrngo.kr";
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" /*element={ <MainLayout/> }*/>
          <Route index element={ <Main/> }/>
          <Route path="intro" element={ <Intro/> }/>
          <Route path="delivery">
            <Route index element={ <Delivery/> }/> 
            <Route path="registAddr" element={ <Regist/> }/>
            <Route path="modifyAddr" element={ <Modify/> }/>
            <Route path="order">
              <Route index element={ <Order/> }/>
              <Route path="selectDate" element={ <SelectDate/> }/>
            </Route>
          </Route>
          <Route path="payment" element={ <Payment/> }/>
          <Route path="introduction" element={ <MainIntroduction/> }/>
          <Route path="cabinetIntro" element={ <MainCabinetIntro/> }/>
          <Route path="serviceDetail/:seq" element={ <MainServiceDetail/> }/>
          <Route path="mypage">
            <Route index element={ <Mypage/> }/>
            <Route path="addressList" element={ <AddressList/> }/>
            <Route path="myInfo" element={ <MyInfo/> }/>
            <Route path="paymentManage">
              <Route index element={ <PaymentManage/> }/>
              <Route path="registPayment" element={ <RegistPayment/> }/>
              <Route path="modifyPayment" element={ <ModifyPaymentComp/> }/>
            </Route>
            <Route path="addFriend" element={ <AddFriend/> }/>
            <Route path="friendPointHistory" element={ <FriendPointHistory/> }/>
            <Route path="appSetting" element={ <AppSetting/> }/>
          </Route>
          <Route path="notice">
            <Route index element={ <Notice/> }/>
            <Route path="detail/:seq" element={ <NoticeDetailComp/> }/>
          </Route>
          <Route path="faq">
            <Route index element={ <Faq/> }/>
            <Route path="detail/:seq" element={ <FaqDetail/> }/>
          </Route>
          <Route path="history">
            <Route index element={ <History/> }/>
            <Route path="paymentHistory" element={ <PaymentHistory/> }/>
            <Route path="deliveryHistory" element={ <DeliveryHistoryComp/> }/>
          </Route>
          <Route path="terminateService" element={ <TerminateService/> }/>
          <Route path="changeService" element={ <ChangeService/> }/>
          <Route path="storrPolicy" element={ <StorrPolicy/> }/>
          <Route path="storrWithdraw" element={ <StorrWithdraw/> }/>
          <Route path="serviceAvailableRegion" element={ <MainServiceAvailable/> }/>
        </Route>
        <Route path="/login">
          <Route index element={ <Login/> }/>
          <Route path="step2" element={ <LoginStep2/> }/>
          <Route path=":snsId" element={ <SnsLoginCheck/> }/>
        </Route>
        <Route path="/join" element={ <SignupLayout/> }>
          <Route index element={ <Signup/> }/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
