import { useDispatch, useSelector } from "react-redux";
import EncryptCardNum from "../../js/EncryptCardNum";
import { postCollApplyAPI, postDelApplyAPI } from "../../apis/DeliveryAPICalls";
import { POST_ORDER_TAB } from "../../modules/delivery/DeliveryModule";
import { useState } from "react";
import CompleteApplyDeliveryModal from "./ConfirmApplyDeliveryModal";
import CardName from "../../js/CardName";
import { postTerminateServiceAPI } from "../../apis/MemberAPICalls";
import CreateToken from "../../js/CreateToken";
import { useNavigate } from "react-router-dom";

function PayModal({status, setStatus, form}) {

	const payReducer = useSelector(state => state.payReducer);
	const memberReducer = useSelector(state => state.memberReducer);
	const deliveryReducer = useSelector(state => state.deliveryReducer);
	const [modalStatus, setModalStatus] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const closeModal = () => {
		setStatus(false);
	};

	const confirm = () => {
		// 수거/배송 결제 및 추가결제 api 만드는중...
		// alert("결제 성공");

		// 결제 시도 후 받은 resultCode 값에 따라 다음 코드를 진행할지 
		// 멈출지 분기점 설정을 해줘야함.
		if(deliveryReducer.terminateDate && Object.keys(form).length) {
			(async () => {
				await postTerminateServiceAPI(form, CreateToken());
			})();
		}

		if("C" === payReducer.payData.type) {
			(async () => {
        const resCode = await postCollApplyAPI(payReducer.payData);

        if(1 === resCode) {
          // alert("성공적으로 신청했습니다.");
          dispatch({ type: POST_ORDER_TAB, payload: false });
					setModalStatus("C");
					closeModal();
          // navigate("/delivery");
        }
      })();
		} else if("D" === payReducer.payData.type) {
			(async () => {
        const resCode = await postDelApplyAPI(payReducer.payData);

        if(1 === resCode) {
          // alert("성공적으로 신청했습니다.");
          dispatch({ type: POST_ORDER_TAB, payload: false });
					closeModal();
					if(Object.keys(form).length) navigate("/delivery", {replace: true});
					else setModalStatus("D")
          // navigate("/delivery");
        }
      })();
		}
	};

	return (
		<>
			{
				Object.keys(form).length ?
				<></>
				:
				<CompleteApplyDeliveryModal status={modalStatus} setStatus={setModalStatus}/>
			}

			<div className={ status ? "modal smallModal active" : "modal smallModal" }>
        <div className="modalNoHeader">
          <div className="modalTxt1">
            <div className="payBox">
							<div className="row">
								<div className="payment default">기본</div>
							</div>
							<div className="row">
								<div className="label">
									{
										CardName(memberReducer.cardInfo.card_code)
									}
								</div>
								<div className="number">{EncryptCardNum(memberReducer.cardInfo.card_num)}</div>
							</div>
						</div>
						<div>결제금액 : {"N" === deliveryReducer.cabinetYn ? "0" : memberReducer.freeUsedCnt < memberReducer.time ? "0" : memberReducer.coupon > 0 ? "0" : "10,000"}원</div>
						<div className="txtB2">
              <div className="txt">&#8226; 등록된 신용카드로 4주(28일)에 한 번씩 월 구독요금이 자동 결제됩니다.</div>
              <div className="txt">&#8226; 필요 시, 등록된 신용카드로 추가 운송요금이 결제됩니다.</div>
							<div className="txt">&#8226; 서비스를 이용하기 위해서는 유효한 신용카드를 사전에 등록해야 합니다.</div>
            </div>
						{memberReducer.freeUsedCnt < memberReducer.time ? <div>* 월 기본수거/배송 횟수에서 차감됩니다.</div> : <></>}
          </div>
        </div>

        <div className="modalButtons">
          <div className="inside">
            <button tabIndex={0} className="buttonCancel button1in2" title="" onClick={closeModal}>취소</button>
            <button tabIndex={0} className="buttonDone button1in2" title="" onClick={confirm}>결제</button>
          </div>
        </div>
      </div>

      <button tabIndex={0} className={ status ? "dimm active" : "dimm" } title="닫기" onClick={closeModal}></button>
		</>
	);
}

export default PayModal;