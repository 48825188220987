import { encrypt, decrypt } from "./encrypt";

function CreateToken() {
  const mem_seq = sessionStorage.getItem("mem_seq") ? decrypt(sessionStorage.getItem("mem_seq")) : "";
  const time = String(Math.floor(new Date().getTime() / 1000));
  const token = encrypt(`${mem_seq},${time}`);

  return token;
}

export default CreateToken;