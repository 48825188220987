function PaymentPolicyContent2() {

  return (
    <>
      ■ 개인정보 수집 및 이용에 대한 동의<br />
      “나이스페이먼츠 주식회사”(이하 “회사”)는 이용자의 개인정보를 중요시하며, “정보통신망 이용촉진 및 정보보호 등에 관한 법률” 및 “개인정보보호법”을 준수하고 있습니다. 회사는 회사가 제공하는 결제대행서비스를 통해 결제하신 내역을 조회하기 원하는 이용자로부터 아래와 같이 개인정보를 수집하고 이용합니다.<br />
      <br />
      1. 개인정보의 수집 및 이용목적<br />
      회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.<br />
      <br />
      결제내역(신용카드, 계좌이체, 가상계좌, 휴대폰결제, 문화상품권) 조회 및 거래명세표 출력<br />
      서비스 제공 및 관련 업무처리에 필요한 동의 또는 철회 등<br />
      2. 수집하는 개인정보 항목 및 수집방법<br />
      1) 개인정보 수집 항목(결제내역 조회 목적)<br />
      신용카드: 카드종류(ISP/안심클릭), 결제정보, 카드번호, 결제일자, 기타정보(E-mail), 보안문자<br />
      계좌이체: 계좌번호 뒤 5자리, 구매자명, 결제일자, 결제금액, 보안문자<br />
      가상계좌: (가상)계좌번호, 결제일자, 결제금액, 보안문자<br />
      휴대폰결제: 휴대폰번호, 결제일자, 결제금액, 보안문자<br />
      문화상품권: 컬쳐랜드ID, 결제일자, 보안문자<br />
      2) 개인정보 수집방법<br />
      홈페이지(NICEPAY/allthegate)에서 회사가 제공하는 결제내역 조회 화면에서 이용자가 직접 제시한 정보를 수집<br />
      3. 개인정보의 보유 및 이용기간<br />
      이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기 합니다.<br />
      단, 전자금융거래법, 전자상거래 등에서의 소비자 보호에 관한 법률 등 관련 법령에 의하여 보존할 필요가 있는 경우 관련 법령에서 정한 일정한 기간 동안 개인정보를 보존합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.<br />

      <table>
      <thead>
        <tr>
          <td>보관하는 정보</td>
          <td>보존 근거</td>
          <td>보존기간</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>계약 또는 청약 철회 등에 관한 기록</td>
          <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
          <td>5년</td>
        </tr>
        <tr>
          <td>대금결제 및 재화 등의 공급에 관한 기록</td>
          <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
          <td>5년</td>
        </tr>
        <tr>
          <td>건당 1만원 초과 전자금융거래에 관한 기록</td>
          <td>전자금융거래법</td>
          <td>5년</td>
        </tr>
        <tr>
          <td>소비자의 불만 또는 분쟁 처리에 관한 기록</td>
          <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
          <td>3년</td>
        </tr>
        <tr>
          <td>신용정보의 수집/처리 및 이용 등에 관한 기록</td>
          <td>신용정보의 이용 및 보호에 관한 법률</td>
          <td>3년</td>
        </tr>
        <tr>
          <td>방문에 관한 기록</td>
          <td>통신비밀보호법</td>
          <td>3년</td>
        </tr>
        <tr>
          <td>건당 1만원 이하 전자금융거래에 관한 기록</td>
          <td>전자금융거래법</td>
          <td>1년</td>
        </tr>
        <tr>
          <td>본인확인에 관한 기록</td>
          <td>정보통신 이용촉진 및 정보보호 등에 관한 법률</td>
          <td>6개월</td>
        </tr>
      </tbody>
      </table>
      <br />
      4. 이용자의 개인정보의 수집 및 이용 거부<br />
      이용자는 회사의 개인정보 수집 및 이용 동의를 거부할 수 있습니다. 단, 동의를 거부하는 경우, 결제내역 조회가 정상적으로 완료될 수 없음을 알려드립니다.<br />
      <br />
      (※ 개인정보의 처리와 관련된 사항은 회사의 개인정보처리방침에 따릅니다.)<br />
    </>
  );
}

export default PaymentPolicyContent2;