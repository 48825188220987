import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { POST_COLL_SELECTED_DATE, POST_DEL_SELECTED_DATE, POST_ORDER_TAB, POST_TERMINATE_DATE } from "../../modules/delivery/DeliveryModule";
import { updateMemberStorageApplyDateAPI } from "../../apis/MemberAPICalls";
import CreateToken from "../../js/CreateToken";
import { getSelectableDateListAPI, getSelectableTerminateDateListAPI } from "../../apis/DeliveryAPICalls";
import Swal from "sweetalert2";
import ScrollToTop from "../../js/ScrollToTop";
import { decrypt } from "../../js/encrypt";

function SelectDateComp() {

  const urlParams = new URL(location.href).searchParams;
  const type = urlParams.get("type");
  const status = urlParams.get("status");
  const seq = urlParams.get("seq");
  const cabinet = urlParams.get("cabinet");
  const terminating = urlParams.get("terminating");
  const ed = urlParams.get("ed");
  // const memStatus = urlParams.get("memStatus");
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cSelected, setCSelected] = useState({
    s1: '',
  });
  const [dSelected, setDSelected] = useState({
    s1: '',
  });
  const [cSelectList, setCSelectList] = useState([]);
  const [dSelectList, setDSelectList] = useState([]);

  const moveBack = () => {
    if("N" === cabinet || "A1" === status) navigate("/delivery", {replace: true});
    else if("Y" === terminating) navigate(`/terminateService`, {replace: true});
    else navigate("/delivery/order", {replace: true});
  };

  const dateFormat = str => {
    const y = str.substring(0, 4);
    const m = str.substring(5, 7);
    const d = str.substring(8, 10);
    let day;
    const dayNum = new Date(str).getDay();

    if(1 === dayNum) {
      day = "(월)";
    } else if(2 === dayNum) {
      day = "(화)";
    } else if(3 === dayNum) {
      day = "(수)";
    } else if(4 === dayNum) {
      day = "(목)";
    } else if(5 === dayNum) {
      day = "(금)";
    } else if(6 === dayNum) {
      day = "(토)";
    } else if(0 === dayNum) {
      day = "(일)";
    }

    return `${y}년 ${m}월 ${d}일 ${day}`;
  };

  const onChangeHandler = e => {
    if("C" === type) {
      setCSelected({
        ...cSelected,
        [e.target.name]: e.target.value,
      });
    } else if("D" === type) {
      setDSelected({
        ...dSelected,
        [e.target.name]: e.target.value,
      });
    }
  };

  const checkDeadLine = val => {
    const comparison = new Date();
    const y = comparison.getFullYear();
    const m = String(comparison.getMonth() + 1).padStart(2, "0");
    const d = String(comparison.getDate()).padStart(2, "0");
    const str = `${y}-${m}-${d}`;
    
    const deadline = new Date(val);
    
    if(str === val) return "신청마감";
    else {

      deadline.setDate(deadline.getDate() - 1);

      const y2 = deadline.getFullYear();
      const m2 = String(deadline.getMonth() + 1).padStart(2, "0");
      const d2 = String(deadline.getDate()).padStart(2, "0");
      const str2 = `${y2}-${m2}-${d2}`;

      if(str === str2) {
        return comparison.getHours() < 15
      } else return true;
    }
  };

  const saveDate = () => {
    if("C" === type && !cSelected.s1) {
      Swal.fire({
        text: "변경할 날짜를 선택해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    } else if("D" === type && !dSelected.s1) {
      Swal.fire({
        text: "변경할 날짜를 선택해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    } else if(cSelected.s1 || dSelected.s1) {
      if("C" === type) {
        dispatch({ type: POST_COLL_SELECTED_DATE, payload: [cSelected.s1, false] })
        if(!status) navigate("/delivery/order");
        else {
          updateMemberStorageApplyDateAPI(cSelected.s1, decrypt(sessionStorage.getItem("mem_seq")), seq, CreateToken());
          
          navigate("/delivery");
        }
      }
      else if("D" === type) {
        dispatch({ type:POST_DEL_SELECTED_DATE, payload: [dSelected.s1, true] })
        if(!status) {
          if("Y" === terminating) {
            dispatch({ type: POST_TERMINATE_DATE, payload: dSelected.s1 });
            navigate(`/terminateService`);
          } else navigate("/delivery/order");
        } else {
          updateMemberStorageApplyDateAPI(dSelected.s1, decrypt(sessionStorage.getItem("mem_seq")), seq, CreateToken());
          
          if("Y" === terminating) {
            dispatch({ type: POST_TERMINATE_DATE, payload: dSelected.s1 });
            navigate(`/terminateService`);
          } else navigate("/delivery");
        }
      }
    }
  };

  useEffect(() => {
    ScrollToTop();

    if(!cSelectList.length && !state) {
      (async () => {
        setCSelectList(await getSelectableDateListAPI("C"));
      })();
    }

    if(!dSelectList.length && !state) {
      if("Y" === terminating) {
        (async () => {
          const res = await getSelectableTerminateDateListAPI("D");

          setDSelectList(res.filter(item => item.date <= ed && "OVER" !== item.result));
        })();
      } else {
        (async () => {
          setDSelectList(await getSelectableDateListAPI("D"));
        })();
      }
    }

    if("N" === cabinet) dispatch({ type: POST_ORDER_TAB, payload: true });
  }, []);
console.log(state);
  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>
            {
              cabinet ? "캐비닛 배송 신청" : "날짜 변경하기"
            }
          </h1>
        </div>
      </header>

      <div className="contents">
        <div className="centerWrap">
          <div className="txtB1">
            {
              cabinet && "N" === cabinet ? 
              <>최초로 캐비닛을 <span className="t1">배송 받을 날짜</span> :</>
              :
              <><span className="t1">새로운 날짜</span>를 선택해주세요.</>
            }
          </div>

          <div className="wrapB1">
            {
              state ?
              state.map(date => {
                
                return (
                  <div key={date.date} className={"OVER" === date.result || "신청마감" === checkDeadLine(date.date) || false === checkDeadLine(date.date) || "2024-08-23" === date.date ? "dataWrap disabledWrap" : "dataWrap"}>
                    <label className="brisakInput">
                      <input type="radio" name="s1" onChange={onChangeHandler} value={date.date} disabled={"OVER" === date.result || "신청마감" === checkDeadLine(date.date) || false === checkDeadLine(date.date) || "2024-08-23" === date.date ? true : false}/>
                      <div className="wrap">
                        <div className="icon"></div>
                        <div className="txt" style={{ fontSize: "20px" }}>{dateFormat(date.date)}</div>
                      </div>
                    </label>
                    <div className="sdStatus">
                      {
                        "OVER" === date.result || "신청마감" === checkDeadLine(date.date) || false === checkDeadLine(date.date) || "2024-08-23" === date.date ?
                        <>&nbsp;&nbsp;신청마감</>
                        :
                        ""
                      }
                    </div>
                  </div>
                );
              })
              :
              <>
                {
                  "C" === type && cSelectList && 
                  cSelectList.map(date => {
                    
                    return (
                      <div key={date.date} className={"OVER" === date.result || "신청마감" === checkDeadLine(date.date) || false === checkDeadLine(date.date) ? "dataWrap disabledWrap" : "dataWrap"}>
                        <label className="brisakInput">
                          <input type="radio" name="s1" onChange={onChangeHandler} value={date.date} disabled={"OVER" === date.result || "신청마감" === checkDeadLine(date.date) || false === checkDeadLine(date.date) ? true : false}/>
                          <div className="wrap">
                            <div className="icon"></div>
                            <div className="txt" style={{ fontSize: "20px" }}>{dateFormat(date.date)}</div>
                          </div>
                        </label>
                        <div className="sdStatus">
                          {
                            "OVER" === date.result || "신청마감" === checkDeadLine(date.date) || false === checkDeadLine(date.date) ?
                            <>&nbsp;&nbsp;신청마감</>
                            :
                            ""
                          }
                        </div>
                      </div>
                    );
                  })
                }
                {
                  "D" === type && dSelectList && 
                  dSelectList.map(date => {
                    return (
                      <div key={date.date} className={"OVER" === date.result || "신청마감" === checkDeadLine(date.date) || false === checkDeadLine(date.date) ? "dataWrap disabledWrap" : "dataWrap"}>
                        <label className="brisakInput">
                          <input type="radio" name="s1" onChange={onChangeHandler} value={date.date} disabled={"OVER" === date.result || "신청마감" === checkDeadLine(date.date) || false === checkDeadLine(date.date) ? true : false}/>
                          <div className="wrap">
                            <div className="icon"></div>
                            <div className="txt" style={{ fontSize: "20px" }}>{dateFormat(date.date)}</div>
                          </div>
                        </label>
                        <div className="sdStatus">
                          {
                            "OVER" === date.result || "신청마감" === checkDeadLine(date.date) || false === checkDeadLine(date.date) ?
                            <>&nbsp;&nbsp;신청마감</>
                            :
                            ""
                          }
                        </div>
                      </div>
                    );
                  })
                }
              </>
            }

            <div className="lineB1"></div>
            <div className="txtB2">
              <div className="txt">최초로 빈 캐비닛을 배송하는 비용은 스토르앤고가 부담합니다. 따라서, 고객에게 제공된 월 편도 횟수가 차감되거나 비용을 청구하지 않습니다.</div>
              <div className="txt">배송신청이 가능한 날짜를 선택해 주세요. 신청이 마감된 날짜는 선택이 어렵습니다.</div>
              <div className="txt">서비스 구독 시작일은 첫 캐비닛을 배송받은 날짜로 적용되어 다음 결제일에 반영됩니다.</div>
            </div>
            
            {/* <div className="warning"><div className="txt">주의사항: 고객의 물품을 안전하게 수거하기 위해, 캐비닛이 배송된 날짜로부터 7일 후로 수거신청 날짜가 자동으로 접수됩니다. 더 빠른 날짜에 수거를 원할 경우, 앱에서 수거일을 변경해 주세요.</div></div> */}
            
          </div>
        </div>
      </div>

      <div className="bottomButtons">
        <button tabIndex={0} className="buttonDone" onClick={saveDate}>저장하기</button>
      </div>
    </>
  );
}

export default SelectDateComp;