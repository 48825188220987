import { useState } from "react";
import { useNavigate } from "react-router-dom";

function DeliveryHistory({memInfo, applyList}) {

  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  let cHistory = [];
  let dHistory = [];

  if(applyList.length) {
    cHistory = applyList.filter(item => "C" === item.type).slice(0, 5);
    dHistory = applyList.filter(item => "D" === item.type).slice(0, 5);
  }

  const clickHistory = e => {
    let arr = document.getElementsByClassName("tab");

    for(let i = 0; i < arr.length; i++) {
      arr[i].classList.remove("active");
    }

    e.target.classList.add("active");

    if(e.target.classList.contains("cBtn")) setStep(1);
    else setStep(2);
  };

  const moveToMypage = () => {
    navigate("/mypage");
  };

  const dateFormat = val => {
    const y = val.substring(0, 4);
    const m = val.substring(5, 7);
    const d = val.substring(8, 10);

    return `${y}. ${m}. ${d}`;
  };

  return (
    <>
      <div className="tabs">
        <button tabIndex={0} className="tab cBtn active" title="현재 선택중" onClick={clickHistory}>수거 신청 내역</button>
        <button tabIndex={0} className="tab dBtn" title="클릭시 선택" onClick={clickHistory}>배송 신청 내역</button>
      </div>

      <div className="tabArea active">
        {
          1 === step ?
          <>
            <div className="box2">
              <div className="boxTxt" style={{ textAlign: "left" }}>
                <span className="bold">수거 신청</span>은 고객님의 주소지에서 캐비닛을 수거한 후, <span className="bold">스토르앤고 전용 보관센터</span>에 입고해 보관하는 절차입니다.
              </div>
            </div>

            <div className="blueBox">
              {
                Object.keys(memInfo).length && "Y" === memInfo.collection_default_yn ?
                <>
                  {/* 기본 수거지로 등록된 주소 정보를 호출 */}
                  <div className="address">{memInfo.collection_addr1}<br/>{memInfo.collection_addr2}</div>
                  <button onClick={moveToMypage} tabIndex={0} className="buttonAddress cModify">주소 변경</button>
                </>
                :
                <>
                  <div className="address">수거지 정보를 등록하시겠습니까?</div>
                  <button onClick={moveToMypage} tabIndex={0} className="buttonAddress cAdd">주소등록</button>
                </>
              }
            </div>
          </>
          :
          <>
            <div className="box2">
              <div className="boxTxt" style={{ textAlign: "left" }}>
              <span className="bold">배송 신청</span>은 스토르앤고 전용 보관센터에서 출고한 캐비닛을 <span className="bold">고객님의 주소지로 배송</span>하는 절차입니다.
              </div>
            </div>

            <div className="blueBox">
              {
                Object.keys(memInfo).length && "Y" === memInfo.delivery_default_yn ?
                <>
                  <div className="address">{memInfo.delivery_addr1}<br/>{memInfo.delivery_addr2}</div>
                  <button onClick={moveToMypage} tabIndex={0} className="buttonAddress dModify">주소 변경</button>
                </>
                :
                <>
                  <div className="address">배송지 정보를 등록하시겠습니까?</div>
                  <button onClick={moveToMypage} tabIndex={0} className="buttonAddress dAdd">주소등록</button>
                </>
              }
            </div>
          </>
        }
        
        <div className="histories">
          {
            1 === step ?
            <>
              {
                cHistory.length ?
                cHistory.map(item => {
                  return (
                    <div key={item.seq} className="history">
                      <div className="row">
                        <div className="date">{dateFormat(item.regdate)}</div>
                        {/* 나중에 무료로 신청했는지 결제했는지 데이터 가져와야함 */}
                        <div className="payment">
                          {
                            ("F1" === item.free_yn || "F2" === item.free_yn) ? "무료" : "F3" === item.free_yn ? "쿠폰" : "추가결제"
                          }
                        </div>
                      </div>
                      <div className="row">
                        <div className="status">
                          {
                            ("A1" === item.status || "B1" === item.status) && "수거 신청"
                          }
                          {
                            "C2" === item.status && "수거 취소"
                          }
                          {
                            "D1" === item.status && "수거 완료"
                          }
                          {
                            "E1" === item.status && "수거 실패"
                          }
                        </div>
                        <div className="amount free">
                          {
                            "F2" === item.free_yn && "월 기본수거"
                          }
                          {
                            "F3" === item.free_yn && "쿠폰 사용"
                          }
                          {
                            "P1" === item.free_yn && "10,000원"
                          }
                        </div>
                      </div>
                    </div>
                  );
                })
                :
                <div className="noList">
                  {/* <img src={`${process.env.PUBLIC_URL}/assets/images/nolist2.png`} alt=""/> */}
                  <div className="txt">신청 내역이 없습니다.</div>
                </div>
              }
            </>
            :
            <>
              {
                dHistory.length ?
                dHistory.map(item => {
                  return (
                    <div key={item.seq} className="history">
                      <div className="row">
                        <div className="date">{dateFormat(item.regdate)}</div>
                        {/* 나중에 무료로 신청했는지 결제했는지 데이터 가져와야함 */}
                        <div className="payment">
                          {
                            ("F1" === item.free_yn || "F2" === item.free_yn) ? "무료" : "F3" === item.free_yn ? "쿠폰" : "추가결제"
                          }
                        </div>
                      </div>
                      <div className="row">
                        <div className="status">
                          {
                            ("A1" === item.status || "B1" === item.status) && "배송 신청"
                          }
                          {
                            "C2" === item.status && "배송 취소"
                          }
                          {
                            "D1" === item.status && "배송 완료"
                          }
                          {
                            "E1" === item.status && "배송 실패"
                          }
                        </div>
                        <div className="amount free">
                          {
                            "F1" === item.free_yn && "첫 캐비닛 배송"
                          }
                          {
                            "F2" === item.free_yn && "월 기본배송"
                          }
                          {
                            "F3" === item.free_yn && "쿠폰 사용"
                          }
                          {
                            "P1" === item.free_yn && "10,000원"
                          }
                        </div>
                      </div>
                    </div>
                  );
                })
                :
                <div className="noList">
                  {/* <img src={`${process.env.PUBLIC_URL}/assets/images/nolist2.png`} alt=""/> */}
                  <div className="txt">신청 내역이 없습니다.</div>
                </div>
              }
            </>
          }
        </div>
      </div>
    </>
  );
}

export default DeliveryHistory;