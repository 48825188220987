import Swal from "sweetalert2";
import { changeDefaultPaymentAPI } from "../../apis/MemberAPICalls";
import CreateToken from "../../js/CreateToken";
import { decrypt } from "../../js/encrypt";

function PaymentDefaultChangeModal({ seq, setSeq, status, setStatus }) {

  const mem_seq = sessionStorage.getItem("mem_seq") ? decrypt(sessionStorage.getItem("mem_seq")) : "";
  
  const closeModal = () => {
    setStatus(false);
    setSeq(0);
  };

  const changeDefaultPayment = () => {
    (async () => {
      const resCode = await changeDefaultPaymentAPI(mem_seq, seq, CreateToken());

      if(1 === resCode) {
        setStatus(false);

        location.replace(location.href);
      } else {
        // alert("기본결제수단 변경 실패");
        Swal.fire({
          
          text: "기본결제수단 변경 실패",
          confirmButtonText: "확인",
          position: "center",
        confirmButtonColor: "#0064ff",
        })
        .then();
        return;
      }
    })();
  };

  return (
    <>
      <div className={ status ? "modal smallModal active" : "modal smallModal" }>
        <div className="modalNoHeader">
          <div className="modalTxt1">
            선택하신 결제수단을 <span className="bold">기본결제수단</span>으로<br/>
            등록하시겠습니까?
          </div>
        </div>

        <div className="modalButtons">
          <div className="inside">
            <button tabIndex={0} className="buttonCancel button1in2" title="" onClick={closeModal}>취소</button>
            <button tabIndex={0} className="buttonDone button1in2" title="" onClick={changeDefaultPayment}>등록</button>
          </div>
        </div>
      </div>

      <button tabIndex={0} className={ status ? "dimm active" : "dimm" } title="닫기" onClick={closeModal}></button>
    </>
  );
}

export default PaymentDefaultChangeModal;