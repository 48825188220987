import { useNavigate } from "react-router-dom";

function SignupHeader() {

  const navigate = useNavigate();
  
  const moveBack = () => {
    navigate("/login");
  };

  return (
    <>
      <div className="topBigLogo">
        <div className="centerWrap">
        <img src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} className="joinLogo" alt="STORR"/>
        </div>
      </div>

      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>스토르앤고 회원가입</h1>

          {/* <div className="appicon"></div> */}
        </div>
      </header>
    </>
  );
}

export default SignupHeader;