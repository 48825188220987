import { useEffect, useState } from "react";
import MypageHeader from "./MypageHeader";
import { getMemberInfoAPI, getMemberPurchasedServiceListAPI } from "../../apis/MemberAPICalls";
import { useNavigate } from "react-router-dom";
import MainMenu from "../main/MainMenu";
import ScrollToTop from "../../js/ScrollToTop";
import { decrypt } from "../../js/encrypt";
import MemberWithdrawModal from "../modal/MemberWithdrawModal";
import Swal from "sweetalert2";

function MypageComp() {

  const [memInfo, setMemInfo] = useState({});
  const mem_seq = sessionStorage.getItem("mem_seq") ? decrypt(sessionStorage.getItem("mem_seq")) : "";
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(mem_seq ? true : false);
  const [purchasedInfo, setPurchasedInfo] = useState({});
  const [modalStatus, setModalStatus] = useState(false);

  const dateFormat = val => {
    const y = val.substring(0, 4);
    const m = val.substring(5, 7);
    const d = val.substring(8, 10);

    return `${y}. ${m}. ${d}`;
  };

  const moveToLogin = () => {
    navigate("/login", {replace: true});
  };

  const moveToAddrList = e => {
    if(e.target.classList.contains("cAddr")) navigate("addressList?type=C");
    else if(e.target.classList.contains("dAddr")) navigate("addressList?type=D");
  };

  const moveToMyInfo = () => {
    navigate("myInfo", { state: memInfo });
  };

  const moveToPaymentManage = () => {
    navigate("paymentManage");
  };

  const moveToNotice = () => {
    navigate("/notice");
  };

  const moveToFaq = () => {
    navigate("/faq")
  }

  const moveToAddFriend = () => {
    navigate("addFriend", { state: memInfo });
  };

  const moveToAppSetting = () => {
    navigate("appSetting");
  };

  const logout = () => {
    sessionStorage.clear();
    localStorage.removeItem("mem_seq");
    setIsLogin(false);
  };

  const checkAutoPayDate = val => {
    const now = new Date();
    const edDate = new Date(val);
    edDate.setDate(edDate.getDate() + 2);
    edDate.setHours(0);
    edDate.setMinutes(0);
    edDate.setSeconds(0);

    return now < edDate;
  };

  const withdraw = () => {
    if(Object.keys(purchasedInfo).length && !("N" === purchasedInfo.payment_yn || ("Y" === purchasedInfo.payment_yn && "Y" === purchasedInfo.refund_yn) || ("Y" === purchasedInfo.payment_yn && !checkAutoPayDate(purchasedInfo.service_date_ed)))) {
      // console.log("no withdraw");
      Swal.fire({
        text: "현재 이용중인 서비스가 있습니다. 서비스 구독해제 후 회원탈퇴가 가능합니다.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      });
      return;
    }

    setModalStatus(true);
  };

  useEffect(() => {
    ScrollToTop();
    // if("0" === sessionStorage.getItem("mem_seq")) {
    //   sessionStorage.removeItem("mem_seq");
    //   navigate("/login");
    // }
    if(!sessionStorage.getItem("mem_seq")) navigate("/login");

    if(!Object.keys(memInfo).length && mem_seq) {
      (async () => {
        setMemInfo(await getMemberInfoAPI(mem_seq));
      })();
    }
    if(!Object.keys(purchasedInfo).length && mem_seq) {
      (async () => {
        const res = await getMemberPurchasedServiceListAPI(mem_seq);
        setPurchasedInfo(res && res.length ? res[0] : {});
      })();
    }
  }, []);

  return (
    <>
      <MypageHeader/>

      <MemberWithdrawModal status={modalStatus} setStatus={setModalStatus}/>

      <div className="contents deliveryContents">
        <div className="centerWrap">
          <div className="titleWrap2">
            <div className="title">{sessionStorage.getItem("mem_seq") && "0" !== decrypt(sessionStorage.getItem("mem_seq")) ? `${memInfo.name}님의 정보` : "마이페이지"}</div>
            {
              sessionStorage.getItem("mem_seq") && "0" !== decrypt(sessionStorage.getItem("mem_seq")) ?
              <button tabIndex={0} className="buttonMypageEdit" onClick={moveToMyInfo}>수정</button>
              :
              <></>
            }
          </div>

          <div className="mypageBox1">
            {
              sessionStorage.getItem("mem_seq") && "0" !== decrypt(sessionStorage.getItem("mem_seq")) ?
              <>
                <div className="row">
                  <div className="label">이름</div>
                  <div className="data">{memInfo.name}</div>
                </div>
                <div className="row">
                  <div className="label">전화번호</div>
                  <div className="data">{memInfo.phone}</div>
                </div>
                <div className="row">
                  <div className="label">최근 가입일</div>
                  <div className="data">{Object.keys(memInfo).length && dateFormat(memInfo.regdate)}</div>
                </div>
                {/* <div className="row">
                  <div className="label">연결계정</div>
                  <div className="data">
                    <div tabIndex={0} className="snsIcon">
                      {
                        memInfo.sns_id && memInfo.sns_id.includes("NAVER") && "네이버"
                      }
                      {
                        memInfo.sns_id && memInfo.sns_id.includes("KAKAO") && "카카오"
                      }
                      {
                        memInfo.sns_id && memInfo.sns_id.includes("GOOGLE") && "구글"
                      }
                      {
                        memInfo.sns_id && memInfo.sns_id.includes("FACEBOOK") && "페이스북"
                      }
                      {
                        memInfo.sns_id && memInfo.sns_id.includes("APPLE") && " 애플"
                      }
                    </div>  
                  </div>
                </div> */}
              </>
              :
              <>
                <div className="mpTxt">
                  <span className="bold">회원가입</span>하시고<br/>
                  <span className="bold">프리미엄 서비스</span>를 이용해 보세요.
                </div>
                <button onClick={moveToLogin} tabIndex={0} className="gotoLogin">로그인/회원가입</button>
              </>
            }
          </div>

          {
            sessionStorage.getItem("mem_seq") && "0" !== decrypt(sessionStorage.getItem("mem_seq")) ?
            <>
              <div className="boxD2">
                <div className="title" style={{ textDecoration: "underline" }}>기본 수거지</div>
                <div className="notice">아래 주소에서 수거한 캐비닛을 보관센터로 보냅니다.</div>
                {
                  memInfo.collection_addr1 ?
                  <>
                    <div className="address">{memInfo.collection_addr1}<br/>{memInfo.collection_addr2}</div>
                    <div className="password">{`공동현관 비밀번호 (${memInfo.collection_door_pw ? memInfo.collection_door_pw : "없음"})`}</div>
                    {/* <div className="phone">{SeperatePhoneNum(memInfo.phone)}</div> */}
                    <button onClick={moveToAddrList} tabIndex={0} className="cAddr buttonD21">수정</button>
                  </>
                  :
                  <>
                    <div style={{ color: "white", margin: "14px 0 0"}}>등록된 기본 수거지가 없습니다.</div>
                    <button onClick={moveToAddrList} tabIndex={0} className="cAddr buttonD21">등록</button>
                  </>
                }
              </div>

              <div className="boxD2">
                <div className="title" style={{ textDecoration: "underline" }}>기본 배송지</div>
                <div className="notice">캐비닛을 보관센터에서 아래 주소지로 보냅니다.</div>
                {
                  memInfo.delivery_addr1 ?
                  <>
                    <div className="address">{memInfo.delivery_addr1}<br/>{memInfo.delivery_addr2}</div>
                    <div className="password">{`공동현관 비밀번호 (${memInfo.delivery_door_pw ? memInfo.delivery_door_pw : "없음"})`}</div>
                    {/* <div className="phone">{SeperatePhoneNum(memInfo.phone)}</div> */}
                    <button onClick={moveToAddrList} tabIndex={0} className="dAddr buttonD21">수정</button>
                  </>
                  :
                  <>
                    <div style={{ color: "white", margin: "14px 0 0"}}>등록된 기본 배송지가 없습니다.</div>
                    <button onClick={moveToAddrList} tabIndex={0} className="dAddr buttonD21">등록</button>
                  </>
                }
              </div>

              <div className="myPageEtcs">
                <button tabIndex={0} className="myPageEtc" onClick={moveToPaymentManage}>
                  <div className="label">결제 신용카드 관리</div>
                </button>
                <button tabIndex={0} className="myPageEtc" onClick={moveToAddFriend}>
                  <div className="label">친구 초대하기</div>
                  <div className="friendEvent">지금 <span className="bold">5,000 포인트</span> 받기</div>
                </button>
                {/* <button tabIndex={0} className="myPageEtc" onClick={moveToAppSetting}>
                  <div className="label">앱설정</div>
                </button> */}
                <button tabIndex={0} className="myPageEtc" onClick={moveToNotice}>
                  <div className="label">공지사항</div>
                </button>
                <button tabIndex={0} className="myPageEtc" onClick={moveToFaq}>
                  <div className="label">FAQ</div>
                </button>
                <button onClick={logout} tabIndex={0} className="myPageEtc">
                  <div className="label">로그아웃</div>
                </button>
                {/* {
                  !Object.keys(purchasedInfo).length ?
                  <div className="row">
                    <button className="buttonCancelPayment" onClick={withdraw}>회원탈퇴</button>
                  </div>
                  :
                  Object.keys(purchasedInfo).length && ("N" === purchasedInfo.payment_yn || ("Y" === purchasedInfo.payment_yn && "Y" === purchasedInfo.refund_yn) || ("Y" === purchasedInfo.payment_yn && !checkAutoPayDate(purchasedInfo.service_date_ed))) ?
                  <div className="row">
                    <button className="buttonCancelPayment" onClick={withdraw}>회원탈퇴</button>
                  </div>
                  :
                  <></>
                } */}
                <button className="buttonCancelPayment" onClick={withdraw}>회원탈퇴</button>
              </div>
            </>
            :
            <>
              <div className="myPageEtcs">
                <button tabIndex={0} className="myPageEtc" onClick={moveToPaymentManage}>
                  <div className="label">결제 신용카드 관리</div>
                </button>
                <button tabIndex={0} className="myPageEtc" onClick={moveToAddFriend}>
                  <div className="label">친구 초대하기</div>
                  <div className="friendEvent">지금 <span className="bold">5,000 포인트</span> 받기</div>
                </button>
                <button tabIndex={0} className="myPageEtc" onClick={moveToNotice}>
                  <div className="label">공지사항</div>
                </button>
                <button tabIndex={0} className="myPageEtc" onClick={moveToFaq}>
                  <div className="label">FAQ</div>
                </button>
              </div>
            </>
          }
        </div>
      </div>

      <MainMenu/>
    </>
  );
}

export default MypageComp;