import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from 'swiper/modules';
import "../../css/swiper.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { POST_MEMBER_VOTE_INFO } from "../../modules/member/MemberModule";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ScrollToTop from "../../js/ScrollToTop";

function Intro() {

  const [vote, setVote] = useState({
    vote1: 0,
    vote2: 0,
    vote3: 0,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClickHandler = e => {
    if(e.target.className.includes("first")) {
      const prev = document.getElementsByClassName("active1")[0];

      if(prev) prev.classList.remove("active1");
      e.target.classList.add("active1");
    } else if(e.target.className.includes("second")) {
      const prev = document.getElementsByClassName("active2")[0];

      if(prev) prev.classList.remove("active2");
      e.target.classList.add("active2");
    } else if(e.target.className.includes("third")) {
      const prev = document.getElementsByClassName("active3")[0];

      if(prev) prev.classList.remove("active3");
      e.target.classList.add("active3");
    }

    setVote({
      ...vote,
      [e.target.name]: e.target.getAttribute("value")
    });
  };

  useEffect(() => {
    ScrollToTop();
  }, []);

  useEffect(() => {
    if(vote.vote1 && vote.vote2 && vote.vote3) {
      // if(confirm("설문조사 답변을 저장하시겠습니까?")) {
      //   dispatch({ type: POST_MEMBER_VOTE_INFO, payload: vote });
      //   navigate("/");
      // } else {
      //   const prev = document.getElementsByClassName("active3")[0];

      //   if(prev) prev.classList.remove("active3");

      //   setVote({
      //     ...vote,
      //     vote3: "",
      //   });
      // }
      Swal.fire({
        text: "설문조사 답변을 저장하시겠습니까?",
        confirmButtonText: "저장하기",
        showCancelButton: true,
        cancelButtonText: "취소",
        cancelButtonColor: "black",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then(res => {
        if(res.isConfirmed) {
          dispatch({ type: POST_MEMBER_VOTE_INFO, payload: vote });
          navigate("/");
        } else {
          const prev = document.getElementsByClassName("active3")[0];

          if(prev) prev.classList.remove("active3");

          setVote({
            ...vote,
            vote3: "",
          });
        }
      })
      .catch(err => console.error(err));
    }
  }, [vote]);

  return (
    <div className="contents introContents">
      <div className="centerWrap">
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={0}
          slidesPerView={1}
          centeredSlides
          pagination={{
            el: ".pageIntro"
          }}
          navigation={{
            nextEl: ".introImg"
          }}
          className="introSwiper"
        >
          <div className="row">
            <a href="/" className="skipIntro">건너뛰기</a>
          </div>

          <div className="swiper-wrapper">
            <SwiperSlide className="swiper-slide">
              <div className="introTxt1">
                맡기려고 하는 <span className="bold">물건의<br/>종류</span>는 무엇인가요?
              </div>

              <div className="introImgs">
                <img src={`${process.env.PUBLIC_URL}/assets/images/sample002.png`} name="vote1" className="introImg first" value={1} alt="" onClick={onClickHandler}/>
                <img src={`${process.env.PUBLIC_URL}/assets/images/sample002.png`} name="vote1" className="introImg first" value={2} alt="" onClick={onClickHandler}/>
                <img src={`${process.env.PUBLIC_URL}/assets/images/sample002.png`} name="vote1" className="introImg first" value={3} alt="" onClick={onClickHandler}/>
                <img src={`${process.env.PUBLIC_URL}/assets/images/sample002.png`} name="vote1" className="introImg first" value={4} alt="" onClick={onClickHandler}/>
              </div>
            </SwiperSlide>

            <SwiperSlide className="swiper-slide">
              <div className="introTxt1">
                예상하는 <span className="bold">보관 기간</span>은<br/>얼마나 되나요?
              </div>

              <div className="introImgs">
                <img src={`${process.env.PUBLIC_URL}/assets/images/sample002.png`} name="vote2" className="introImg second" value={1} alt="" onClick={onClickHandler}/>
                <img src={`${process.env.PUBLIC_URL}/assets/images/sample002.png`} name="vote2" className="introImg second" value={2} alt="" onClick={onClickHandler}/>
                <img src={`${process.env.PUBLIC_URL}/assets/images/sample002.png`} name="vote2" className="introImg second" value={3} alt="" onClick={onClickHandler}/>
                <img src={`${process.env.PUBLIC_URL}/assets/images/sample002.png`} name="vote2" className="introImg second" value={4} alt="" onClick={onClickHandler}/>
              </div>
            </SwiperSlide>

            <SwiperSlide className="swiper-slide">
              <div className="introTxt1">
                예상 <span className="bold">수거/배송 횟수</span>는?<br/><br/>
              </div>

              <div className="introImgs">
                <img src={`${process.env.PUBLIC_URL}/assets/images/sample002.png`} name="vote3" className="introImg third" value={1} alt="" onClick={onClickHandler}/>
                <img src={`${process.env.PUBLIC_URL}/assets/images/sample002.png`} name="vote3" className="introImg third" value={2} alt="" onClick={onClickHandler}/>
                <img src={`${process.env.PUBLIC_URL}/assets/images/sample002.png`} name="vote3" className="introImg third" value={3} alt="" onClick={onClickHandler}/>
                <img src={`${process.env.PUBLIC_URL}/assets/images/sample002.png`} name="vote3" className="introImg third" value={4} alt="" onClick={onClickHandler}/>
              </div>
            </SwiperSlide>
          </div>

          <div className="pageIntro"></div>
        </Swiper>

        <div className="introTxt2">완료 시, <span className="bold">1,000 포인트</span>를 드립니다.</div>
      </div>
    </div>
  );
}

export default Intro;