import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../js/ScrollToTop";

function MainIntroduction() {

  const navigate = useNavigate();

  const moveBack = () => {
    navigate("/");
  };

  const moveToLogin = () => {
    if(localStorage.getItem("mem_seq") || sessionStorage.getItem("mem_seq")) navigate("/");
    else navigate("/login");
  };

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    ScrollToTop();
  }, []);

  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>서비스 이용안내</h1>
          <div className="appicon"></div>
        </div>
      </header>

      <div className="contents">
        <div className="centerWrap">
          <div className="cbTxt0 introTxt" style={{ background: "none", padding: "0 20px" }}>
            <span className="bold">스토르앤고</span>는 가장 혁신적인 <span className="bold">도어투도어 (D2D) 배송형 짐보관 서비스</span>입니다.<br/><br/>
            넓고 쾌적한 공간을 원하는 도시인들에게 새로운 라이프스타일을 제안합니다.
          </div>

          <div className="cBox">
            <div className="cbTxt1">STEP 1</div>
            <div className="cbTxt2">회원 가입 및 <span className="underline">서비스 신청</span>하기</div>
            <div className="cbTxt3">
              앱 회원 가입 및 구독 서비스 선택<br/>
              배송형 짐보관 서비스 신청
            </div>
            <img src={`${process.env.PUBLIC_URL}/assets/images/image10a_1.jpg`} alt=""/>
            {/* <button tabIndex={0} className="cbButton">서비스 가능 지역</button> */}
          </div>

          <div className="cBox">
            <div className="cbTxt1">STEP 2</div>
            <div className="cbTxt2">첫 캐비닛 <span className="underline">배송 신청</span> 후 캐비닛에 짐 넣기</div>
            <div className="cbTxt3">
              스토르앤고 전용 캐비닛이 집 앞으로 배송<br/>
              물품 보관한 캐비닛을 문 앞에 두고 수거신청
            </div>
            <img src={`${process.env.PUBLIC_URL}/assets/images/image12.png`} alt=""/>
            {/* <button tabIndex={0} className="cbButton">수거신청과 배송신청 구분</button> */}
          </div>

          <div className="cBox">
            <div className="cbTxt1">STEP 3</div>
            <div className="cbTxt2">비대면 <span className="underline">수거 신청</span>하기 (집&#8594;보관센터)</div>
            <div className="cbTxt3">
              D2D 배송팀이 고객 집 앞에서부터<br/>
              스토르앤고 보관센터까지 운송 후 보관
            </div>
            <img src={`${process.env.PUBLIC_URL}/assets/images/image13.png`} alt=""/>
          </div>

          <div className="cBox">
            <div className="cbTxt1">STEP 4</div>
            <div className="cbTxt2">다음 배송까지 <span className="underline">전용 보관센터</span>에 보관</div>
            <div className="cbTxt3">
              항온∙항습 ∙항진 기능을 갖춘  쾌적한 공간<br/>
              무인경비 ∙화재보험을 갖춘  안전한 공간
            </div>
            <img src={`${process.env.PUBLIC_URL}/assets/images/image14.png`} alt=""/>
            {/* <button tabIndex={0} className="cbButton">보관금지 물품</button> */}
          </div>
        </div>
      </div>

      <div className="bottomButtons">
        <button onClick={moveToLogin} tabIndex={0} className="buttonDone">스토르 사용하기</button>
      </div>

      <button tabIndex={0} className="gotoTop" id="gotoTop" title="화면 위로" onClick={moveToTop}></button>
    </>
  );
}

export default MainIntroduction;