
import { useEffect, useState } from "react";
import MainHeader from "./MainPopupHeader";
import { createPortal } from "react-dom";
import { TEST_IMAGE } from "../../port/Port";
import { useCookies } from "react-cookie";

function MainPopup({item}) {

  const [status, setStatus] = useState(true);
  const [checked, setChecked] = useState(false);
  const [hasCookie, setHasCookie] = useState(true);
  const [cookies, setCookies] = useCookies();

  const closeModal = () => {
    setStatus(false);
  };
  
  const onCheckHandler = e => {
    e.target.checked ? setChecked(true) : setChecked(false);
  };

  // 쿠키의 유효기간 지정 함수
  const getExpDate = days => {
    const date = new Date();
    date.setDate(date.getDate() + days);

    return date;
  };

  const popupRoot = document.getElementById("popup");

  const modal = 
  <div className={status ? "modal smallModal active" : "modal smallModal"}>
    <div className="modalHeader">
      <div className="modalTitle">{item.title}</div>
      <button tabIndex={0} className="closeModal" title="모달 닫기" onClick={closeModal}></button>
    </div>
    
    <div className="modalContent2" style={{ padding: "30px 20px 0 20px" }}>
      <img src={`${TEST_IMAGE}/popup/${item.filename_pc_svr}`} alt="popupImg" style={{ maxWidth: "100%" }}/>

      <div className="inside" style={{ width: "100%", textAlign: "right", padding: "10px 0" }}>
        <input id="cb_dayOff" type="checkbox" onChange={onCheckHandler}/><label htmlFor="cb_dayOff" style={{ marginLeft: "5px" }}>오늘 하루 더이상 보지 않기</label>
      </div>
    </div>
  </div>;
  const portal = createPortal(modal, popupRoot);

  // return (
  //   <div style={{ display: display }}>
  //     <MainHeader setDisplay={ setDisplay }/>

  //     <div className="container">
  //       <h2>맡기려고 하는<br/>물건의 종류는 무엇인가요?</h2>

  //       <div>아이템 목록 영역</div>
  //     </div>
  //     <div>완료 시 10,000 포인트를 드립니다.</div>
  //   </div>
  // );

  useEffect(() => {
    if(cookies[`mp_${item.seq}_Cookie`]) {
      setHasCookie(true);
    } else setHasCookie(false);
  }, []);

  useEffect(() => {
    if(!status && !checked) return;
    else if(!status && checked) {
      const expires = getExpDate(1);
      setCookies(`mp_${item.seq}_Cookie`, true, { path: "/", expires });
    }
  }, [status]);

  return !hasCookie ? portal : <></>;
}

export default MainPopup;