import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function CompleteApplyDeliveryModal({status, setStatus}) {

  const navigate = useNavigate();
  const deliveryReducer = useSelector(state => state.deliveryReducer);

  const closeModal = () => {
    setStatus(false);
    navigate("/delivery");
  };

  const dateFormat = str => {
    const y = str.substring(0, 4);
    const m = str.substring(5, 7);
    const date = str.substring(8, 10);
    let day = "";
    const dayNum = new Date(str).getDay();

    if(1 === dayNum) {
      day = "(월)";
    } else if(2 === dayNum) {
      day = "(화)";
    } else if(3 === dayNum) {
      day = "(수)";
    } else if(4 === dayNum) {
      day = "(목)";
    } else if(5 === dayNum) {
      day = "(금)";
    } else if(6 === dayNum) {
      day = "(토)";
    } else if(0 === dayNum) {
      day = "(일)";
    }

    return `${y}년 ${m}월 ${date}일 ${day}`;
  };

  return (
    <>
      <div className={ status ? "modal midModal active" : "modal midModal" }>
        <div className="modalNoHeader">
          <div className="modalTxt1"><span className="bold">{"C" === status ? deliveryReducer.cSelectedDate ? dateFormat(deliveryReducer.cSelectedDate) : dateFormat(deliveryReducer.cDefaultDate) : "D" === status ? deliveryReducer.dSelectedDate ? dateFormat(deliveryReducer.dSelectedDate) : deliveryReducer.dDefaultDate ? dateFormat(deliveryReducer.dDefaultDate) : dateFormat(deliveryReducer.terminateDate) : ""}</span></div>
          {/* <img src={`${process.env.PUBLIC_URL}/assets/images/sample003.jpg`} className="imgDelivery" alt=""/> */}
          {
            "C" === status ? "수거 신청이 완료되었습니다." : "D" === status ? "배송 신청이 완료되었습니다." : ""
          } 
        </div>

        <div className="modalButtons">
          <div className="inside">
            <button tabIndex={0} className="buttonDone" title="" onClick={closeModal}>확인</button>
          </div>
        </div>
      </div>

      <button tabIndex={0} className={ status ? "dimm active" : "dimm" } title="닫기" onClick={closeModal}></button>
    </>
  );
}

export default CompleteApplyDeliveryModal;