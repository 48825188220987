import { useEffect, useState } from "react";
import { postMemberPaymentItemAPI } from "../../../apis/MemberAPICalls";
import { useNavigate } from "react-router-dom";
import CreateToken from "../../../js/CreateToken";
import { useDispatch, useSelector } from "react-redux";
import { POST_MEMBER_DEFAULT_PAYMENT_INFO } from "../../../modules/member/MemberModule";
import PaymentPolicyModal from "../../modal/PaymentPolicyModal";
import Swal from "sweetalert2";
import { decrypt } from "../../../js/encrypt";
import ScrollToTop from "../../../js/ScrollToTop";

function RegistPaymentComp() {

  const [data, setData] = useState({
    cardNum1: "",
    cardNum2: "",
    cardNum3: "",
    cardNum4: "",
    expireM: "",
    expireY: "",
    card_pw: "",
    name: "",
    isDefault: "N",
    mem_seq: decrypt(sessionStorage.getItem("mem_seq")),
    policy1: "N",
    policy2: "N",
    policy3: "N",
    ssn1: "",
    ssn2: "",
  });
  const [modalStatus, setModalStatus] = useState(false);
  const [policyNum, setPolicyNum] = useState(0);
  const [isAvailable1, setIsAvailable1] = useState(false);
  const [isAvailable2, setIsAvailable2] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const memberReducer = useSelector(state => state.memberReducer);

  const defaultInput = document.getElementById("defaultInput");

  const moveBack = () => {
    navigate("/mypage/paymentManage");
  };

  const openModal = e => {
    setModalStatus(true);
    setPolicyNum(e.target.getAttribute("value"));
  };

  const onChangeHandler = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const onlyNums = e => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
  };

  const onNameHandler = e => {
    setData({
      ...data,
      name: e.target.value
    });
  };

  const onCheckHandler = e => {
    setData({
      ...data,
      [e.target.name]: e.target.checked ? "Y" : "N"
    });
  };

  const checkExpireLength = e => {
    if(2 < e.target.value.length) {
      e.target.value = e.target.value.substr(0, 2);
    }
  };

  const moveNextCardNumInput = e => {
    const targetEl = document.getElementsByName(e.target.name)[0];
    const nextTarget = document.getElementsByName("expireM")[0];
    targetEl.value = e.target.value;

    // next input
    if((48 <= e.keyCode && e.keyCode <= 57) || (96 <= e.keyCode && e.keyCode <= 105)) {
      // console.log(e.keyCode);
      if(e.target.value.length === e.target.maxLength && e.target.nextElementSibling && e.target.nextElementSibling.nextElementSibling) {
        e.target.nextElementSibling.nextElementSibling.focus();
      } else if(e.target.value.length === e.target.maxLength && !e.target.nextElementSibling) {
        nextTarget.focus();
      }
    }

    // backspace
    if(8 === e.keyCode) {
      if(!e.target.value.length && e.target.previousElementSibling && e.target.previousElementSibling.previousElementSibling) {
        e.target.previousElementSibling.previousElementSibling.focus();
      }
    }
  };

  const moveNextCardExpireInput = e => {
    const targetEl = document.getElementsByName(e.target.name)[0];
    const nextTarget = document.getElementsByName("card_pw")[0];
    targetEl.value = e.target.value;

    // next input
    if((48 <= e.keyCode && e.keyCode <= 57) || (96 <= e.keyCode && e.keyCode <= 105)) {
      // console.log(e.keyCode);
      if(e.target.value.length === e.target.maxLength && e.target.nextElementSibling && e.target.nextElementSibling.nextElementSibling) {
        e.target.nextElementSibling.nextElementSibling.focus();
      } else if(e.target.value.length === e.target.maxLength && !e.target.nextElementSibling) {
        nextTarget.focus();
      }
    }

    // backspace
    if(8 === e.keyCode) {
      if(!e.target.value.length && e.target.previousElementSibling && e.target.previousElementSibling.previousElementSibling) {
        e.target.previousElementSibling.previousElementSibling.focus();
      }
    }
  };

  const agreeAll = () => {
    const p1 = document.getElementsByName("policy1")[0];
    const p2 = document.getElementsByName("policy2")[0];
    const p3 = document.getElementsByName("policy3")[0];

    p1.checked = true;
    p2.checked = true;
    p3.checked = true;

    setData({
      ...data,
      policy1: "Y",
      policy2: "Y",
      policy3: "Y",
    });
  };

  const calcCardExp = () => {
    const comparison = new Date();
    comparison.setMonth(comparison.getMonth() + 3);

    const cardExp = `20${data.expireY}-${data.expireM}`;
    const expDate = new Date(cardExp);
    expDate.setDate(expDate.getDate() + 1);

    return comparison < expDate;
  };

  const submitPayment = async () => {
    if(!data.cardNum1 || !data.cardNum2 || !data.cardNum3 || !data.cardNum4 || !data.card_pw || !data.expireM || !data.expireY || !data.name || !data.ssn1 || !data.ssn2) {
      Swal.fire({
        text: "필수 정보들을 입력하세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }

    if("N" === data.policy1 || "N" === data.policy2 || "N" === data.policy3) {
      Swal.fire({
        text: "필수 약관에 동의하세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }

    const resArr = await postMemberPaymentItemAPI(data, CreateToken());

    if(1 !== resArr[0]) {
      Swal.fire({
        text: resArr[1],
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    } else {
      if(!Object.keys(memberReducer.cardInfo).length) dispatch({ type: POST_MEMBER_DEFAULT_PAYMENT_INFO, payload: data });

      Swal.fire({  
        text: "성공적으로 등록했습니다.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then(res => navigate("/mypage/paymentManage"));
      return;
    }
  };

  useEffect(() => {
    ScrollToTop();

    if(!Object.keys(memberReducer.cardInfo).length) {
      setData({
        ...data,
        isDefault: "Y"
      });
    }
  }, []);

  useEffect(() => {
    if(!Object.keys(memberReducer.cardInfo).length && defaultInput) {
      defaultInput.checked = true;
      defaultInput.disabled = true;
    }

    if(4 === data.cardNum1.length && 4 === data.cardNum2.length && 4 === data.cardNum3.length && (4 === data.cardNum4.length || 3 === data.cardNum4.length) && 2 === data.expireM.length && 2 === data.expireY.length && 2 === data.card_pw.length && data.name && 6 === data.ssn1.length && 1 === data.ssn2.length && "Y" === data.policy1 && "Y" === data.policy2 && "Y" === data.policy3) setIsAvailable1(true);
    else setIsAvailable1(false);

    if(calcCardExp()) setIsAvailable2(true);
    else setIsAvailable2(false);

    if("00" === data.expireM) {
      setData({
        ...data,
        expireM: ""
      });
    } else {
      if(Number(data.expireM) > 12) {
        setData({
          ...data,
          expireM: ""
        });
      }
    }
  }, [data]);

  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>간편 결제수단 등록</h1>
        </div>
      </header>

      <PaymentPolicyModal status={modalStatus} setStatus={setModalStatus} type={policyNum} setType={setPolicyNum} data={data} setData={setData}/>

      <div className="contents">
        <div className="centerWrap">
          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">카드번호</div>
          </div>
          <div className="dataWrap">
            <input name="cardNum1" type="text" className="inputNormal inputNormal1in4 inputUnderline" title="카드번호" onInput={onlyNums} onChange={onChangeHandler} onKeyUp={moveNextCardNumInput} value={data.cardNum1} maxLength={4}/>
            <div className="bw">-</div>
            <input name="cardNum2" type="text" className="inputNormal inputNormal1in4 inputUnderline" title="카드번호" onInput={onlyNums} onChange={onChangeHandler} onKeyUp={moveNextCardNumInput} value={data.cardNum2} maxLength={4}/>
            <div className="bw">-</div>
            <input name="cardNum3" type="text" className="inputNormal inputNormal1in4 inputUnderline" title="카드번호" onInput={onlyNums} onChange={onChangeHandler} onKeyUp={moveNextCardNumInput} value={data.cardNum3} maxLength={4}/>
            <div className="bw">-</div>
            <input name="cardNum4" type="text" className="inputNormal inputNormal1in4 inputUnderline" title="카드번호" onInput={onlyNums} onChange={onChangeHandler} onKeyUp={moveNextCardNumInput} value={data.cardNum4} maxLength={4}/>
          </div>
          {
            4 > data.cardNum1.length || 4 > data.cardNum2.length || 4 > data.cardNum3.length || 3 > data.cardNum4.length ?
            <div className="warning">
              <div className="txt">신용카드 번호를 정확히 입력하세요.</div>
            </div>
            :
            <></>
          }

          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">유효기간</div>
          </div>
          <div className="dataWrap">
            <input name="expireM" type="text" className="inputNormal inputNormal1in2" title="유효기간 월" placeholder="MM" onInput={onlyNums} onChange={onChangeHandler} onKeyUp={moveNextCardExpireInput} value={data.expireM} maxLength={2}/>
              <div className="bw">-</div>
            <input name="expireY" type="password" className="inputNormal inputNormal1in2" title="유효기간 년도" placeholder="YY" onInput={onlyNums} onChange={onChangeHandler} onKeyUp={moveNextCardExpireInput} value={data.expireY} maxLength={2}/>
          </div>
          {
            !isAvailable2 ?
            <div className="warning">
              <div className="txt">유효기간이 3개월 이상 남은 카드를 등록하셔야합니다.</div>
            </div>
            :
            <></>
          }

          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">카드 비밀번호(앞 2자리)</div>
          </div>
          <div className="dataWrap">
            <div className="inputStyleWrap" style={{ maxWidth: "200px"}}>
              <input name="card_pw" type="password" className="cardUnderline" title="카드 비밀번호" onInput={onlyNums} onChange={onChangeHandler} value={data.card_pw} maxLength={2}/>
              <div className="inputCenterTxt"></div>
              <input type="password" className="inputTrans"value="**" readOnly disabled/>
            </div>
          </div>

          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">이름</div>
          </div>
          <div className="dataWrap">
            <input type="text" className="inputNormal" title="이름" onChange={onNameHandler}/>
          </div>

          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">생년월일</div>
          </div>
          <div className="dataWrap">
            <div className="inputStyleWrap">
              <input type="text" className="birthUnderline" title="생년월일" name="ssn1" onInput={onlyNums} onChange={onChangeHandler} value={data.ssn1} maxLength={6}/>
              <div className="inputCenterTxt">-</div>
              <input type="password" className="birthUnderline2" title="성별" name="ssn2" onInput={onlyNums} onChange={onChangeHandler} value={data.ssn2} maxLength={1}/>
              <div className="etc">******</div>
            </div>
          </div>

          <div className="dataWrap" style={{ marginTop: "32px" }}>
            <label className="brisakInput">
              <input id="defaultInput" name="isDefault" type="checkbox" onChange={onCheckHandler}/>
              <div className="wrap">
                <div className="icon"></div>
                <div className="txt">기본 결제 수단으로 설정</div>
              </div>
            </label>
          </div>

          <br/>
          <div className="dataWrap">
            <button tabIndex={0} className="buttonDone" title="모든 약관 동의" onClick={agreeAll}>모든 약관 동의</button>
          </div>

          <div className="dataWrap dataWrapPolicy">
            <label className="brisakInput">
              <input type="checkbox" name="policy1" onChange={onCheckHandler} checked={"Y" === data.policy1 ? true : false}/>
              <div className="wrap">
                <div className="icon"></div>
                <div className="txt">(나이스페이)전자금융거래 이용약관</div>
              </div>
            </label>

            <span className="buttonShowPolicy" style={{ cursor: "pointer" }} value={1} onClick={openModal}></span>
          </div>

          <div className="dataWrap dataWrapPolicy">
            <label className="brisakInput">
              <input type="checkbox" name="policy2" onChange={onCheckHandler} checked={"Y" === data.policy2 ? true : false}/>
              <div className="wrap">
                <div className="icon"></div>
                <div className="txt">(나이스페이)개인정보 수집 및 이용 안내</div>
              </div>
            </label>

            <span className="buttonShowPolicy" style={{ cursor: "pointer" }} value={2} onClick={openModal}></span>
          </div>

          <div className="dataWrap dataWrapPolicy">
            <label className="brisakInput">
              <input type="checkbox" name="policy3" onChange={onCheckHandler} checked={"Y" === data.policy3 ? true : false}/>
              <div className="wrap">
                <div className="icon"></div>
                <div className="txt">(나이스페이)개인정보 제공 및 위탁 안내</div>
              </div>
            </label>

            <span className="buttonShowPolicy" style={{ cursor: "pointer" }} value={3} onClick={openModal}></span>
          </div>
        </div>
      </div>

      <div className="bottomButtons">
        <button tabIndex={0} className={isAvailable1 && isAvailable2 ? "buttonDone" : "buttonDone disabledBtn"} onClick={submitPayment} disabled={isAvailable1 && isAvailable2 ? false : true}>등록하기</button>
      </div>
    </>
  );
}

export default RegistPaymentComp;