import MainMenu from "../components/main/MainMenu";
import MainHeader from "../components/main/MainHeader";
import MainPopup from "../components/main/MainPopup";
import MainServiceList from "../components/main/MainServiceList";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from 'swiper/modules';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getMemberInfoAPI, getMemberLastPurchasedServiceSeq } from "../apis/MemberAPICalls";
import CheckRegionPopup from "../components/popup/CheckRegionPopup";
import RegionAlertPopup from "../components/popup/RegionAlertPopup";
import { TEST_IMAGE } from "../port/Port";
import { getBannerListAPI, getPopupListAPI } from "../apis/ServiceAPICalls";
import "../css/swiper.css";
import "../js/swiper.js";
import { useDispatch, useSelector } from "react-redux";
import { GET_MEMBER_INFO, POST_MEMBER_FIRST_PURCHASE, POST_MEMBER_SERVICE_AVAILABLE } from "../modules/member/MemberModule.js";
import AddrConfirm from "../components/modal/AddrConfirm.js";
import { decrypt } from "../js/encrypt.js";

function Main() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalStatus, setModalStatus] = useState(false);
  const [memInfo, setMemInfo] = useState({});
  const [bannerList, setBannerList] = useState([]);
  const [popupList, setPopupList] = useState([]);
  const memberReducer = useSelector(state => state.memberReducer);

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const moveToIntro = () => {
    navigate("introduction");
  };

  const moveToCabinetIntro = () => {
    navigate("cabinetIntro");
  };

  const moveToAvailable = () => {
    navigate("serviceAvailableRegion");
  };

  useEffect(() => {
    if(localStorage.getItem("mem_seq")) {
      // if(!isNaN(parseInt(decrypt(localStorage.getItem("mem_seq"))))) {
      //   sessionStorage.clear();
      //   localStorage.removeItem("mem_seq");
      //   navigate("/login");
      //   return;
      // }
      if(!(localStorage.getItem("mem_seq")).includes("dev_")) {
        if(isNaN(parseInt(decrypt(localStorage.getItem("mem_seq"))))) {
          sessionStorage.clear();
          localStorage.removeItem("mem_seq");
          // navigate("/login");
          window.location.replace("/login");
          return;
        }
      }

      sessionStorage.setItem("mem_seq", localStorage.getItem("mem_seq"));

      (async () => {
        setMemInfo(await getMemberInfoAPI(decrypt(localStorage.getItem("mem_seq"))));
        setBannerList(await getBannerListAPI());
        setPopupList(await getPopupListAPI());
      })();
    } else {
      if(sessionStorage.getItem("mem_seq") && "0" !== decrypt(sessionStorage.getItem("mem_seq"))) {
        localStorage.setItem("mem_seq", sessionStorage.getItem("mem_seq"));

        (async () => {
          setMemInfo(await getMemberInfoAPI(decrypt(localStorage.getItem("mem_seq"))));
          setBannerList(await getBannerListAPI());
        })();
      } else if(sessionStorage.getItem("mem_seq") && "0" === decrypt(sessionStorage.getItem("mem_seq"))) {
        (async () => {
          setBannerList(await getBannerListAPI());
        })();
      } else if(!sessionStorage.getItem("mem_seq")) navigate("/login");
    }

    (async () => {
      const seq = await getMemberLastPurchasedServiceSeq(decrypt(sessionStorage.getItem("mem_seq")));

      dispatch({ type: POST_MEMBER_FIRST_PURCHASE, payload: seq ? false : true });
    })();
  }, []);

  useEffect(() => {
    if(sessionStorage.getItem("mem_seq") && "0" !== decrypt(sessionStorage.getItem("mem_seq"))) {
      // if(memInfo && Object.keys(memInfo).length && !memInfo.collection_addr1 && !memInfo.delivery_addr1) {
      //   // setModalStatus(true);
      //   return;
      // } else if(memInfo && Object.keys(memInfo).length && !memInfo.collection_addr1.includes("서울") && (!memInfo.collection_addr1.includes("경기") || !memInfo.collection_addr1.includes("하남")) && (!memInfo.collection_addr1.includes("경기") || !memInfo.collection_addr1.includes("구리")) && (memInfo.delivery_addr1.includes("서울") || (memInfo.delivery_addr1.includes("경기") && memInfo.delivery_addr1.includes("하남")) || (memInfo.delivery_addr1.includes("경기") || memInfo.delivery_addr1.includes("구리")))) {
      //   setModalStatus(true);
      //   dispatch({ type:POST_MEMBER_SERVICE_AVAILABLE, payload: {coll: false, del: true} });
      // } else if(memInfo && Object.keys(memInfo).length && !memInfo.delivery_addr1.includes("서울") && (!memInfo.delivery_addr1.includes("경기") || !memInfo.delivery_addr1.includes("하남")) && (!memInfo.delivery_addr1.includes("경기") || !memInfo.delivery_addr1.includes("구리")) && (memInfo.collection_addr1.includes("서울") || (memInfo.collection_addr1.includes("경기") && memInfo.collection_addr1.includes("하남")) || (memInfo.collection_addr1.includes("경기") || memInfo.collection_addr1.includes("구리")))) {
      //   setModalStatus(true);
      //   dispatch({ type:POST_MEMBER_SERVICE_AVAILABLE, payload: {coll: true, del: false} });
      // } else if(memInfo && Object.keys(memInfo).length && (memInfo.delivery_addr1.includes("서울") || (memInfo.delivery_addr1.includes("경기") && memInfo.delivery_addr1.includes("하남")) || (memInfo.delivery_addr1.includes("경기") || memInfo.delivery_addr1.includes("구리"))) && (memInfo.collection_addr1.includes("서울") || (memInfo.collection_addr1.includes("경기") && memInfo.collection_addr1.includes("하남")) || (memInfo.collection_addr1.includes("경기") || memInfo.collection_addr1.includes("구리")))) {
      //   console.log("yes");
      //   setModalStatus(false);
      //   dispatch({ type:POST_MEMBER_SERVICE_AVAILABLE, payload: {coll: true, del: true} });
      // } else if(memInfo && Object.keys(memInfo).length && (!(memInfo.delivery_addr1.includes("서울") || (memInfo.delivery_addr1.includes("경기") && memInfo.delivery_addr1.includes("하남")) || (memInfo.delivery_addr1.includes("경기") || memInfo.delivery_addr1.includes("구리"))) && !(memInfo.collection_addr1.includes("서울") || (memInfo.collection_addr1.includes("경기") && memInfo.collection_addr1.includes("하남")) || (memInfo.collection_addr1.includes("경기") || memInfo.collection_addr1.includes("구리"))))) {
      //   setModalStatus(true);
      //   dispatch({ type:POST_MEMBER_SERVICE_AVAILABLE, payload: {coll: false, del: false} });
      // }
      dispatch({ type: GET_MEMBER_INFO, payload: memInfo });
    }

    if(memInfo && Object.keys(memInfo).length) {
      dispatch({ type: GET_MEMBER_INFO, payload: memInfo });
      // if(!memInfo.collection_addr1 || !memInfo.delivery_addr1) setRModalStatus(true);
      if(memInfo.collection_addr1 && memInfo.delivery_addr1) {
        if(((memInfo.collection_addr1).includes("서울") || ((memInfo.collection_addr1).includes("경기") && ((memInfo.collection_addr1).includes("하남") || (memInfo.collection_addr1).includes("구리")))) && ((memInfo.delivery_addr1).includes("서울") || ((memInfo.delivery_addr1).includes("경기") && ((memInfo.delivery_addr1).includes("하남") || (memInfo.delivery_addr1).includes("구리"))))) {
          dispatch({ type: POST_MEMBER_SERVICE_AVAILABLE, payload: {coll: true, del: true}});
        } else if(((memInfo.collection_addr1).includes("서울") || ((memInfo.collection_addr1).includes("경기") && ((memInfo.collection_addr1).includes("하남") || (memInfo.collection_addr1).includes("구리")))) && (!(memInfo.delivery_addr1).includes("서울") && (!(memInfo.delivery_addr1).includes("경기") || (!(memInfo.delivery_addr1).includes("하남") && !(memInfo.delivery_addr1).includes("구리"))))) {
          dispatch({ type: POST_MEMBER_SERVICE_AVAILABLE, payload: {coll: true, del: false}});
        } else if((!(memInfo.collection_addr1).includes("서울") && (!(memInfo.collection_addr1).includes("경기") || (!(memInfo.collection_addr1).includes("하남") && !(memInfo.collection_addr1).includes("구리")))) && ((memInfo.delivery_addr1).includes("서울") || ((memInfo.delivery_addr1).includes("경기") && ((memInfo.delivery_addr1).includes("하남") || (memInfo.delivery_addr1).includes("구리"))))) {
          dispatch({ type: POST_MEMBER_SERVICE_AVAILABLE, payload: {coll: false, del: true}});
        } else {
          dispatch({ type: POST_MEMBER_SERVICE_AVAILABLE, payload: {coll: false, del: false}});
        }
      }
    }
  }, [memInfo]);
  
  return (
    <>
      {
        popupList.length ?
        popupList.map(item => {
          
          return (
            <MainPopup item={item}/>
          );
        })
        :
        <></>
      }

      <MainHeader/>

      {
        memInfo && Object.keys(memInfo).length && memInfo.collection_addr1 && memInfo.delivery_addr1 ?
        !memberReducer.coll_addr_available && !memberReducer.del_addr_available ?
        <AddrConfirm status={modalStatus} setStatus={setModalStatus} type={"All"}/>
        :
        !memberReducer.del_addr_available ?
        <AddrConfirm status={modalStatus} setStatus={setModalStatus} type={"D"}/>
        :
        <AddrConfirm status={modalStatus} setStatus={setModalStatus} type={"C"}/>
        :
        <RegionAlertPopup modalStatus={modalStatus} setModalStatus={setModalStatus}/>
      }

      <div className="contents mainContents">
        <div className="centerWrap">
          <div className="mainTopWrap">
            <Swiper
              modules={[Navigation, Pagination]}
              spaceBetween={0}
              slidesPerView={1}
              centeredSlides
              loop
              pagination={{
                el: ".swiper-pagination"
              }}
              navigation={{
                nextEl: ".buttonSwiperNext",
                prevEl: ".buttonSwiperPrev",
              }}
              className="mainSwiper"
            >
              <div className="swiper-wrapper">
                {
                  bannerList.length ?
                  bannerList.map(item => {
                    return (
                      <SwiperSlide key={item.seq} className="swiper-slide">
                        {/* <div dangerouslySetInnerHTML={{__html: item.content}}></div> */}
                        <img src={`${TEST_IMAGE}/banner/${item.filename_pc_svr}`} className="img1" alt=""/>
                      </SwiperSlide>
                    );
                  })
                  :
                  <></>
                }
              </div>

              <div className="swiper-pagination"></div>

              <button tabIndex={0} className="buttonSwiperPrev" title="이전"></button>
              <button tabIndex={0} className="buttonSwiperNext" title="다음"></button>
            </Swiper>

            <div className="mainTopMenus">
              <a onClick={moveToIntro} className="mainTopMenu"><span>서비스 이용안내</span></a>
              <a onClick={moveToCabinetIntro} className="mainTopMenu"><span>캐비닛 이용방법</span></a>
            </div>
          </div>

          <div className="mainTxt1">가장 혁신적인 배송형 짐보관 구독서비스!</div>

          <MainServiceList/>
        </div>
      </div>

      <MainMenu/>

      <div className="bottomFixedButton" onClick={moveToAvailable}>서비스 가능지역</div>
      <button tabIndex={0} className="gotoTop" id="gotoTop" title="화면 위로" onClick={moveToTop}></button>
    </>
  );
}

export default Main;