import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../js/ScrollToTop";

function MainCabinetIntro() {

  const navigate = useNavigate();

  const moveBack = () => {
    navigate("/");
  };

  const moveToLogin = () => {
		if(localStorage.getItem("mem_seq") || sessionStorage.getItem("mem_seq")) navigate("/");
    else navigate("/login");
  };

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

	useEffect(() => {
		ScrollToTop();
	}, []);

  return (
    <>
		<header>
			<div className="centerWrap">
				<button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
				<h1>스토르앤고 캐비닛 이용방법 안내</h1>
				<div className="appicon"></div>
			</div>
		</header>
		
		<div className="contents">
			<div className="centerWrap">
			
				<div className="cBox">
					<div className="cbTxt1">보안성/기능성/프라이버시 보호</div>
					<div className="cbTxt3">
						견고한 소재와 부품으로 튼튼하게 제작되어 <br/>
						나만의 소중한 물건을 안전하게 지켜줍니다.
					</div>
					<img src={`${process.env.PUBLIC_URL}/assets/images/image15.png`} alt=""/>
				</div>

				<div className="cBox">
					<div className="cbTxt1">넉넉한 내부 공간</div>
					<div className="cbTxt3">
						우체국 4호박스(41x31x28cm) 10개도 충분하게
					</div>
					<img src={`${process.env.PUBLIC_URL}/assets/images/image22.gif`} alt=""/>
				</div>

				<div className="cBox">
					<div className="cbTxt1">캐비닛 열 때</div>
					<div className="cbTxt3">
						잠금핸들을 좌측으로 끝까지 돌리세요.
					</div>
					<img src={`${process.env.PUBLIC_URL}/assets/images/image16.gif`} alt=""/>
				</div>

				<div className="cBox">
					<div className="cbTxt1">캐비닛 잠글 때</div>
					<div className="cbTxt3">
						잠금핸들을 우측으로 끝까지 돌리세요.
					</div>
					<img src={`${process.env.PUBLIC_URL}/assets/images/image17.gif`} alt=""/>
				</div>

				<div className="cBox">
					<div className="cbTxt1">자물쇠로 한 번 더 잠금!</div>
					<div className="cbTxt3">
						스토르 전용 자물쇠로 잠금핸들을 고정해 주세요.
					</div>
					<img src={`${process.env.PUBLIC_URL}/assets/images/image18.gif`} alt=""/>
				</div>

				<div className="cBox">
					<div className="cbTxt1">비밀번호 변경할 때</div>
					<div className="cbTxt3">
						아래 버튼을 누른 채, 새 번호로 변경하세요.<br/>
						최초 비밀번호: 고객 휴대폰 번호 뒤 3자리 
					</div>
					<img src={`${process.env.PUBLIC_URL}/assets/images/image19.gif`} alt=""/>
				</div>

				<div className="cBox">
					<div className="cbTxt1">내부 행거 및 탈착형 선반</div>
					<div className="cbTxt3">
						탈거한 선반은 캐비닛 내부 바닥에 두세요.
					</div>
					<img src={`${process.env.PUBLIC_URL}/assets/images/image20.gif`} alt=""/>
				</div>

				<div className="cBox">
					<div className="cbTxt1">스토퍼 기능을 갖춘 바퀴</div>
					<div className="cbTxt3">
						문 앞에 캐비닛을 놓을 때, 스토퍼를 내려주세요.
					</div>
					<img src={`${process.env.PUBLIC_URL}/assets/images/image21.png`} alt=""/>
				</div>


				
			</div>
		</div>

		<div className="bottomButtons">
    	<button onClick={moveToLogin} tabIndex={0} className="buttonDone">스토르 사용하기</button>
		</div>


		<button tabIndex={0} className="gotoTop" id="gotoTop" title="화면 위로" onClick={moveToTop}></button>
    </>
  );
}

export default MainCabinetIntro;