import { useEffect, useState } from "react";
import { faqCateListAPI, faqListAPI } from "../../apis/FaqAPICalls";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../js/ScrollToTop";

export default function Faq() {

  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [cateList, setCateList] = useState([]);
  const [chosen, setChosen] = useState("");
  const [page, setPage] = useState(1);

  const moveBack = () => {
    navigate("/mypage", {replace: true});
  };

  const changeCategory = e => {
    const prevActived = document.getElementsByClassName("active")[0];
    
    if(e.target !== prevActived) {
      prevActived.classList.remove("active");
      e.target.classList.add("active");

      setChosen(e.target.value);
    }
  };

  const moveToFaqDetail = e => {
    const faq_seq = e.target.getAttribute("value");
    const info = list.filter(item => item.seq === Number(faq_seq));
    
    navigate(`detail/${faq_seq}`, { state: info[0] });
  };

  useEffect(() => {
    ScrollToTop();

    (async () => {
      setList(await faqListAPI(page, chosen));
      setCateList(await faqCateListAPI());
    })();
  }, []);

  useEffect(() => {
    ScrollToTop();

    (async () => {
      setList(await faqListAPI(page, chosen));
    })();
  }, [page]);

  useEffect(() => {
    ScrollToTop();

    (async () => {
      setList(await faqListAPI(1, chosen));
    })();
  }, [chosen]);

  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>FAQ</h1>
        </div>
      </header>

      <div className="contents contents2">
        <div className="centerWrap">
          <div className="cateArea">
            <button className="cateBtn active" value="" onClick={changeCategory}>전체</button>
            {
              cateList.length ?
              cateList.map(item => {
                  return (
                  <button className="cateBtn" key={item.cate_name} value={item.seq} onClick={changeCategory}>{item.cate_name}</button>
                );
              })
              :
              <></>
            }
          </div>
        
          <div className="noticeWrap" style={{ marginTop: "50px" }}>
            {
              list.length ?
              list.map(item => {

                return (
                  <div key={item.regdate} className="noticeOne" value={item.seq} style={{ cursor: "pointer" }} onClick={moveToFaqDetail}>
                    <div className="noticeTitle" value={item.seq}>Q. {`[${item.cate_name}] ${item.title}`}</div>
                  </div>
                );
              })
              :
              <></>
            }
          </div>
        </div>
      </div>
    </>
  );
}