import { useEffect, useState } from "react";
import { getDetailServiceAPI } from "../../apis/ServiceAPICalls";
import { cancelMemberStorageApplyDateAPI, getMemberStorageFreeCountAPI } from "../../apis/MemberAPICalls";
import { useDispatch, useSelector } from "react-redux";
import { POST_MEMBER_TIME_AND_FREE_CNT } from "../../modules/member/MemberModule";
import { useNavigate } from "react-router-dom";
import CreateToken from "../../js/CreateToken";
import Swal from "sweetalert2";
import { decrypt } from "../../js/encrypt";

function DeliveryTop({purchasedList, applyList, setStatus}) {

  const [serviceInfo, setServiceInfo] = useState({});
  const [checkCardExp, setCheckCardExp] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const memberReducer = useSelector(state => state.memberReducer);
  const deliveryReducer = useSelector(state => state.deliveryReducer);

  const dateFormat = (date, type) => {
    if(!date){
      return "대기중";
    }

    if("s" === type || "e" === type) {
      const year = date.substring(0, 4);
      const month = date.substring(5, 7).replace(".", "").padStart(2, "0");
      const day = date.substring(8, 10).replace(".", "").padStart(2, "0");
      
      return `${year}.${month}.${day}`;
    } else if("n" === type) {
      let formated = new Date(date);
      formated.setDate(formated.getDate() + 1);
      
      const edDate = formated.toLocaleString();
      const year = edDate.substring(0, 4);
      const month = edDate.substring(6, 8).replace(".", "").padStart(2, "0");
      const day = edDate.substring(9, 11).replace(".", "").padStart(2, "0");

      return `${year}.${month}.${day}`;
    }
  };

  const dateFormat2 = (applyDate) => {
    const year = applyDate.substring(0, 4);
    const month = applyDate.substring(5, 7).replace(".", "").padStart(2, "0");
    const date = applyDate.substring(8, 10).replace(".", "").padStart(2, "0");

    return `${year}. ${month}. ${date}`;
  };

  const dateFormat3 = (val) => {
    const y = val.getFullYear();
    const m = String(val.getMonth() + 1).padStart(2, "0");
    const d = String(val.getDate()).padStart(2, "0");

    return `${y}-${m}-${d}`;
  };

  const calcCardExp = () => {
    const comparison = new Date();
    comparison.setMonth(comparison.getMonth() + 3);

    const cardExp = `20${memberReducer.cardInfo.card_expiration_yy}-${memberReducer.cardInfo.card_expiration_mm}`;
    const expDate = new Date(cardExp);
    expDate.setDate(expDate.getDate() + 1);

    return comparison < expDate;
  };

  const openDCModal = () => {
    setStatus(`${applyList[0].status} ${applyList[0].type}`);
  };

  const selectDate = () => {
    if(!checkDeadLine2(applyList[0].apply_date)) {
      Swal.fire({
        text: "수거/배송 변경은 하루 전 날 오후 11시 전까지만 가능합니다.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      });
      return;
    }

    navigate(`/delivery/order/selectDate?type=${applyList[0].type}&status=${applyList[0].status}&seq=${applyList[0].seq}`);
  };

  const checkDeadLine = val => {
    const now = new Date();
    const edDate = new Date(val);
    edDate.setHours(23);
    edDate.setMinutes(59);
    edDate.setSeconds(59);

    return now <= edDate;
  };

  const checkDeadLine2 = val => {
    const now = new Date();
    const applyDate = new Date(val);
    applyDate.setDate(applyDate.getDate() - 1);
    applyDate.setHours(23);
    applyDate.setMinutes(0);
    applyDate.setSeconds(0);

    return now < applyDate;
  };
// console.log(applyList[0].apply_date)
  const checkAutoPayDate = val => {
    const now = new Date();
    const edDate = new Date(val);
    edDate.setDate(edDate.getDate() + 1);
    edDate.setHours(0);
    edDate.setMinutes(0);
    edDate.setSeconds(0);

    return now < edDate;
  };

  const cancelStorage = () => {
    if(!checkDeadLine2(applyList[0].apply_date)) {
      Swal.fire({
        text: "수거/배송 취소는 신청일 하루 전 날 오후 11시 전까지만 가능합니다.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      });
      return;
    }
    // if("C" === applyList[0].type && confirm("수거 신청을 취소하시겠습니까?")) cancelMemberStorageApplyDateAPI(sessionStorage.getItem("mem_seq"), applyList[0].seq, CreateToken());
    // else if("D" === applyList[0].type && confirm("배송 신청을 취소하시겠습니까?")) cancelMemberStorageApplyDateAPI(sessionStorage.getItem("mem_seq"), applyList[0].seq, CreateToken());
    if("C" === applyList[0].type) {
      Swal.fire({
        text: "수거 신청을 취소하시겠습니까?",
        confirmButtonText: "확인",
        showCancelButton: true,
        cancelButtonText: "취소",
        cancelButtonColor: "black",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then(async (res) => {
        if(res.isConfirmed) {
          await cancelMemberStorageApplyDateAPI(decrypt(sessionStorage.getItem("mem_seq")), applyList[0].seq, CreateToken());
          
          // location.replace(location.href);
        } else return;
      })
      .catch(err => console.error(err));
    } else if("D" === applyList[0].type) {
      Swal.fire({
        text: "배송 신청을 취소하시겠습니까?",
        confirmButtonText: "확인",
        showCancelButton: true,
        cancelButtonText: "취소",
        cancelButtonColor: "black",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then(async (res) => {
        if(res.isConfirmed) {
          await cancelMemberStorageApplyDateAPI(decrypt(sessionStorage.getItem("mem_seq")), applyList[0].seq, CreateToken());
        } else return;
      })
      .catch(err => console.error(err));
    }
  };

  useEffect(() => {
    if(!Object.keys(serviceInfo).length && purchasedList.length) {
      (async () => {
        setServiceInfo(await getDetailServiceAPI(purchasedList[0].payment_goods_seq));
      })();
    }
  }, [purchasedList]);

  useEffect(() => {
    if(Object.keys(serviceInfo).length) {
      (async () => {
        const cnt = await getMemberStorageFreeCountAPI(decrypt(sessionStorage.getItem("mem_seq")), memberReducer.service_seq);

        dispatch({ type: POST_MEMBER_TIME_AND_FREE_CNT, payload: [serviceInfo.times, cnt] })
      })();
    }
  }, [serviceInfo]);

  useEffect(() => {
    if(memberReducer.cardInfo && Object.keys(memberReducer.cardInfo).length) {
      if(!calcCardExp()) setCheckCardExp(false);
      else setCheckCardExp(true);
    }
  }, [checkCardExp]);

  return (
    <>
      <div className="titleWrap2">
        <div className="title">수거/배송 신청</div>
        {
          purchasedList[0] && purchasedList[0].service_date_ed >= dateFormat3(new Date()) ?
          <>
            <div className="coupon">쿠폰 {memberReducer.coupon}</div>
            {
              memberReducer.time ?
              <div className="free">FREE {memberReducer.freeUsedCnt}/{memberReducer.time}회</div>
              :
              <></>
            }
            {
              memberReducer.time && memberReducer.time && memberReducer.time <= memberReducer.freeUsedCnt ?
              <div className="payMore">추가결제</div>
              :
              <></>
            }
          </>
          :
          <></>
        }
      </div>

      <div className="box1">
        {
          !purchasedList.length || "Y" === purchasedList[0].refund_yn || !checkAutoPayDate(purchasedList[0].service_date_ed) ?
          <div className="noList">
            <img src={`${process.env.PUBLIC_URL}/assets/images/nolist1.png`} alt=""/>
            <div className="txt">현재 이용중인 서비스가 없습니다</div>
          </div>
          :
          <div className="service">
            {
              applyList.length ?
              <>
                <div className="row">
                  <div className="status">
                    {
                      "C" === applyList[0].type && ("A1" === applyList[0].status || "B1" === applyList[0].status) && <>수거신청</>
                    }
                    {
                      "D" === applyList[0].type && ("A1" === applyList[0].status || "B1" === applyList[0].status) && <>배송신청</>
                    }
                    {
                      "C" === applyList[0].type && "D1" === applyList[0].status && <>수거완료</>
                    }
                    {
                      "D" === applyList[0].type && "D1" === applyList[0].status && <>배송완료</>
                    }
                    {
                      "C" === applyList[0].type && "E1" === applyList[0].status && <>수거실패</>
                    }
                    {
                      "D" === applyList[0].type && "E1" === applyList[0].status && <>배송실패</>
                    }
                    {
                      ("C" === applyList[0].type || "D" === applyList[0].type) && "C1" === applyList[0].status && <>취소신청</>
                    }
                    {
                      ("C" === applyList[0].type || "D" === applyList[0].type) && "C2" === applyList[0].status && <>취소완료</>
                    }
                  </div>
                </div>
                <div className="row row2">
                  <div className="date">
                    {
                      applyList[0].comp_date ?
                      dateFormat2(applyList[0].comp_date)
                      :
                      dateFormat2(applyList[0].apply_date)
                    }
                  </div>
                  {
                    "C" === applyList[0].type && "A1" === applyList[0].status && <div className="shipping shipping1">수거 예정</div>
                  }
                  {
                    "C" === applyList[0].type && "B1" === applyList[0].status && <div className="shipping shipping2">도착 예정</div>
                  }
                  {
                    "C" === applyList[0].type && "C1" === applyList[0].status && <div className="shipping shipping2">취소 예정</div>
                  }
                  {
                    "C" === applyList[0].type && "C2" === applyList[0].status && <div className="shipping shipping3">수거 취소</div>
                  }
                  {
                    "C" === applyList[0].type && "D1" === applyList[0].status && <div className="shipping shipping3">수거 완료</div>
                  }
                  {
                    "C" === applyList[0].type && "E1" === applyList[0].status && <div className="shipping shipping3">수거 실패</div>
                  }
                  
                  {
                    "D" === applyList[0].type && "A1" === applyList[0].status && <div className="shipping shipping1">배송 예정</div>
                  }
                  {
                    "D" === applyList[0].type && "B1" === applyList[0].status && <div className="shipping shipping2">도착 예정</div>
                  }
                  {
                    "D" === applyList[0].type && "C1" === applyList[0].status && <div className="shipping shipping2">취소 예정</div>
                  }
                  {
                    "D" === applyList[0].type && "C2" === applyList[0].status && <div className="shipping shipping3">배송 취소</div>
                  }
                  {
                    "D" === applyList[0].type && "D1" === applyList[0].status && <div className="shipping shipping3">배송 완료</div>
                  }
                  {
                    "D" === applyList[0].type && "E1" === applyList[0].status && <div className="shipping shipping3">배송 실패</div>
                  }

                  {
                    "C" === applyList[0].type && "A1" === applyList[0].status && <><button tabIndex={0} className="button1 deliveryB deliveryButton" onClick={selectDate}>날짜 수정</button><button tabIndex="0" type="button" className="button2 deliveryB deliveryButton2" onClick={cancelStorage}>수거 취소</button></>
                  }
                  {
                    "C" === applyList[0].type && "B1" === applyList[0].status && <button tabIndex="0" type="button" className="deliveryB button2" onClick={cancelStorage}>수거 취소</button>
                  }
                  {
                    "C" === applyList[0].type && "D1" === applyList[0].status && <button tabIndex="0" type="button" className="deliveryB button3" onClick={openDCModal}>확인하기</button>
                  }
                  {
                    "C" === applyList[0].type && "E1" === applyList[0].status && <button tabIndex="0" type="button" className="deliveryB button3" onClick={openDCModal}>확인하기</button>
                  }

                  {
                    "D" === applyList[0].type && "A1" === applyList[0].status && <><button tabIndex={0} className="button1 deliveryB deliveryButton" onClick={selectDate}>날짜 수정</button><button tabIndex="0" type="button" className="button2 deliveryB deliveryButton2" onClick={cancelStorage}>배송 취소</button></>
                  }
                  {
                    "D" === applyList[0].type && "B1" === applyList[0].status && <button tabIndex="0" type="button" className="deliveryB button2" onClick={cancelStorage}>배송 취소</button>
                  }
                  {
                    "D" === applyList[0].type && "D1" === applyList[0].status && <button tabIndex="0" type="button" className="deliveryB button3" onClick={openDCModal}>확인하기</button>
                  }
                  {
                    "D" === applyList[0].type && "E1" === applyList[0].status && <button tabIndex="0" type="button" className="deliveryB button3" onClick={openDCModal}>확인하기</button>
                  }
                </div>
              </>
              :
              <></>
            }

            <div className="serviceNow">
              <div className="row">
                <div className="serviceNowTitle">
                  {serviceInfo.title}
                  {
                    checkDeadLine(purchasedList[0].service_date_ed) ?
                    <span className="st1"> 이용중</span>
                    :
                    <span className="st1"> 기한만료</span>
                  }
                </div>
              </div>
              <div className="row row4">
                <div className="serviceDate">구독기간 : {dateFormat(purchasedList[0].service_date_st, "s")} ~ {dateFormat(purchasedList[0].service_date_ed, "e")} (4주){"N" === deliveryReducer.cabinetYn ? "" : ""}</div>
                {
                  checkAutoPayDate(purchasedList[0].service_date_ed) ?
                  <div className="payDate">다음 결제일 : {dateFormat(purchasedList[0].service_date_ed, "n")}
                    &nbsp;<span className="payStatus00">
                      {
                        checkCardExp ? 
                        <>
                          [자동 결제중]
                        </>
                        :
                        <>
                          [카드 교체 필요]
                        </>
                      }
                    </span>
                  </div>
                  :
                  <></>
                }
              </div>
            </div>
          </div>
        }
      </div>

      <div className="box2" style={{ background: "none", border: "none", boxShadow: "none", padding: "5px 14px" }}>
        <div className="boxTxt">
          <span className="bold">월 편도 수거∙배송</span> 신청 시,<br/>
          <span className="bold">이월된 쿠폰</span>이 먼저 차감됩니다.<br/>
          (쿠폰 유효기간은 발급일로부터 4주)
        </div>
      </div>
    </>
  );
}

export default DeliveryTop;