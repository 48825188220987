import { useNavigate } from "react-router-dom";

export default function MainServiceAvailable() {

  const navigate = useNavigate();

  const moveBack = () => {
    navigate("/");
  };

  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>서비스 가능지역</h1>
        </div>
      </header>

      <div className="contents">
        <div className="centerWrap">
          <img src={`${process.env.PUBLIC_URL}/assets/images/availableRegionImg.png`} alt=""/>
        </div>
      </div>
    </>
  );
}