import { useDispatch, useSelector } from "react-redux";
import CreateToken from "../../js/CreateToken";
import { POST_PAY_DATA } from "../../modules/pay/PayModules";
import { decrypt } from "../../js/encrypt";

function ConfirmDeliveryModal({tab, status, setStatus, face, setPayModalStatus}) {

  const dispatch = useDispatch();
  const deliveryReducer = useSelector(state => state.deliveryReducer);
  const memberReducer = useSelector(state => state.memberReducer);
  const serviceReducer = useSelector(state => state.serviceReducer);
  const serviceInfo = serviceReducer.serviceInfo;
// console.log(deliveryReducer.recentHistory);
  const closeModal = () => {
    setStatus(false);
  };

  const nextDate = str => {
    const next = new Date(str);
    next.setDate(next.getDate() + 1);

    const month = String(next.getMonth() + 1).padStart(2, "0");
    const date = String(next.getDate()).padStart(2, "0");

    return `${month}월 ${date}일`;
  };

  // 나중에 회원의 무료횟수 사용이 몇번 남았는지에 대한 정보 가져와서 free_yn의 값을 정해야함.
  // F1 : 기본무료 / F2 : 상품기본횟수 / F3 : 쿠폰사용 / P1 : 추가결제
  const confirm = () => {
    if(!tab) {
      const cateActiveEl = document.querySelectorAll("button.buttonCategory.active");

      let collStr = "";
      cateActiveEl.forEach(item => {
        collStr = collStr + item.value + "^";
      });

      const obj = {
        apply_date: deliveryReducer.cSelectedDate ? deliveryReducer.cSelectedDate : deliveryReducer.cDefaultDate,
        collection_type: encodeURI(collStr.substring(0, collStr.length - 1)),
        mem_seq: decrypt(sessionStorage.getItem("mem_seq")),
        goods_seq: Number(memberReducer.payment_goods_seq),
        service_seq: Number(memberReducer.service_seq),
        status: "A1",
        token: CreateToken(),
        type: "C",
        face_yn: face ? "Y" : "N",
        free_yn: memberReducer.freeUsedCnt < memberReducer.time ? "F2" : memberReducer.coupon ? "F3" : "P1",
      };

      closeModal();
      dispatch({ type: POST_PAY_DATA, payload: obj });
      setPayModalStatus(true);
      // (async () => {
      //   const resCode = await postCollApplyAPI(obj);
      //   if(1 === resCode) {
      //     alert("성공적으로 신청했습니다.");
      //     dispatch({ type: POST_ORDER_TAB, payload: false });
      //     navigate("/delivery");
      //   }
      // })();
    } else  {
      const obj = {
        apply_date: deliveryReducer.dSelectedDate ? deliveryReducer.dSelectedDate : deliveryReducer.dDefaultDate ? deliveryReducer.dDefaultDate : deliveryReducer.terminateDate,
        mem_seq: decrypt(sessionStorage.getItem("mem_seq")),
        goods_seq: Number(memberReducer.payment_goods_seq),
        service_seq: Number(memberReducer.service_seq),
        status: "A1",
        token: CreateToken(),
        type: "D",
        face_yn: face ? "Y" : "N",
        free_yn: "N" === deliveryReducer.cabinetYn ? "F1" : memberReducer.freeUsedCnt < memberReducer.time ? "F2" : memberReducer.coupon ? "F3" : "P1",
      };

      closeModal();
      dispatch({ type: POST_PAY_DATA, payload: obj });
      setPayModalStatus(true);

      // (async () => {
      //   const resCode = await postDelApplyAPI(obj);

      //   if(1 === resCode) {
      //     alert("성공적으로 신청했습니다.");
      //     dispatch({ type: POST_ORDER_TAB, payload: false });
      //     navigate("/delivery");
      //   }
      // })();
    }
  };

  return (
    <>
      <div className={ status ? "modal smallModal active" : "modal smallModal" }>
        <div className="modalNoHeader">
          <div className="modalTxt1">
            {  
              "N" === deliveryReducer.cabinetYn && (!Object.keys(deliveryReducer.recentHistory).length || (Object.keys(deliveryReducer.recentHistory).length && "F1" === deliveryReducer.recentHistory.free_yn && "D" === deliveryReducer.recentHistory.type && ("C2" === deliveryReducer.recentHistory.status || "E1" === deliveryReducer.recentHistory.status))) ?
              <>
                <span className="bold">첫 캐비닛 배송은 무료입니다.</span><br/><br/>
                이후 수거/배송 신청을 할 때에는 <br/>
                아래 순서에 따라 결제방법이 적용됩니다.<br /><br />
                1) 패키지 상품 월 편도 운송 횟수 차감<br />
                2) 수거/배송 편도 쿠폰 차감<br />
                3) 추가 편도 운송비 1만원 결제
              </>
              :
              <>
                {
                  memberReducer.coupon ?
                  <>
                    <span className="bold">이월 적립된 편도 쿠폰 1개를 사용합니다.</span><br/><br/>
                    이번 신청 후, 남아있는 쿠폰은 {memberReducer.coupon}개입니다.<br/>
                    적립된 쿠폰은 4주 후 자동 소멸합니다.
                  </>
                  :
                  <>
                    {
                      memberReducer.freeUsedCnt < memberReducer.time ?
                      <>
                        <span className="bold">월 기본 제공된 편도 횟수 1개를 사용합니다.</span><br/><br/>
                        이번 신청 후, {serviceInfo.service_date_ed.substring(5, 7)}월 {serviceInfo.service_date_ed.substring(8, 10)}일까지<br/>
                        사용 가능한 편도 횟수는 {memberReducer.time - memberReducer.freeUsedCnt}개입니다.<br/>
                        (미사용 시, 다음달에 편도 쿠폰으로 적립)
                      </>
                      :
                      <>
                        <span className="bold">
                          기본 제공된 편도 횟수를 모두 사용하여,<br/>
                          추가 운송비 10,000원이 부가됩니다.
                        </span><br/><br/>
                        월 구독 서비스가 갱신되는<br/>
                        {nextDate(serviceInfo.service_date_ed)}에 편도 횟수가 다시 제공됩니다.
                      </>
                    }
                  </>
                }
              </>
            }
          </div>
        </div>

        <div className="modalButtons">
          <div className="inside">
            <button tabIndex={0} className="buttonCancel button1in2" title="" onClick={closeModal}>취소</button>
            <button tabIndex={0} className="buttonDone button1in2" title="" onClick={confirm}>신청합니다</button>
          </div>
        </div>
      </div>

      <button tabIndex={0} className={ status ? "dimm active" : "dimm" } title="닫기" onClick={closeModal}></button>
    </>
  );
}

export default ConfirmDeliveryModal;