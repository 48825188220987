import { useNavigate } from "react-router-dom";

function RegionAlertPopup({modalStatus, setModalStatus}) {

  const navigate = useNavigate();
  const path = window.location.pathname;

  // const closeModal = () => {
  //   setModalStatus(false);

  //   if(path.includes("delivery")) navigate("/");
  // };

  const moveToMypage = () => {
    navigate("/mypage");
  };

  return (
    <>
      <div className={ modalStatus ? "modal smallModal active" : "modal smallModal" }>
        <div className="modalNoHeader">
          <img src={`${process.env.PUBLIC_URL}/assets/images/error-b.png`} className="alertImg1" alt="!"/>
          <div className="modalTxt1">
            수거/배송 주소 정보가<br/>
            입력되지 않았습니다.
          </div>
        </div>

        <div className="modalButtons">
          <div className="inside">
            <button tabIndex={0} className="buttonDone" title="" onClick={moveToMypage}>등록하기</button>
          </div>
        </div>
      </div>

      <button tabIndex={0} className={ modalStatus ? "dimm active" : "dimm" } title="닫기" onClick={moveToMypage}></button>
    </>
  );
}

export default RegionAlertPopup;