import { Outlet } from "react-router-dom";
import SignupHeader from "../../components/Header/SignupHeader";

function SignupLayout() {

  return (
    <>
      <SignupHeader/>
      <Outlet/>
    </>
  );
}

export default SignupLayout;