import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { updateMemberInfoAPI, postMemberVerifyCodeAPI, checkMemberVerifyCodeAPI } from "../../../apis/MemberAPICalls";
import Swal from "sweetalert2";
import ScrollToTop from "../../../js/ScrollToTop";

function MyInfoComp() {

  const { state } = useLocation();
  const [form, setForm] = useState({
    mem_seq: state.seq,
    name: state.name,
    phone: state.phone,
    email: state.email,
    verifyCode: "",
  });
  const navigate = useNavigate();

  const moveBack = () => {
    navigate("/mypage");
  };

  const onChangeHandler = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });

    const sendBtn = document.querySelector("#sendVerifyCodeBtn");

    if(sendBtn && sendBtn.disabled) sendBtn.disabled = false;
  };

  const emailValidation = () => {
    const regExp = /^[-0-9A-Za-z!#$%&'*+/=?^_`{|}~.]+@[-0-9A-Za-z!#$%&'*+/=?^_`{|}~]+[.]{1}[0-9A-Za-z]/;
    const result = regExp.test(form.email);

    return result;
  };

  const onlyNums = e => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
  };

  const sendVerifyCode = () => {
    const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

    if(!regPhone.test(form.phone)) {
      // alert("휴대폰번호를 입력해주세요.");
      Swal.fire({
        
        text: "휴대폰번호를 입력해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }

    const sendBtn = document.querySelector("#sendVerifyCodeBtn");

    (async () => {
      const code = await postMemberVerifyCodeAPI(form.phone);
      
      setForm({
        ...form,
        verifyCode: code
      });
    })();

    sendBtn.disabled = true;
  };

  const submitMyInfo = async () => {
    if(state.phone !== form.phone) {
      const resCode = await checkMemberVerifyCodeAPI(form.phone, form.verifyCode);
      const warning = document.querySelector(".warning");

      if(1 !== resCode) {
        warning.style.display = "block";
        return;
      } else warning.style.display = "none";
    }

    const result = emailValidation();

    if(result) {
      const resCode = await updateMemberInfoAPI(form);

      if(1 === resCode) {
        // alert("정보가 수정됐습니다.");
        // navigate("/mypage");
        Swal.fire({
          
          text: "주소 수정에 성공했습니다.",
          confirmButtonText: "확인",
          position: "center",
        confirmButtonColor: "#0064ff",
        })
        .then(res => navigate("/mypage"));
        return;
      } else {
        // alert("정보 수정에 실패했습니다.");
        Swal.fire({
          
          text: "정보 수정에 실패했습니다.",
          confirmButtonText: "확인",
          position: "center",
        confirmButtonColor: "#0064ff",
        })
        .then();
        return;
      }
    } else {
      // alert("이메일 형식이 올바르지 않습니다.");
      Swal.fire({
        
        text: "이메일 형식이 올바르지 않습니다.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }
  };

  useEffect(() => {
    ScrollToTop();
  }, []);

  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>내 정보 수정</h1>
        </div>
      </header>

      <div className="contents">
        <div className="centerWrap">
          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">이름</div>
          </div>
          <div className="dataWrap">
            <input type="text" className="inputNormal" title="이름" name="name" onChange={onChangeHandler} value={form.name} />
          </div>

          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">휴대폰번호</div>
          </div>
          <div className="dataWrap">
            <input style={state.phone === form.phone ? { width: "100%" } : {}} type="text" className="inputNormal inputWithButton" title="휴대폰번호" placeholder="숫자만 입력" name="phone" onInput={onlyNums} onChange={onChangeHandler} value={form.phone} maxLength={11} />
            {state.phone !== form.phone ? <button id="sendVerifyCodeBtn" tabIndex={0} className="buttonDark buttonWithInput" onClick={sendVerifyCode}><span>인증번호 요청</span></button> : <></>}
          </div>

          {
            state.phone !== form.phone ?
            <>
              <div className="dataWrap">
                <input type="text" className="inputNormal" title="인증번호" placeholder="인증번호 숫자 6자리" name="verifyCode" onInput={onlyNums} onChange={onChangeHandler} value={form.verifyCode} maxLength={6}/>
              </div>
              <div className="warning" style={{ display: "none" }}><div className="txt">인증번호를 다시 확인해주세요.</div></div>
            </>
            :
            <></>
          }
          
          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">이메일</div>
          </div>
          <div className="dataWrap">
            <input type="text" className="inputNormal" title="이메일 주소" name="email" onChange={onChangeHandler} value={form.email} />
          </div>
        </div>
      </div>

      <div className="bottomButtons">
        <button tabIndex={0} className="buttonDone" onClick={submitMyInfo}>수정 완료</button>
      </div>
    </>
  );
}

export default MyInfoComp;