import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMemberInfoAPI, getMemberPurchasedServiceListAPI, memberWithdrawAPI, postTerminateServiceAPI } from "../../apis/MemberAPICalls";
import { getSelectableDateListAPI, getSelectableTerminateDateListAPI } from "../../apis/DeliveryAPICalls";
import { useDispatch, useSelector } from "react-redux";
import CreateToken from "../../js/CreateToken";
import Swal from "sweetalert2";
import ScrollToTop from "../../js/ScrollToTop";
import { decrypt } from "../../js/encrypt";
import PayModal from "../../components/modal/PayModal";
import ConfirmDeliveryModal from "../../components/modal/ConfirmDeliveryModal";
import { POST_TERMINATE_DATE } from "../../modules/delivery/DeliveryModule";

function TerminateService() {

  const deliveryReducer = useSelector(state => state.deliveryReducer);
  const memberReducer = useSelector(state => state.memberReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [memInfo, setMemInfo] = useState({});
  const mem_seq = sessionStorage.getItem("mem_seq") ? decrypt(sessionStorage.getItem("mem_seq")) : 0;
  const [form, setForm] = useState({
    mem_seq: decrypt(sessionStorage.getItem("mem_seq")),
    selectReason: "짐을 보관할 필요가 없어져서",
    inputReason: "짐을 보관할 필요가 없어져서",
    satisfaction: "5",
    check1: false,
    check2: false,
    terminateDelDate: "",
    service_seq: memberReducer.service_seq,
  });
  const [dSelectList, setDSelectList] = useState([]);
  const [serviceInfo, setServiceInfo] = useState({});
  const [payModalStatus, setPayModalStatus] = useState(false);
  const [confirmModalStatus, setConfirmModalStatus] = useState(false);

  const moveBack = () => {
    navigate("/history");
  };

  const onSelectChangeHandler = e => {
    if("4" !== e.target.value) {
      setForm({
        ...form,
        inputReason: e.target.value,
        [e.target.name]: e.target.value
      });
    } else {
      setForm({
        ...form,
        inputReason: "",
        [e.target.name]: e.target.value
      });
    }
  };

  const selectDate = () => {
    navigate(`/delivery/order/selectDate?type=D&terminating=Y&ed=${serviceInfo.service_date_ed}`);
  };

  const onChangeHandler = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const onCheckHandler = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.checked
    });
  };

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const dateFormat = str => {
    const m = str.substring(5, 7);
    const d = str.substring(8, 10);

    return `${m}월 ${d}일`;
  };

  // const checkDeadLine = val => {
  //   const now = new Date();
  //   const applyDate = new Date(val);
  //   applyDate.setDate(applyDate.getDate() - 1);
  //   applyDate.setHours(15);
  //   applyDate.setMinutes(0);
  //   applyDate.setSeconds(0);

  //   return now < applyDate;
  // };

  const checkDeadLine2 = val => {
    const st = new Date(serviceInfo.service_date_ed);
    st.setDate(st.getDate() - 5);
    st.setHours(0);
    st.setMinutes(0);
    st.setSeconds(0);
    const ed = new Date(serviceInfo.service_date_ed);
    ed.setHours(0);
    ed.setMinutes(0);
    ed.setSeconds(0);
    const applyDate = new Date(val);
    applyDate.setHours(0);
    applyDate.setMinutes(0);
    applyDate.setSeconds(0);

    return st <= applyDate && applyDate <= ed;
  };

  const submitTerminate = async () => {
    if(!form.check1) {
      Swal.fire({  
        text: "배송 예정일 확인에 체크해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }

    if(!form.check2) {
      Swal.fire({
        text: "서비스 해지 완료 시점을 확인해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }

    if(deliveryReducer.terminateDate && new Date(deliveryReducer.terminateDate) > new Date(serviceInfo.service_date_ed)) {
      Swal.fire({
        text: "최종 배송일이 구독 만기일 이후 날짜로 설정됐습니다. 날짜를 재선택해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    } else if(!deliveryReducer.terminateDate && new Date(dSelectList[0].date) > new Date(serviceInfo.service_date_ed)) {
      Swal.fire({
        text: "최종 배송일이 구독 만기일 이후 날짜로 설정됐습니다. 날짜를 재선택해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }

    Swal.fire({
      text: "정말로 서비스를 해지 하시겠습니까?",
      confirmButtonText: "해지하기",
      showCancelButton: true,
      cancelButtonText: "취소",
      cancelButtonColor: "black",
      position: "center",
      confirmButtonColor: "#0064ff",
    })
    .then(async (res) => {
      if(res.isConfirmed) {
        // const resCode = await postTerminateServiceAPI(form, CreateToken());

        // if(1 === resCode) navigate("/", {replace: true});
        // else if(1 !== resCode) {
        //   Swal.fire({
        //     text: "서비스 해지 중 오류가 발생했습니다.",
        //     confirmButtonText: "확인",
        //     position: "center",
        //     confirmButtonColor: "#0064ff",
        //   })
        //   .then(res => {return;});
        // }
        
        dispatch({ type: POST_TERMINATE_DATE, payload: !deliveryReducer.terminateDate ? dSelectList[0].date : deliveryReducer.terminateDate });
        setConfirmModalStatus(true);
      }
    })
    .catch(err => console.error(err));
  };

  useEffect(() => {
    ScrollToTop();

    if(!Object.keys(memInfo).length) {
      (async () => {
        setMemInfo(await getMemberInfoAPI(mem_seq));
      })();
    }

    if(!Object.keys(serviceInfo).length && mem_seq) {
      (async () => {
        const res = await getMemberPurchasedServiceListAPI(mem_seq);
        
        setServiceInfo(res[0]);
      })();
    }
  }, []);

  useEffect(() => {
    if(Object.keys(serviceInfo).length && !dSelectList.length) {
      (async () => {
        const res = await getSelectableTerminateDateListAPI("D")
        
        setDSelectList(res.filter(item => checkDeadLine2(item.date) && "OVER" !== item.result && "2024-08-23" !== item.date));
      })();
    }
  }, [serviceInfo]);
  
  useEffect(() => {
    if(dSelectList.length) {
      setForm({
        ...form,
        terminateDelDate: dSelectList[0].date,
      });
    }

    if(deliveryReducer.terminateDate) {
      setForm({
        ...form,
        terminateDelDate: deliveryReducer.terminateDate,
      });
    }
  }, [dSelectList]);

  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>서비스 해지하기</h1>
        </div>
      </header>

      <PayModal status={payModalStatus} setStatus={setPayModalStatus} form={form}/>
      <ConfirmDeliveryModal tab={true} status={confirmModalStatus} setStatus={setConfirmModalStatus} face={"N"} setPayModalStatus={setPayModalStatus}/>

      <div className="contents contents2">
        <div className="centerWrap">
          <div className="warningX">
            <div className="txt">
              오늘 서비스를 해지하시면,<br/>
              고객님의 소중한 물건이 담긴 캐비닛을<br/>
              <span className="bold" onClick={selectDate} style={{ cursor: "pointer", fontWeight: "600", textDecoration: "underline" }}>{!deliveryReducer.terminateDate && dSelectList.length ? dateFormat(dSelectList[0].date) : dateFormat(deliveryReducer.terminateDate)}</span>에 마지막으로 배송해 드립니다.
            </div>
          </div>

          <div className="boxD2">
            <div className="title">배송지</div>
            <div className="notice">보관중인 물건을 아래 주소지로 보냅니다.</div>
            <div className="address">{memInfo.delivery_addr1} {memInfo.delivery_addr2}</div>
            <div className="phone">{memInfo.phone}</div>
            <div className="password">{`공동현관 비밀번호 (${memInfo.delivery_door_pw ? memInfo.delivery_door_pw : "없음"})`}</div>
            <button tabIndex={0} className="buttonD21">수정</button>
          </div>

          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">서비스 해지하는 이유를 알려주세요.</div>
          </div>
          <div className="dataWrap">
            <select className="selectNormal" title="서비스 해지 사유" name="selectReason" onChange={onSelectChangeHandler}>
              <option value={"짐을 보관할 필요가 없어져서"}>짐을 보관할 필요가 없어져서</option>
              <option value={"이용 요금이 부담스러워서"}>이용 요금이 부담스러워서</option>
              <option value={"서비스 이용이 불편해서"}>서비스 이용이 불편해서</option>
              <option value={"다른 짐보관 서비스를 이용하려고"}>다른 짐보관 서비스를 이용하려고</option>
              <option value={"4"}>직접 입력</option>
            </select>
          </div>

          {
            "4" === form.selectReason ?
            <div className="dataWrap">
              <input type="text" className="inputNormal" title="사유 직접 입력" placeholder="직접 입력" name="inputReason" onChange={onChangeHandler} value={form.inputReason} disabled={"4" !== form.selectReason ? true : false}/>
            </div>
            :
            <></>
          }

          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">스토르앤고 서비스에 만족하셨나요?</div>
          </div>
          <div className="dataWrap">
            <select className="selectNormal" title="서비스 만족도" name="satisfaction" onChange={onChangeHandler}>
              <option value="5">매우 만족</option>
              <option value="4">대체로 만족</option>
              <option value="3">보통</option>
              <option value="2">대체로 불만</option>
              <option value="1">매우 불만</option>
            </select>
          </div>

          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">
              최종 배송일 {!deliveryReducer.terminateDate && dSelectList.length ? dateFormat(dSelectList[0].date) : dateFormat(deliveryReducer.terminateDate)}을 확인했습니다.
            </div>
          </div>
          <div className="dataWrap">
            <label className="brisakInput">
              <input type="checkbox" name="check1" onChange={onCheckHandler} checked={form.check1}/>
              <div className="wrap">
                <div className="icon"></div>
                <div className="txt">네, 확인했습니다.</div>
              </div>
            </label>
          </div>

          <div className="warning3">
            <div className="txt">최종 배송일은 마지막 구독 기간 내에서 변경 가능합니다. 단, 마지막 구독 만기일 이후 날짜로 최종 배송일을 선택할 수 없습니다.</div>
          </div>

          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">
              사용했던 빈 캐비닛을 최종 배송일 다음 날 현관 앞에 놓아주세요. 빈 캐비닛 반납이 죄종 확인된 후 서비스 해지가 완료됩니다.
            </div>
          </div>
          <div className="dataWrap">
            <label className="brisakInput">
              <input type="checkbox" name="check2" onChange={onCheckHandler} checked={form.check2}/>
              <div className="wrap">
                <div className="icon"></div>
                <div className="txt">네, 확인했습니다.</div>
              </div>
            </label>
          </div>

          <div className="warning3">
            <div className="txt">마지막 구독 만기일로부터 3일 이내로 빈 캐비닛을 반납하지 않을 경우, 3일 이후부터 매주 3만원의 패널티가 청구됩니다. 캐비닛을 반납할 때에는,최초에 함께 제공된 선반2개와 자물쇠를 캐비닛 안에 넣어 함께 반납해 주세요. 만약 캐비닛이 파손 또는 분실된 경우, 이용약관에 따라 추가 수선비 또는 제작비가 청구될 수 있습니다.</div>
          </div>

          {/* <div className="iineTs1"></div>

          <div className="warning3">
            <div className="txt">서비스 해지 완료 후, 최대 3일 내로 상품을 배송해 드립니다.</div>
          </div>
          <div className="warning3">
            <div className="txt">배송 완료 후, 최대 2일 내로 캐비닛이 수거 될 예정입니다. 현관문 앞에 놓아주세요.</div>
          </div>
          <div className="warning3">
            <div className="txt">수거가 안될 경우, 기간에 따라 추가 비용 결제가 발생할 수 있습니다.</div>
          </div> */}
        </div>
      </div>

      <div className="bottomButtons">
        <div tabIndex={0} className="buttonDone" onClick={submitTerminate} style={{ cursor: "pointer" }}>서비스 해지하기</div>
      </div>

      <button tabIndex={0} className="gotoTop" id="gotoTop" title="화면 위로" onClick={moveToTop}></button>
    </>
  );
}

export default TerminateService;