import { useNavigate } from "react-router-dom";

function MainHeader() {

  const path = window.location.pathname;
  const navigate = useNavigate();

  const moveToMain = () => {
    navigate("/");
  };

  const moveToWebStorr = () => {
    // window.open("http://www.storr.co.kr");
  };
  
  return (
    <header className="homeHeader">
      <div className="centerWrap">
        <a onClick={moveToMain} className="homeLogo" title="홈"><img src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} alt="STORR"/></a>

        {/* <div className="floatRight">
          {
            "/" === path ?
            <a onClick={moveToWebStorr} className="selfStorage"><span>셀프스토리지 이동</span></a>
            :
            <></>
          }
          <button tabIndex={0} className="qmark" title="안내"></button>
          <button tabIndex={0} className="bell" title="알림"><div className="alarm">알림 있음</div></button>
        </div> */}
      </div>
    </header>
  );
}

export default MainHeader;